import React, { Component, Fragment } from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Typography from "@material-ui/core/Typography";
import CustomDialog from "./CustomDialog";

// Original optional props:
// label

// Extended optional props:
// helpDialog: HTML (enable the help dialog)

class HelpDialog extends Component {
  state = { isDialogOpen: false };
  render() {
    const dialog = (
      <CustomDialog label={this.props.label} open={this.state.isDialogOpen} onClose={this.handleClose}>
        <Typography variant="body2" component="div">
          {this.props.helpDialog}
        </Typography>
      </CustomDialog>
    );
    return (
      <Fragment>
        <HelpOutlineIcon color="action" style={{ cursor: "pointer", verticalAlign: "middle" }} onClick={this.handleClickOpen} />
        {dialog}
      </Fragment>
    );
  }

  handleClickOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleClose = () => {
    this.setState({ isDialogOpen: false });
  };
}

export default HelpDialog;
