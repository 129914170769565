import React, { memo } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import * as customColors from "../../theme/customColors";
import { ignoreInvalidCharForNumber, isNumberInRange } from "../../services/utils/utils";
// import "../../index.css";
import HelpDialog from "./HelpDialog";

// Original optional props:
// name
// label
// placeholder
// value
// type
// onChange
// onKeyPress
// helperText
// error
// disabled
// multiple
// multiline
// rows

// Extended optional props:
// options: array of {label, value} (relevant for "select" type).
// helpDialog: HTML (enable the help dialog).
// textFieldWidth: TextField width in px deafult 180.
// labelWidth: Label width in px deafult null (auto).
// background: set background color of textfield to secondary.
// currency: ended with currency symbol.
// bold: set the label as bold.
// labeledTextFieldDirection: set the label and textfield direction.

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 240
  }
}));

// These props could change dynamically and should be check for rerenders.
const isEqual = (prevProps, nextProps) => {
  if (prevProps.value !== nextProps.value) return false;
  if (prevProps.label !== nextProps.label) return false;
  if (prevProps.disabled !== nextProps.disabled) return false;
  if (prevProps.error !== nextProps.error) return false;
  if (prevProps.background !== nextProps.background) return false;
  if (prevProps.helperText !== nextProps.helperText) return false;
  if (prevProps.options && nextProps.options) {
    if (prevProps.options.length !== nextProps.options.length) return false;
    else
      for (let i = 0; i < prevProps.options.length; i++) {
        if (prevProps.options[i].label !== nextProps.options[i].label) return false;
        if (prevProps.options[i].value !== nextProps.options[i].value) return false;
      }
  }
  return true;
};

const LabeledTextField = function (props) {
  const classes = useStyles();

  const saveAsString = function (numStr) {
    return /\.0*$/.test(numStr);
  };

  const handleOnChange = function (e) {
    // Number checking:
    if (props.type === "number" && !props.sequencesOfZerosAllowed) {
      const value = saveAsString(e.target.value) ? e.target.value : parseFloat(ignoreInvalidCharForNumber(e.target.value));
      e.target = { value, name: e.target.name };

      if (Number.isFinite(e.target.value) && isNumberInRange(e.target.value, props.min, props.max)) {
        props.onChange(e);
      } else if (isNumberInRange(parseFloat(e.target.value), props.min, props.max)) {
        props.onChange(e);
      }
    } else {
      props.onChange(e);
    }
  };

  const handleOnBlur = function (e) {
    if (saveAsString(e.target.value)) {
      handleOnChange({ target: { name: e.target.name, value: e.target.value.replace(/\.0*$/, "") } });
    }
  };

  let val;
  let type = props.type || "";
  if (props.type !== "number") {
    val = props.value || "";
  }
  if (props.type === "number") {
    val = props.value;
    if (!props.sequencesOfZerosAllowed) {
      val = Number.isFinite(props.value) || saveAsString(props.value) ? props.value : "";
    }
    if (!props.noComma) {
      if (Number.isFinite(val)) val = val.toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
    type = "text";
  }

  // Set the Background color and set its borderRadius only if needed.
  let background = customColors.white;
  if (props.background) background = customColors.orange;
  else if (props.disabled) background = customColors.veryLigntGray;
  const textFieldStyle = {
    // Set the TextField Width (default is 190px).
    width: props.textFieldWidth ? props.textFieldWidth + "px" : "190px",
    background,
    borderRadius: props.background || props.disabled ? "5px" : null
  };
  return (
    <Grid
      container
      item
      direction={props.labeledTextFieldDirection ? props.labeledTextFieldDirection : "row"}
      spacing={1}
      alignItems={props.labeledTextFieldDirection === "column" ? "flex-start" : "center"}
    >
      {props.label ? (
        <Grid item>
          <Box
            width={props.labelWidth ? props.labelWidth : null}
            /*style={{verticalAlign: "1px"}}*/ marginBottom={props.helperText ? "22px" : ""}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" align="right" style={{ fontWeight: props.bold ? "bold" : null }}>
                  {props.label}
                </Typography>
              </Grid>
              {props.helpDialog ? (
                <Grid item>
                  <HelpDialog helpDialog={props.helpDialog} label={props.label} />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Grid>
      ) : null}

      <Grid item>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <TextField
              disabled={props.disabled}
              size="small"
              align="right"
              style={textFieldStyle}
              value={val}
              type={type}
              select={props.type === "select"}
              SelectProps={{ multiple: props.multiple, MenuProps: { classes: { paper: classes.menuPaper } } }}
              variant="outlined"
              placeholder={props.placeholder}
              helperText={props.helperText}
              rows={props.rows}
              multiline={props.multiline}
              inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px " + background + " inset" }, maxLength: props.maxLength }}
              error={props.error}
              name={props.name}
              className="width100-sm"
              onKeyPress={props.onKeyPress}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            >
              {props.type === "select" && props.options
                ? props.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>
          {props.currency ? (
            <Grid item>
              <Typography align="right" variant="subtitle1">
                {props.currency}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(LabeledTextField, isEqual);
