import { Checkbox, FormControlLabel, Radio, RadioGroup, Grid, Typography, Divider } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import filesService from "../../../services/docsService/filesService";
import CustomButton from "../../layout/CustomButton";

class ChooseDocs extends Component {
  render() {
    let registerDocsContent;
    if (this.props.docTypes.taboAndRMIType === "tabo") {
      registerDocsContent = (
        <Fragment>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="shtarMecher"
                  checked={this.props.docTypes.shtarMecher}
                  value={!this.props.docTypes.shtarMecher}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="שטר מכר"
            />
          </Grid>
          {/* <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="eaaratAzharaOneSide"
                  checked={this.props.docTypes.eaaratAzharaOneSide}
                  value={!this.props.docTypes.eaaratAzharaOneSide}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="בקשה לרישום הערת אזהרה - צד אחד"
            />
          </Grid> */}
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="eaaratAzharaAllSides"
                  checked={this.props.docTypes.eaaratAzharaAllSides}
                  value={!this.props.docTypes.eaaratAzharaAllSides}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="בקשה לרישום הע'א בהסכמת כל הצדדים(ללא צד ג')"
            />
          </Grid>
        </Fragment>
      );
    } else if (this.props.docTypes.taboAndRMIType === "rmi") {
      registerDocsContent = (
        <Fragment>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="noTaboLeasingRight"
                  checked={this.props.docTypes.noTaboLeasingRight}
                  value={!this.props.docTypes.noTaboLeasingRight}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="שטר העברת זכות חכירה שאינה בטאבו"
            />
          </Grid>
        </Fragment>
      );
    } else if (this.props.docTypes.taboAndRMIType === "both") {
      registerDocsContent = (
        <Fragment>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="taboPassingLeasingRight"
                  checked={this.props.docTypes.taboPassingLeasingRight}
                  value={!this.props.docTypes.taboPassingLeasingRight}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="שטר העברת זכות שכירות - מנהל רשום בטאבו"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="taboFixLeasingRight"
                  checked={this.props.docTypes.taboFixLeasingRight}
                  value={!this.props.docTypes.taboFixLeasingRight}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="שטר תיקון תנאי חכירה - מנהל רשום בטאבו"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="taboCancelLeasingRight"
                  checked={this.props.docTypes.taboCancelLeasingRight}
                  value={!this.props.docTypes.taboCancelLeasingRight}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="שטר ביטול רישום חכירה - מנהל רשום בטאבו"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="taboRegisterLeasingRight"
                  checked={this.props.docTypes.taboRegisterLeasingRight}
                  value={!this.props.docTypes.taboRegisterLeasingRight}
                  onChange={(e) => this.props.handleDocTypesOnChange(e)}
                  color="primary"
                />
              }
              label="שטר רישום זכות חכירה - מנהל רשום בטאבו"
            />
          </Grid>
        </Fragment>
      );
    }
    return (
      //   {/* <Button onClick={() => filesService.generate2990("605864cd86f37251ccfe7b30")}>Create 2990</Button> */}

      <Grid container direction="column" spacing={1} wrap="nowrap">
        <Grid item>
          <RadioGroup
            row
            name="taboAndRMIType"
            value={this.props.docTypes.taboAndRMIType}
            onChange={(e) => this.props.handleDocTypesOnChange(e)}
          >
            <FormControlLabel value="tabo" control={<Radio color="primary" />} label="טאבו" />
            <FormControlLabel value="rmi" control={<Radio color="primary" />} label='רמ"י' />
            <FormControlLabel value="both" control={<Radio color="primary" />} label='רמ"י רשום בטאבו' />
          </RadioGroup>
        </Grid>
        {/* <Grid item> */}
        <Grid container item direction="row" spacing={1} justify="flex-start" alignItems="flex-start">
          <Grid item xs={4}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item>
                <Typography variant="h6" align="right" style={{ textDecoration: "underline" /*, marginRight: "24px"*/ }}>
                  מסמכי רישום
                </Typography>
              </Grid>
              {registerDocsContent}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item>
                <Typography variant="h6" align="right" style={{ textDecoration: "underline" /*, marginRight: "24px"*/ }}>
                  מסמכים שונים
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ipuiKoachBuyer"
                      checked={this.props.docTypes.ipuiKoachBuyer}
                      value={!this.props.docTypes.ipuiKoachBuyer}
                      onChange={(e) => this.props.handleDocTypesOnChange(e)}
                      color="primary"
                    />
                  }
                  label="יפוי כח בלתי חוזר קונים"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ipuiKoachSeller"
                      checked={this.props.docTypes.ipuiKoachSeller}
                      value={!this.props.docTypes.ipuiKoachSeller}
                      onChange={(e) => this.props.handleDocTypesOnChange(e)}
                      color="primary"
                    />
                  }
                  label="יפוי כח בלתי חוזר מוכרים"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ipuiKoachEaaratAzhara"
                      checked={this.props.docTypes.ipuiKoachEaaratAzhara}
                      value={!this.props.docTypes.ipuiKoachEaaratAzhara}
                      onChange={(e) => this.props.handleDocTypesOnChange(e)}
                      color="primary"
                    />
                  }
                  label='יפוי כח בלתי חוזר לביטול הע"א'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" alignItems="flex-start">
              {/* <Grid item>
                <Typography variant="h6" align="right" style={{ textDecoration: "underline" }}>
                  קובץ שע"מ
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="fillDetailFileOnline"
                      checked={this.props.docTypes.fillDetailFileOnline}
                      value={!this.props.docTypes.fillDetailFileOnline}
                      onChange={(e) => this.props.handleDocTypesOnChange(e)}
                      color="primary"
                    />
                  }
                  label="קובץ מילוי פרטים באתר המקוון"
                />
              </Grid> */}
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <CustomButton
                textWidth={145}
                label="עבור למילוי הטפסים"
                disabled={!this.props.isFillDocumnetsAllowed}
                iconLeft
                icon={ArrowBackIcon}
                onClick={() => this.props.changeDocsStep(2)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ChooseDocs;
