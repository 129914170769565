import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton, Tooltip } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ContactMail } from "@material-ui/icons";
import { connect } from "react-redux";
import { setLoggedOut } from "../../store/actions/loggedinActions";

import ContactUs from "../dialogs/contactUs";
import * as customColors from "../../theme/customColors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: customColors.darkBlue
  }
}));

const NavigationBar = (props) => {
  const classes = useStyles();
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsContactUsOpen(true);
  };

  const handleClose = () => {
    setIsContactUsOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Tooltip title="העסקאות שלי">
            <Link to="/my-deals">
              <IconButton color="secondary">
                <HomeIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="צור קשר">
            <IconButton color="secondary" onClick={() => handleClickOpen()}>
              <MailIcon />
            </IconButton>
          </Tooltip>
          {props.role === "admin" || props.role === "salesman" ? (
            <Tooltip title="ניהול משתמשים">
              <Link to="/users-management">
                <IconButton color="secondary">
                  <SupervisorAccountIcon />
                </IconButton>
              </Link>
            </Tooltip>
          ) : null}
          {props.role === "admin" ? (
            <>
              <Tooltip title="עדכון מדדים">
                <Link to="/admin/update-indexes">
                  <IconButton color="secondary">
                    <EqualizerIcon />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title="ניהול מייל חידוש לקוחות">
                <Link to="/admin/manage-email-sub-renewal">
                  <IconButton color="secondary">
                    <ContactMail />
                  </IconButton>
                </Link>
              </Tooltip>
            </>
          ) : null}
          <div className={classes.grow} />
          <Typography color="secondary">
            {props.fullName} - (#{props.belongToCardNum})
          </Typography>
          <Tooltip title="התנתק">
            <IconButton color="secondary" onClick={props.setLoggedOut}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {isContactUsOpen ? <ContactUs open onClose={handleClose} /> : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    belongToCardNum: state.loggedinReducer.belongToCardNum,
    fullName: state.loggedinReducer.fullName,
    role: state.loggedinReducer.role
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedOut: () => dispatch(setLoggedOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
