import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import usersService from "../../../services/usersService";
import CustomComplexTable from "../../layout/CustomComplexTable";
import RemindersDetailsDialog from "./dialogs/reminderDetailsDialog";
import remindersService from "../../../services/remindersService";
import * as customColors from "../../../theme/customColors";
import { toggleReminderState } from "../../../store/actions/loggedinActions";
import { connect } from "react-redux";

const statusOpts = [
  { label: "פתוח", value: "open" },
  { label: "בביצוע", value: "in progress" },
  { label: "סגור", value: "close" }
];

class DealRemindersContainer extends Component {
  state = {
    dealId: "",
    rows: [[]],
    headers: ["מספר", "משימה", "תאריך יצירה", "תאריך יעד", "סטטוס", "תאריך תזכורת"],
    selectedRow: null,
    isCreateReminderDialogOpen: false,
    isEditReminderDialogOpen: false
  };

  componentDidMount() {
    const dealId = this.props.match.params.dealId;
    remindersService.getRemindersByDealId(dealId).then((res) => {
      const rows = [];
      if (res.success && Array.isArray(res.allReminders)) {
        for (const reminder of res.allReminders) {
          const row = [];
          row.push(reminder._id);
          row.push(reminder.prettyId);
          row.push(reminder.mission);
          row.push(moment(reminder.dateCreated).format("DD/MM/YYYY"));
          row.push(moment(reminder.destinationDate).format("DD/MM/YYYY"));
          row.push(statusOpts.filter((opt) => opt.value === reminder.status)[0].label);
          row.push(moment(reminder.notificationDate).format("DD/MM/YYYY"));
          rows.push(row);
        }
        this.setState({ dealId, rows });
      } else {
        this.setState({ dealId });
      }
    });
  }

  render() {
    return (
      <Grid container direction="column" spacing={2} style={{ marginTop: "12px" }}>
        <Grid item>
          <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ minWidth: "100px" }}
                endIcon={<AddIcon style={{ marginRight: "8px" }} />}
                onClick={() => this.handleOpen("isCreateReminderDialogOpen")}
              >
                חדש
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Checkbox checked={this.props.areRemindersOn || false} onChange={this.toggleClientWantsReminders} color="primary" />
                }
                label="שליחת התראות במייל"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomComplexTable
            hideFirstCellsInRow={1}
            rows={this.state.rows}
            headers={this.state.headers}
            handleDoubleClick={this.editReminder}
            editFunc={this.editReminder}
            deleteFunc={this.deleteReminder}
          />
        </Grid>
        {this.state.isCreateReminderDialogOpen ? (
          <RemindersDetailsDialog
            open
            onClose={(e, isReload) => this.handleClose("isCreateReminderDialogOpen", isReload)}
            dealId={this.state.dealId}
            statusOpts={statusOpts}
            dealPrettyId={this.props.dealPrettyId}
            dealDescription={this.props.dealDescription}
          />
        ) : null}
        {this.state.isEditReminderDialogOpen ? (
          <RemindersDetailsDialog
            open
            onClose={(e, isReload) => this.handleClose("isEditReminderDialogOpen", isReload)}
            dealId={this.state.dealId}
            statusOpts={statusOpts}
            selectedRow={this.state.selectedRow}
            dealPrettyId={this.props.dealPrettyId}
            dealDescription={this.props.dealDescription}
          />
        ) : null}
      </Grid>
    );
  }

  toggleClientWantsReminders = (e) => {
    let title = "לשלוח תזכורות של עסקאות למייל?";
    let text = "חשוב - הדלקת תזכורות הינה לכל העסקאות";
    if (!e.target.checked) {
      title = "לבטל שליחת תזכורות למייל?";
      text = "חשוב - ביטול תזכורות מונע שליחת מיילים לכל העסקאות";
    }

    Swal.fire({
      title,
      text,
      icon: "info",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        const areRemindersOn = !e.target.checked;

        usersService.toggleReminders(areRemindersOn).then((res) => {
          if (res.success) {
            Swal.fire("שינוי מצב תזכורות בוצע בהצלחה", "", "success");
            // this isnt really logged in... it just sets areRemindersOn changes
            this.props.toggleReminderState(areRemindersOn);
          }
        });
      }
    });
  };

  handleOnChange = (e) => {
    const newState = { ...this.state, [e.target.name]: e.target.value };
    this.setState(newState);
  };

  handleOpen(dialog, selectedRow) {
    if (dialog === "isCreateReminderDialogOpen") this.setState({ isCreateReminderDialogOpen: true });
    else if (dialog === "isEditReminderDialogOpen") this.setState({ isEditReminderDialogOpen: true, selectedRow });
  }

  handleClose = (dialog, isReload) => {
    if (dialog === "isCreateReminderDialogOpen") this.setState({ isCreateReminderDialogOpen: false });
    else if (dialog === "isEditReminderDialogOpen") this.setState({ isEditReminderDialogOpen: false, selectedRow: null });
    if (isReload && isReload !== "escapeKeyDown") {
      this.props.history.go(0);
    }
  };

  editReminder = (row) => {
    this.handleOpen("isEditReminderDialogOpen", row);
  };

  deleteReminder = (row) => {
    Swal.fire({
      icon: "warning",
      title: `האם אתה בטוח שברצונך למחוק את תזכורת מספר ${row[1]}`,
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonColor: customColors.red,
      confirmButtonText: "מחק",
      preConfirm: () => {
        if (!row[0]) return Swal.showValidationMessage("מחיקה נכשלה");
        return remindersService
          .deleteReminder(row[0], this.state.dealId)
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `תזכורת מספר ${row[1]} נמחקה בהצלחה`,
          confirmButtonText: "המשך",
          preConfirm: () => {}
        }).then((result) => {
          if (result.isConfirmed) this.props.history.go(0);
        });
      }
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleReminderState: (data) => dispatch(toggleReminderState(data))
  };
};

const mapStateToProps = (state) => {
  return {
    areRemindersOn: state.loggedinReducer.areRemindersOn
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DealRemindersContainer));
