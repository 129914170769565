import React, { useState, useEffect } from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import HelpDialog from "../../layout/HelpDialog";
import helpTexts from "../../../assets/texts/helpTexts";
import { date, getIlsCurrencyDateBased, numberWithCommas } from "../../../services/utils/utils";
import moment from "moment";

const MIN_SUPPORTED_DATE = new Date("1950-01-01");
const MAX_SUPPORTED_DATE = new Date();

export default function Tama38on2({ setFormInvalidMsg, setIsFormInvalid, setDataToSave }) {
  const [ptorFiveOnFive, setPtorFiveOnFive] = useState(null);
  const [isMutavSeller, setIsMutavSeller] = useState(null);
  const [daySignTama2Deal, setDaySignTama2Deal] = useState(null);
  const [dayApaPurchased, setDayApaPurchased] = useState(null);
  const [totalPropertyCost, setTotalPropertyCost] = useState("");
  const [valueBuildingPropertyTama, setValueBuildingPropertyTama] = useState("");
  const [isBeforeAug2013, setIsBeforeAug2013] = useState(null);
  const [hadPtorChapterFive, setHadPtorChapterFive] = useState(null);
  const [percentLeftFromTama2ToSoldProperty, setPercentLeftFromTama2ToSoldProperty] = useState("");
  const [valueGotPartialPtorFor, setValueGotPartialPtorFor] = useState("");
  const [extraMoneyValue, setExtraMoneyValue] = useState("");
  const [valueSoldApa, setValueSoldApa] = useState("");

  useEffect(() => {
    if (ptorFiveOnFive === "gotPtorFiveOnFive" || ptorFiveOnFive === "didntGetPtorFiveOnFive") {
      validateForm();
      return;
    }
    if (!isBeforeAug2013 || (isBeforeAug2013 === "beforeAug2013" && !hadPtorChapterFive)) {
      setFormInvalidMsg("");
      setIsFormInvalid(true);
      return;
    }
    if (isBeforeAug2013 === "beforeAug2013" && hadPtorChapterFive === "askedPtorChapterFive") {
      setFormInvalidMsg("");
      setIsFormInvalid(true);
      return;
    }
    validateForm();
  }, [ptorFiveOnFive]);

  useEffect(() => {
    if (isBeforeAug2013 === "afterAug2013") {
      validateForm();
      return;
    }

    if (!hadPtorChapterFive || hadPtorChapterFive === "askedPtorChapterFive") {
      setFormInvalidMsg("");
      setIsFormInvalid(true);
      return;
    }
    validateForm();
  }, [isBeforeAug2013]);

  useEffect(() => {
    if (hadPtorChapterFive === "didntAskPtorChapterFive") {
      validateForm();
      return;
    }
    validateForm();
  }, [hadPtorChapterFive]);

  useEffect(() => {
    validateForm();
  }, [
    totalPropertyCost,
    valueBuildingPropertyTama,
    percentLeftFromTama2ToSoldProperty,
    valueGotPartialPtorFor,
    valueSoldApa,
    extraMoneyValue,
    isMutavSeller
  ]);

  return (
    <div>
      <FormControl>
        <FormLabel>האם התבקש פטור לפי פרק חמישי 5?</FormLabel>
        <RadioGroup value={ptorFiveOnFive} row onChange={(e) => setPtorFiveOnFive(e.target.value)}>
          <FormControlLabel value="didntGetPtorFiveOnFive" control={<Radio color="primary" />} label="לא" />
          <FormControlLabel value="gotPartialPtorFiveOnFive" control={<Radio color="primary" />} label="כן, פטור חלקי" />
          <FormControlLabel value="gotPtorFiveOnFive" control={<Radio color="primary" />} label="כן, פטור מלא" />
        </RadioGroup>
      </FormControl>

      {ptorFiveOnFive && (
        <div>
          {ptorFiveOnFive === "gotPtorFiveOnFive" ? (
            <div>
              <FormControl>
                <FormLabel>
                  האם מי שמוכר את הדירה כיום היה, בעת עסקת התמ"א 38/2, "מוכר מוטב" כמשמעותו בחוק וקיבל סכום כספי אשר, יחד עם הדירה, היווה
                  סכום שמתחת לתקרת הפטור בעסקת התמ"א 38/2?
                  <HelpDialog helpDialog={helpTexts.sellerMutavTxt} label="מיהו מוכר מוטב" />
                </FormLabel>
                <RadioGroup value={isMutavSeller} row onChange={(e) => setIsMutavSeller(e.target.value)}>
                  <FormControlLabel value="notMutavSeller" control={<Radio color="primary" />} label="לא" />
                  <FormControlLabel value="mutavSeller" control={<Radio color="primary" />} label="כן" />
                </RadioGroup>
              </FormControl>

              {isMutavSeller === "notMutavSeller" && (
                <table className="fs-18" cellSpacing="10">
                  <tbody>
                    <tr>
                      <td>יום הרכישה המקורי של הדירה</td>
                      <td>
                        <TextField
                          type="date"
                          onChange={(e) => setDayApaPurchased(e.target.value)}
                          value={dayApaPurchased}
                          onBlur={onDateBlur}
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>שווי רכישה מקורי של הדירה</td>
                      <td>
                        <TextField
                          className="ironit-input"
                          onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                          value={numberWithCommas(totalPropertyCost)}
                        />
                      </td>
                      <td>
                        {getIlsCurrencyDateBased(dayApaPurchased) && (
                          <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {isMutavSeller === "mutavSeller" && <>{getTama2FinalInputs(true)}</>}
            </div>
          ) : ptorFiveOnFive === "gotPartialPtorFiveOnFive" ? (
            <div>
              <hr />
              <h2 className="yellow-color">במקרה זה יהיו שני ימי רכישה</h2>
              <Alert severity="info">
                <AlertTitle>לתשומת ליבך:</AlertTitle>
                <Typography variant="subtitle2" align="right">
                  אופן התחשיב הזה טרם נבחן בחוק ובפסיקה ואין לגביו הו"ב או החלטת מיסוי כלשהי ולכן מומלץ להיוועץ עם מומחה מיסוי בטרם עריכת
                  התחשיב
                  <br />
                </Typography>
              </Alert>
              <div className="mt-20">
                <b className="fs-18 pr-10">שווי רכישה ראשון:</b>
                <table className="fs-18" cellSpacing="10">
                  <tbody>
                    <tr>
                      <td>יום רכישה מקורי של הדירה</td>
                      <td>
                        <TextField
                          onBlur={validateForm}
                          type="date"
                          onChange={(e) => setDayApaPurchased(e.target.value)}
                          value={dayApaPurchased || ""}
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="td-size-200">שווי הרכישה המקורי של הדירה בניכוי שווי הרכישה שנקבע בעסקת התמ"א בגין החלק החייב במס.</td>
                      <td>
                        <TextField
                          className="ironit-input"
                          onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                          value={numberWithCommas(totalPropertyCost)}
                        />
                      </td>
                      <td>
                        {getIlsCurrencyDateBased(dayApaPurchased) && (
                          <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-30">
                  <b className="fs-18 pr-10">שווי רכישה שני:</b>
                  <table className="fs-18" cellSpacing="10">
                    <tbody>
                      <tr>
                        <td className="td-size-200">שווי המכירה שנקבע בעסקת התמא בגין החלק החייב במס.</td>
                        <td>
                          <TextField
                            className="ironit-input"
                            onChange={(e) => setValueBuildingPropertyTama(e.target.value.replace(/,/g, ""))}
                            value={numberWithCommas(valueBuildingPropertyTama)}
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>יום המכירה שנקבע בעסקת התמ"א</td>
                        <td>
                          <TextField
                            onBlur={validateForm}
                            type="date"
                            onChange={(e) => setDaySignTama2Deal(e.target.value)}
                            value={daySignTama2Deal || ""}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-30">
                  <b className="fs-18 pr-10">שווי המכירה בעסקת התמ"א:</b>
                  <table className="fs-18" cellSpacing="10">
                    <tbody>
                      <tr>
                        <td className="td-size-200">שווי המכירה שנקבע בעסקת התמ"א בגין החלק הפטור ממס</td>
                        <td>
                          <TextField
                            className="ironit-input"
                            onChange={(e) => setValueGotPartialPtorFor(e.target.value.replace(/,/g, ""))}
                            value={numberWithCommas(valueGotPartialPtorFor)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* No ptor five on five */}
              <FormControl>
                <FormLabel>למתי נקבע מועד המכירה של העסקה?</FormLabel>
                <RadioGroup value={isBeforeAug2013} row onChange={(e) => setIsBeforeAug2013(e.target.value)}>
                  <FormControlLabel value="beforeAug2013" control={<Radio color="primary" />} label="לפני 1.8.2013" />
                  <FormControlLabel value="afterAug2013" control={<Radio color="primary" />} label="אחרי 1.8.2013" />
                </RadioGroup>
              </FormControl>

              {isBeforeAug2013 && (
                <div>
                  {isBeforeAug2013 === "afterAug2013" ? (
                    <div>{getTama2FinalInputs()}</div>
                  ) : (
                    <div>
                      {/* before 2013 */}
                      <FormControl>
                        <FormLabel>האם בעסקת התמ"א התבקש פטור לפי פרק חמישי 1?</FormLabel>
                        <RadioGroup value={hadPtorChapterFive} row onChange={(e) => setHadPtorChapterFive(e.target.value)}>
                          <FormControlLabel value="askedPtorChapterFive" control={<Radio color="primary" />} label="כן" />
                          <FormControlLabel value="didntAskPtorChapterFive" control={<Radio color="primary" />} label="לא" />
                        </RadioGroup>
                      </FormControl>

                      {hadPtorChapterFive && (
                        <div>
                          {hadPtorChapterFive === "askedPtorChapterFive" ? (
                            <div>
                              <table className="fs-18" cellSpacing="10">
                                <tbody>
                                  <tr>
                                    <td className="td-size-200">יום המכירה שנקבע בעסקת תמ"א 38/2</td>
                                    <td>
                                      <TextField
                                        type="date"
                                        labelWidth={110}
                                        onChange={(e) => setDaySignTama2Deal(e.target.value)}
                                        value={daySignTama2Deal}
                                        onBlur={onDateBlur}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td className="td-size-200">שווי המכירה של הדירה שנקבע בעסקת התמא 38/2</td>
                                    <td>
                                      <TextField
                                        className="ironit-input"
                                        textFieldWidth={140}
                                        labelWidth={190}
                                        onChange={(e) => setValueBuildingPropertyTama(e.target.value.replace(/,/g, ""))}
                                        value={numberWithCommas(valueBuildingPropertyTama)}
                                        currency="₪"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div>{getTama2FinalInputs()}</div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );

  function onDateBlur() {
    validateForm();
  }

  function getTama2FinalInputs(isMutavSeller) {
    return (
      <table className="fs-18" cellSpacing="10">
        <tbody>
          <tr>
            <td className="td-size-200">יום המכירה שנקבע בעסקת תמ"א 38/2</td>
            <td>
              <TextField type="date" onChange={(e) => setDaySignTama2Deal(e.target.value)} value={daySignTama2Deal} onBlur={onDateBlur} />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>יום הרכישה המקורי של הדירה</td>
            <td>
              <TextField type="date" onChange={(e) => setDayApaPurchased(e.target.value)} value={dayApaPurchased} onBlur={onDateBlur} />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>שווי רכישה מקורי של הדירה</td>
            <td>
              <TextField
                className="ironit-input"
                onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                value={numberWithCommas(totalPropertyCost)}
              />
            </td>
            <td>
              {getIlsCurrencyDateBased(dayApaPurchased) && (
                <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
              )}
            </td>
          </tr>
        </tbody>

        {!isMutavSeller ? (
          <>
            <tbody>
              <tr>
                <td>החלק באחוזים שנשאר לבעלים בדירה המקורית בעסקת התמ"א</td>
                <td>
                  <TextField
                    className="ironit-input"
                    onChange={(e) => setPercentLeftFromTama2ToSoldProperty(e.target.value.replace(/,/g, ""))}
                    value={numberWithCommas(percentLeftFromTama2ToSoldProperty)}
                  />
                </td>
                <td>%</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>שווי עלויות הבניה בתמ"א של הדירה הנמכרת</td>
                <td>
                  <TextField
                    className="ironit-input"
                    onChange={(e) => setValueBuildingPropertyTama(e.target.value.replace(/,/g, ""))}
                    value={numberWithCommas(valueBuildingPropertyTama)}
                  />
                </td>
              </tr>
            </tbody>
          </>
        ) : (
          <>
            <tbody>
              <tr>
                <td className="td-size-200">שווי התמורה הכספית הנוספת</td>
                <td>
                  <TextField
                    className="ironit-input"
                    onChange={(e) => setExtraMoneyValue(e.target.value.replace(/,/g, ""))}
                    value={numberWithCommas(extraMoneyValue)}
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="td-size-200">שווי המכירה של הדירה הנמכרת כיום</td>
                <td>
                  <TextField
                    className="ironit-input"
                    onChange={(e) => setValueSoldApa(e.target.value.replace(/,/g, ""))}
                    value={numberWithCommas(valueSoldApa)}
                  />
                </td>
              </tr>
            </tbody>
          </>
        )}
      </table>
    );
  }

  function validateForm() {
    let createDataToSave;

    if (ptorFiveOnFive === "gotPtorFiveOnFive") {
      if (!dayApaPurchased || !totalPropertyCost) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (date(dayApaPurchased) < MIN_SUPPORTED_DATE) {
        setFormInvalidMsg("תאריך חייב להיות לאחר " + moment(MIN_SUPPORTED_DATE).format("DD-MM-YYYY"));
        setIsFormInvalid(true);
        return;
      }
      if (date(dayApaPurchased) > MAX_SUPPORTED_DATE) {
        setFormInvalidMsg("תאריך חייב להיות לפני " + moment(MAX_SUPPORTED_DATE).format("DD-MM-YYYY"));
        setIsFormInvalid(true);
        return;
      }

      createDataToSave = {
        purchaseValue: totalPropertyCost,
        purchaseDate: dayApaPurchased
      };

      if (isMutavSeller === "mutavSeller") {
        if (!daySignTama2Deal || !valueSoldApa || !extraMoneyValue) {
          setFormInvalidMsg("");
          setIsFormInvalid(true);
          return;
        }

        if (date(daySignTama2Deal) < MIN_SUPPORTED_DATE) {
          setFormInvalidMsg("תאריך חייב להיות לאחר " + moment(MIN_SUPPORTED_DATE).format("DD-MM-YYYY"));
          setIsFormInvalid(true);
          return;
        }
        if (date(daySignTama2Deal) > MAX_SUPPORTED_DATE) {
          setFormInvalidMsg("תאריך חייב להיות לפני " + moment(MAX_SUPPORTED_DATE).format("DD-MM-YYYY"));
          setIsFormInvalid(true);
          return;
        }

        // if (daySignTama2Deal >= "2013-01-01") {
        //   setFormInvalidMsg(`עסקת התמא נקבעה לפני שנת 2013. בשל כך שדה 'יום המכירה שנקבע בעסקת תמ"א 38/2' חייב להיות לפני 2013`);
        //   setIsFormInvalid(true);
        //   return;
        // }

        if (daySignTama2Deal < dayApaPurchased) {
          setFormInvalidMsg(`תאריך עסקת התמא חייב להיות לאחר תאריך הרכישה`);
          setIsFormInvalid(true);
          return;
        }

        createDataToSave.valueSoldApa = valueSoldApa;
        createDataToSave.tama2ExtraValueDate = daySignTama2Deal;
        createDataToSave.tama2ExtraMoneyValue = extraMoneyValue;
      }
    } else if (
      ptorFiveOnFive === "didntGetPtorFiveOnFive" &&
      isBeforeAug2013 === "beforeAug2013" &&
      hadPtorChapterFive === "askedPtorChapterFive"
    ) {
      if (!valueBuildingPropertyTama || !daySignTama2Deal) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (date(daySignTama2Deal) < MIN_SUPPORTED_DATE) {
        setFormInvalidMsg("תאריך חייב להיות לאחר " + moment(MIN_SUPPORTED_DATE).format("DD-MM-YYYY"));
        setIsFormInvalid(true);
        return;
      }
      if (date(daySignTama2Deal) > MAX_SUPPORTED_DATE) {
        setFormInvalidMsg("תאריך חייב להיות לפני " + moment(MAX_SUPPORTED_DATE).format("DD-MM-YYYY"));
        setIsFormInvalid(true);
        return;
      }

      if (daySignTama2Deal >= "2013-01-01") {
        setFormInvalidMsg(`עסקת התמא נקבעה לפני שנת 2013. בשל כך שדה 'יום המכירה שנקבע בעסקת תמ"א 38/2' חייב להיות לפני 2013`);
        setIsFormInvalid(true);
        return;
      }

      createDataToSave = {
        purchaseValue: valueBuildingPropertyTama,
        purchaseDate: daySignTama2Deal
      };
    } else if (ptorFiveOnFive === "gotPartialPtorFiveOnFive") {
      if (!valueBuildingPropertyTama || !daySignTama2Deal || !dayApaPurchased || !totalPropertyCost || !valueGotPartialPtorFor) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (!areDatesValidated()) return;
      createDataToSave = {
        purchaseValue: totalPropertyCost,
        purchaseDate: dayApaPurchased,
        purchaseValue2: valueBuildingPropertyTama,
        purchaseDate2: daySignTama2Deal,
        valueGotPartialPtorFor,
        valuePaidTaxForPartialPtor: valueBuildingPropertyTama
      };
    } else if (
      (isBeforeAug2013 === "afterAug2013" && ptorFiveOnFive === "didntGetPtorFiveOnFive") ||
      (ptorFiveOnFive === "didntGetPtorFiveOnFive" &&
        isBeforeAug2013 === "beforeAug2013" &&
        hadPtorChapterFive === "didntAskPtorChapterFive")
    ) {
      if (!areDatesValidated()) return;

      if (percentLeftFromTama2ToSoldProperty) {
        const getParsedValue = parseInt(percentLeftFromTama2ToSoldProperty);
        if (getParsedValue > 100 || getParsedValue < 1) {
          setFormInvalidMsg("החלק באחוזים שנשאר לבעלים חייב להיות בין 1 ל-100");
          setIsFormInvalid(true);
          return;
        }
      }
      if (!totalPropertyCost || !valueBuildingPropertyTama) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (isBeforeAug2013 === "beforeAug2013" && daySignTama2Deal >= "2013-01-01") {
        setFormInvalidMsg(`עסקת התמא נקבעה לפני שנת 2013. בשל כך שדה 'יום המכירה שנקבע בעסקת תמ"א 38/2' חייב להיות לפני 2013`);
        setIsFormInvalid(true);
        return;
      } else if (isBeforeAug2013 === "afterAug2013" && daySignTama2Deal < "2013-01-01") {
        setFormInvalidMsg(`עסקת התמא נקבעה לאחר שנת 2013. בשל כך שדה 'יום המכירה שנקבע בעסקת תמ"א 38/2' חייב להיות לאחר 2013`);
        setIsFormInvalid(true);
        return;
      }

      createDataToSave = {
        purchaseValue: (totalPropertyCost * parseInt(percentLeftFromTama2ToSoldProperty)) / 100,
        purchaseDate: dayApaPurchased,
        deductionValue: valueBuildingPropertyTama,
        deductionDate: daySignTama2Deal
      };
    } else {
      if (!daySignTama2Deal || !valueBuildingPropertyTama) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }
      createDataToSave = {
        purchaseValue: valueBuildingPropertyTama,
        purchaseDate: daySignTama2Deal
      };
    }

    setFormInvalidMsg("");
    setIsFormInvalid(false);
    setDataToSave(createDataToSave);
    return;
  }

  function areDatesValidated() {
    if (date(dayApaPurchased) > date(daySignTama2Deal)) {
      setFormInvalidMsg('תאריך התמ"א חייב להיות לאחר תאריך הרכישה המקורי');
      setIsFormInvalid(true);
      return false;
    }
    if (date(dayApaPurchased) < MIN_SUPPORTED_DATE || date(daySignTama2Deal) < MIN_SUPPORTED_DATE) {
      setFormInvalidMsg("תאריך חייב להיות לאחר " + moment(MIN_SUPPORTED_DATE).format("DD-MM-YYYY"));
      setIsFormInvalid(true);
      return false;
    }
    if (date(dayApaPurchased) > MAX_SUPPORTED_DATE || date(daySignTama2Deal) > MAX_SUPPORTED_DATE) {
      setFormInvalidMsg("תאריך חייב להיות לפני " + moment(MAX_SUPPORTED_DATE).format("DD-MM-YYYY"));
      setIsFormInvalid(true);
      return false;
    }

    return true;
  }
}
