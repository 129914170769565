import React from "react";
import { Typography } from "@material-ui/core";
import bgWizardLast from "../../assets/images/bg-wizard-last.png";
import bgWizard from "../../assets/images/bg-wizard.png";
import stepCircleNotCompleted from "../../assets/images/circle-wizard2.png";
import stepCircle from "../../assets/images/circle-wizard.png";
import checkMark from "../../assets/images/v.png";

function StepNavBar(props) {
  return (
    <ul className="calc-nav">
      {Array.isArray(props.steps)
        ? props.steps.map((step, index) => {
            if (index === 0) {
              return (
                <li
                  key={`step-${index}`}
                  className="relative"
                  onClick={() => (step.isAllowed ? props.handleStepChange(index + 1) : null)}
                  style={{ cursor: !step.isAllowed ? "default" : "pointer" }}
                >
                  <div className="text-and-step">
                    <div className="relative inline-block">
                      <div className="step-num white-color">{index + 1}</div>
                      <img className="wizard-step-img" alt="step" src={stepCircle} />
                    </div>
                    <div className="inline-block">
                      <Typography variant="subtitle1" align="right">
                        {step.label}
                      </Typography>
                    </div>
                  </div>
                  <img className="step-bg-high" src={bgWizard} alt="bg" />
                  {step.isFinished ? (
                    <div className="finished-step-con">
                      <img src={checkMark} alt="check" />
                    </div>
                  ) : null}
                </li>
              );
            } else if (index < props.steps.length - 1) {
              return (
                <li
                  key={`step-${index}`}
                  className={`relative${step.isAllowed ? "" : " opacity04"}`}
                  onClick={() => (step.isAllowed ? props.handleStepChange(index + 1) : null)}
                  style={{ cursor: !step.isAllowed ? "default" : "pointer" }}
                >
                  <div className="text-and-step">
                    <div className="relative inline-block mr-10">
                      <div className={`step-num ${step.isAllowed ? "white-color" : "black-color"}`}>{index + 1}</div>
                      <img className="wizard-step-img" alt="step" src={step.isAllowed ? stepCircle : stepCircleNotCompleted} />
                    </div>
                    <div className="inline-block">
                      <Typography variant="subtitle1" align="right">
                        {step.label}
                      </Typography>
                    </div>
                  </div>
                  <img className="step-bg" src={bgWizard} alt="step-bg" />
                  {step.isFinished ? (
                    <div className="finished-step-con">
                      <img src={checkMark} alt="check" />
                    </div>
                  ) : null}
                </li>
              );
            } else {
              return (
                <li
                  key={`step-${index}`}
                  className={`relative${step.isAllowed ? "" : " opacity04"}`}
                  onClick={() => (step.isAllowed ? props.handleStepChange(index + 1) : null)}
                  style={{ cursor: !step.isAllowed ? "default" : "pointer" }}
                >
                  <div className="text-and-step">
                    <div className="relative inline-block mr-10">
                      <div className={`step-num ${step.isAllowed ? "white-color" : "black-color"}`}>{index + 1}</div>
                      <img className="wizard-step-img" alt="step" src={step.isAllowed ? stepCircle : stepCircleNotCompleted} />
                    </div>
                    <div className="inline-block">
                      <Typography variant="subtitle1" align="right">
                        {step.label}
                      </Typography>
                    </div>
                  </div>
                  <img src={bgWizardLast} className="last-step-bg" alt="last-step-bg" />
                  {step.isFinished ? (
                    <div className="finished-step-con">
                      <img src={checkMark} alt="check" />
                    </div>
                  ) : null}
                </li>
              );
            }
          })
        : null}
    </ul>
  );
}

export default StepNavBar;
