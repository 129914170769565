import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { Typography, Grid } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import Dialog49a from "../components/dialogs/dialog49a";
import CustomAcordion from "../components/layout/CustomAcordion";
import CustomComplexTable from "../components/layout/CustomComplexTable";
import logo from "../assets/images/logo.png";
import CustomButton from "../components/layout/CustomButton";
import dealsService from "../services/dealsService";
import adminService from "../services/adminService";
import * as customColors from "../theme/customColors";
import DialogNewIronit from "../components/dialogs/newIronit/dialogNewIronit";
import DealsSearchComponent from "../components/layout/DealsSearchComponent";

const MyDealsContainer = (props) => {
  const [state, setState] = useState({
    filterJustPrisaByNumber: "",
    filterJustPrisaByName: "",
    dealHeaders: [
      "מספר עסקה",
      "שם עסקה",
      "עורך דין",
      "תאריך יצירה",
      "סטטוס מס",
      "מועד אחרון לדיווחים",
      "הוגשו דיווחים",
      "הערות אזהרה",
      "אישורי מיסים",
      "התראות"
    ],
    dealRows: [],
    justPrisaHeaders: ["מספר עסקה", "שם עסקה", "עורך דין", "תאריך יצירה"],
    justPrisaRows: [],
    isDialog49aOpen: false,
    isDialogNewIronitOpen: false,
    officeLawyers: [],
    isDoneLoadingOfficeLawyers: false
  });

  //BTax regular deals
  const [btaxDealsAllCount, setBtaxDealsAllCount] = useState("");
  const [resetFiltersRegularDeals, setResetFiltersRegularDeals] = useState(Math.random());
  const [dealsPrettyIdAndDesc, setDealsPrettyIdAndDesc] = useState([]);
  const searchBtaxDealsFilters = useRef({
    dealPrettyId: "",
    dealName: ""
  });

  //BTax-Yam deals
  const [btaxYamDealsAllCount, setBtaxYamDealsAllCount] = useState("");
  const [resetFiltersYam, setResetFiltersYam] = useState(Math.random());
  const searchYamDealsFilters = useRef({
    dealPrettyId: "",
    dealName: ""
  });

  useEffect(() => {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
    fetchAndLoadAllDeals();
    if (props.jobType === "manager") {
      fetchLawyersInOffice();
    } else {
      setState((prev) => ({ ...prev, isDoneLoadingOfficeLawyers: true }));
    }
  }, []);

  const fetchLawyersInOffice = () => {
    dealsService.getLawyersInOffice().then((res) => {
      if (res.success) {
        setState((prev) => ({ ...prev, officeLawyers: res.lawyers, isDoneLoadingOfficeLawyers: true }));
      }
    });
  };

  const fetchAndLoadAllDeals = () => {
    dealsService.getAllDeals({ isTotalCount: true, softwareServiceType: "btax" }).then((res) => {
      if (res["success"]) {
        renderBtaxDeals(res.allDeals, res.dealsAmount);
      }
    });

    const { softwareServiceType, abilities } = props;
    const hasBtaxYam = softwareServiceType === "btaxYam" || (softwareServiceType === "btax" && abilities.includes("prisaExt"));

    if (hasBtaxYam) {
      dealsService.getAllDeals({ isTotalCount: true, softwareServiceType: "btaxYam" }).then((res2) => {
        if (res2["success"]) {
          renderBtaxYamDeals(res2.allDeals, res2.dealsAmount);
        }
      });
    }
  };

  // BTax table search func
  const searchBtaxDealsWithFilters = (isTotalCount, newPage = 0) => {
    const softwareServiceType = "btax";
    const { dealPrettyId, dealName } = searchBtaxDealsFilters.current;
    if (dealPrettyId && dealPrettyId.length < 6) {
      Swal.fire("", "חיפוש לפי מספר עסקה חייב להיות לפחות 6 מספרים", "info");
      return;
    }
    if (dealName && dealName.length < 3) {
      Swal.fire("", "חיפוש לפי שם עסקה חייב להיות לפחות 3 תוים", "info");
      return;
    }
    dealsService.getAllDeals({ dealPrettyId, dealName, isTotalCount, softwareServiceType, skipAmount: newPage * 10 }).then((res) => {
      if (res["success"]) {
        renderBtaxDeals(res.allDeals, res.dealsAmount);
        // If we change the filters (deal name or deal number) then we should reset the page count
        if (isTotalCount) {
          setResetFiltersRegularDeals(Math.random());
        }
      }
    });
  };

  // BTax table search func
  const searchYamDealsWithFilters = (isTotalCount, newPage = 0) => {
    const softwareServiceType = "btaxYam";
    const { dealPrettyId, dealName } = searchYamDealsFilters.current;
    if (dealPrettyId && dealPrettyId.length < 6) {
      Swal.fire("", "חיפוש לפי מספר עסקה חייב להיות לפחות 6 מספרים", "info");
      return;
    }
    if (dealName && dealName.length < 3) {
      Swal.fire("", "חיפוש לפי שם עסקה חייב להיות לפחות 3 תוים", "info");
      return;
    }
    dealsService.getAllDeals({ dealPrettyId, dealName, isTotalCount, softwareServiceType, skipAmount: newPage * 10 }).then((res) => {
      if (res["success"]) {
        renderBtaxYamDeals(res.allDeals, res.dealsAmount);
        if (isTotalCount) {
          setResetFiltersYam(Math.random());
        }
      }
    });
  };

  const renderBtaxDeals = (allDeals, dealsAmount) => {
    const dealRows = [];
    setDealsPrettyIdAndDesc(
      allDeals.map((deal) => ({
        _id: deal._id,
        prettyId: deal.prettyId,
        dealDescription: deal.manage?.dealDescription || "",
        belongToEmail: deal.belongToEmail,
        listOfSharedLawyers: deal.listOfSharedLawyers
      }))
    );
    for (const deal of allDeals) {
      const row = [];
      row.push(deal._id);
      row.push(deal.prettyId || "");
      row.push(deal.manage.dealDescription || "");
      row.push(deal.fullName || "");
      row.push(moment(deal.dateCreated).format("DD/MM/YYYY") || "");
      row.push(
        deal.hasTaxToPay ? (
          <Grid container direction="row" spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <WarningRoundedIcon style={{ color: customColors.red, fontSize: "25px", verticalAlign: "middle" }} />
            </Grid>
            <Grid item>
              <Typography>חייב במס</Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )
      );
      row.push(moment(deal.lastReportDate).format("DD/MM/YYYY") || "");
      row.push(deal.manage.hasMasShevachReportsSubmitted ? "כן" : "לא");
      row.push(deal.manage.hasEaaratAzhara ? "כן" : "לא");
      row.push(deal.manage.haitelHashbacha && deal.manage.masRechisha && deal.manage.masShavach ? "כן" : "לא");
      row.push(deal.remindersCount || 0);
      dealRows.push(row);
    }
    setState((prev) => ({ ...prev, dealRows }));
    if (dealsAmount) setBtaxDealsAllCount(dealsAmount);
  };

  const renderBtaxYamDeals = (allDeals, dealsAmount) => {
    const justPrisaRows = [];

    for (const deal of allDeals) {
      const row = [];
      row.push(deal._id);
      row.push(deal.prettyId || "");
      row.push(deal.manage.dealDescription || "");
      row.push(deal.fullName || "");
      row.push(moment(deal.dateCreated).format("DD/MM/YYYY") || "");
      justPrisaRows.push(row);
    }

    setState((prev) => ({ ...prev, justPrisaRows }));
    if (dealsAmount) setBtaxYamDealsAllCount(dealsAmount);
  };

  const initNewDeal = (dealType) => {
    Swal.fire({
      title: "שם העסקה",
      input: "text",
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonText: "המשך",
      preConfirm: (dealDescription) => {
        if (!dealDescription) return Swal.showValidationMessage("יש להזין תיאור עסקה");
        return dealsService
          .initNewDeal(dealDescription, dealType)
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        let path = "";
        if (dealType === "btax") path = "/deal";
        if (dealType === "btaxYam") path = "/just-prisa";
        props.history.push(path + "/calc/" + result.value.newId);
      }
    });
  };

  const init49aPopup = () => {
    setState((prev) => ({ ...prev, isDialog49aOpen: true }));
  };

  const initNewIronitPopup = () => {
    setState((prev) => ({ ...prev, isDialogNewIronitOpen: true }));
  };

  const importDeal = () => {
    let type;
    Swal.fire({
      title: "האם לייבא את העסקה?",
      input: "textarea",
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonText: "המשך",
      preConfirm: (dealData) => {
        if (!dealData) return Swal.showValidationMessage("יש להזין עסקה");
        let dealDataObj;
        try {
          dealDataObj = JSON.parse(dealData);
          type = dealDataObj.softwareServiceType;
        } catch (e) {
          return Swal.showValidationMessage("פורמט JSON לא תקין");
        }
        return adminService
          .importDeal(dealDataObj)
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        let path = "";
        if (type === "btax") path = "/deal";
        if (type === "btaxYam") path = "/just-prisa";
        props.history.push(path + "/calc/" + result.value.newId);
      }
    });
  };

  const calcDeal = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      props.history.push("/deal/calc/" + row[0]);
    } else {
      return Swal.fire("", "אירעה שגיאה", "error");
    }
  };

  const calcJustPrisa = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      props.history.push("/just-prisa/calc/" + row[0]);
    } else {
      return Swal.fire("", "אירעה שגיאה", "error");
    }
  };

  const copyDeal = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      dealsService
        .copyDeal(row[0])
        .then((res) => props.history.push("/deal/calc/" + res.newId))
        .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
    }
  };

  const copyJustPrisa = (row) => {
    if (row && Array.isArray(row) && row.length > 1 && row[0].length === 24) {
      dealsService
        .copyDeal(row[0])
        .then((res) => props.history.push("/just-prisa/calc/" + res.newId))
        .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
    }
  };

  const deleteDeal = (row) => {
    Swal.fire({
      icon: "warning",
      title: `האם אתה בטוח שברצונך למחוק את עסקה מספר ${row[1]}`,
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonColor: customColors.red,
      confirmButtonText: "מחק",
      preConfirm: () => {
        if (!row[0]) return Swal.showValidationMessage("מחיקה נכשלה");
        return dealsService
          .deleteDeal(row[0])
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `עסקה מספר ${row[1]} נמחקה בהצלחה`,
          confirmButtonText: "המשך",
          preConfirm: () => {}
        }).then((result) => {
          if (result.isConfirmed) props.history.go(0);
        });
      }
    });
  };

  const transferDeal = async (newOwnerEmail, dealIdBeingSharedOrTransferred, dealNewLawyerName) => {
    try {
      // Check if deal is shared with someone first and prevent saving if its the case
      const currentDeal = dealsPrettyIdAndDesc.find((currentDeal) => currentDeal._id === dealIdBeingSharedOrTransferred);
      if (currentDeal && currentDeal.listOfSharedLawyers && currentDeal.listOfSharedLawyers.length) {
        Swal.fire("", "רק עסקאות ללא שיתוף יכולות להיות מועברות לערך דין אחר", "warning");
        return;
      }

      const serverRes = await dealsService.transferDeal(newOwnerEmail, dealIdBeingSharedOrTransferred, dealNewLawyerName);
      if (serverRes.success) {
        Swal.fire("בוצע בהצלחה", "העסקה הועברה", "success");
        const newDealRows = [...state.dealRows];
        const changedRowIndex = newDealRows.findIndex((row) => row[0] === dealIdBeingSharedOrTransferred);
        // Setting new owner name
        newDealRows[changedRowIndex][3] = dealNewLawyerName;

        const newDealsPrettyIdAndDesc = [...dealsPrettyIdAndDesc];
        const deal = newDealsPrettyIdAndDesc.find((currentDeal) => currentDeal._id === dealIdBeingSharedOrTransferred);
        deal.belongToEmail = newOwnerEmail;

        setState((prev) => ({ ...prev, dealRows: newDealRows }));
        setDealsPrettyIdAndDesc(newDealsPrettyIdAndDesc);
        return;
      }
    } catch (err) {}
  };

  const shareDeal = async (newOwnerEmail, dealIdBeingSharedOrTransferred) => {
    try {
      const serverRes = await dealsService.shareDeal(newOwnerEmail, dealIdBeingSharedOrTransferred);
      if (serverRes.success) {
        Swal.fire("", "שותף בהצלחה", "success");
        const newDealsPrettyIdAndDesc = [...dealsPrettyIdAndDesc];
        const deal = newDealsPrettyIdAndDesc.find((currentDeal) => currentDeal._id === dealIdBeingSharedOrTransferred);
        if (!deal.listOfSharedLawyers) deal.listOfSharedLawyers = [];
        deal.listOfSharedLawyers.push(newOwnerEmail);
        setDealsPrettyIdAndDesc(newDealsPrettyIdAndDesc);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancelShareDeal = async (ownerToRemove, dealIdBeingSharedOrTransferred) => {
    try {
      const serverRes = await dealsService.cancelShareDeal(ownerToRemove, dealIdBeingSharedOrTransferred);
      if (serverRes.success) {
        Swal.fire("", "הוסר שיתוף בהצלחה", "success");
        const newDealsPrettyIdAndDesc = [...dealsPrettyIdAndDesc];
        const deal = newDealsPrettyIdAndDesc.find((currentDeal) => currentDeal._id === dealIdBeingSharedOrTransferred);
        deal.listOfSharedLawyers = deal.listOfSharedLawyers.filter((sharedLawyerEmail) => sharedLawyerEmail !== ownerToRemove);
        setDealsPrettyIdAndDesc(newDealsPrettyIdAndDesc);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hasBtaxYam =
    props.softwareServiceType === "btaxYam" || (props.softwareServiceType === "btax" && props.abilities.includes("prisaExt"));

  let filteredDealRows = state.dealRows;
  let filteredJustPrisaRows = state.justPrisaRows;
  if (state.filterJustPrisaByNumber)
    filteredJustPrisaRows = filteredJustPrisaRows.filter((row) => row[1].toString().includes(state.filterJustPrisaByNumber));
  if (state.filterJustPrisaByName)
    filteredJustPrisaRows = filteredJustPrisaRows.filter((row) => row[2].toString().includes(state.filterJustPrisaByName));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid container item direction="row">
        <Grid container item direction="row" xs={6} justify="flex-start" alignItems="flex-end" style={{ minHeight: "85px" }}>
          <Typography variant="h4">עסקאות</Typography>
        </Grid>
        <Grid container item direction="row" xs={6} justify="flex-end">
          <div className="small-logo-deals-page inline-block">
            <img alt="btax-logo" src={logo} />
          </div>
        </Grid>
      </Grid>
      <Grid item>
        <CustomAcordion summaryText="יצירת עסקה חדשה" defaultExpanded>
          <Grid container item direction="row" spacing={2} justify="flex-start" alignItems="center">
            {props.softwareServiceType === "btax" ? (
              <Grid item>
                <CustomButton label="עסקת ביטקס" textWidth={100} onClick={() => initNewDeal("btax")} />
              </Grid>
            ) : null}
            {hasBtaxYam ? (
              <Grid item>
                <CustomButton label="ביטקס ים" textWidth={100} onClick={() => initNewDeal("btaxYam")} />
              </Grid>
            ) : null}

            {props.softwareServiceType === "btax" ? (
              <>
                <Grid item>
                  <CustomButton label="עסקת 49ה" textWidth={100} onClick={init49aPopup} />
                </Grid>

                <Dialog49a
                  open={state.isDialog49aOpen}
                  dealsPrettyIdAndDesc={dealsPrettyIdAndDesc}
                  label="מכירת שתי דירות בפטור - 49(ה)"
                  onClose={() => setState((prev) => ({ ...prev, isDialog49aOpen: false }))}
                />

                <DialogNewIronit
                  open={state.isDialogNewIronitOpen}
                  dealsPrettyIdAndDesc={dealsPrettyIdAndDesc}
                  label="מכר דירה לאחר התחדשות עירונית"
                  onClose={() => setState((prev) => ({ ...prev, isDialogNewIronitOpen: false }))}
                  fetchAndLoadAllDeals={fetchAndLoadAllDeals}
                />
              </>
            ) : null}

            {(props.role === "admin" || props.role === "salesman") && (
              <Grid item>
                <CustomButton label="מכר דירה לאחר התחדשות עירונית" textWidth={250} onClick={initNewIronitPopup} />
              </Grid>
            )}

            {props.role === "admin" ? (
              <Grid item>
                <CustomButton label="ייבא עסקה" textWidth={100} onClick={importDeal} />
              </Grid>
            ) : null}
          </Grid>
        </CustomAcordion>
      </Grid>
      {props.softwareServiceType === "btax" ? (
        <Grid item>
          <CustomAcordion summaryText="עסקאות ביטקס" defaultExpanded>
            <Grid container direction="column" spacing={3} justify="center">
              <DealsSearchComponent
                onSearchFiltersChange={(key, value) => (searchBtaxDealsFilters.current[key] = value)}
                searchDealsWithFilters={() => searchBtaxDealsWithFilters(true)}
              />

              {state.dealRows.length > 0 ? (
                <Grid item>
                  {state.isDoneLoadingOfficeLawyers && (
                    <CustomComplexTable
                      hideFirstCellsInRow={1}
                      headers={state.dealHeaders}
                      rows={filteredDealRows}
                      notifyBadge={true}
                      handleDoubleClick={calcDeal}
                      calcFunc={calcDeal}
                      copyFunc={copyDeal}
                      deleteFunc={deleteDeal}
                      transferDeal={transferDeal}
                      shareDeal={shareDeal}
                      cancelShareDeal={cancelShareDeal}
                      currentLawyerEmail={props.email}
                      officeLawyers={state.officeLawyers}
                      dealsPrettyIdAndDesc={dealsPrettyIdAndDesc}
                      dealsAllCount={btaxDealsAllCount}
                      onPageChange={searchBtaxDealsWithFilters}
                      resetFilters={resetFiltersRegularDeals}
                    />
                  )}
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant="h6">לא קיימות עסקאות ביטקס עבור משתמש זה</Typography>
                </Grid>
              )}
            </Grid>
          </CustomAcordion>
        </Grid>
      ) : null}
      {hasBtaxYam ? (
        <Grid item>
          <CustomAcordion summaryText="עסקאות ביטקס ים" defaultExpanded>
            <Grid container direction="column" spacing={3} justify="center">
              <DealsSearchComponent
                onSearchFiltersChange={(key, value) => (searchYamDealsFilters.current[key] = value)}
                searchDealsWithFilters={() => searchYamDealsWithFilters(true)}
              />

              {state.justPrisaRows.length > 0 ? (
                <Grid item>
                  <CustomComplexTable
                    hideFirstCellsInRow={1}
                    headers={state.justPrisaHeaders}
                    rows={filteredJustPrisaRows}
                    handleDoubleClick={calcJustPrisa}
                    calcFunc={calcJustPrisa}
                    copyFunc={copyJustPrisa}
                    deleteFunc={deleteDeal}
                    dealsAllCount={btaxYamDealsAllCount}
                    onPageChange={searchYamDealsWithFilters}
                    resetFilters={resetFiltersYam}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant="h6">לא קיימות עסקאות ביטקס ים עבור משתמש זה</Typography>
                </Grid>
              )}
            </Grid>
          </CustomAcordion>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    belongToCardNum: state.loggedinReducer.belongToCardNum,
    email: state.loggedinReducer.email,
    fullName: state.loggedinReducer.fullName,
    abilities: state.loggedinReducer.abilities,
    softwareServiceType: state.loggedinReducer.softwareServiceType,
    jobType: state.loggedinReducer.jobType,
    role: state.loggedinReducer.role
  };
};

export default connect(mapStateToProps)(MyDealsContainer);
