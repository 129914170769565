import React, { Component, Fragment } from "react";
import { Divider, Grid, FormControl, FormControlLabel, Checkbox, Typography, Radio, RadioGroup } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import CustomAcordion from "../layout/CustomAcordion";
import LabeledTextField from "../layout/LabeledTextField";
import HelpDialog from "../layout/HelpDialog";
import helpTexts from "../../assets/texts/helpTexts";

class PrisaComponent extends Component {
  render() {
    return (
      <CustomAcordion summaryText="פריסה" defaultExpanded={true}>
        <Grid container direction="column" spacing={2}>
          <Grid container item direction="row" spacing={5} alignItems="center">
            {this.props.shouldTryPrisaShown ? (
              <Fragment>
                <Grid item>
                  <FormControl component="fieldset" disabled={this.props.maxYears < 1 || this.props.minYears === 0}>
                    <Grid container direction="row" spacing={1} alignItems="center">
                      <Grid item>
                        <Typography variant="subtitle1" align="right">
                          האם לבצע פריסת שבח
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HelpDialog helpDialog={helpTexts.shouldTryPrisa} label="האם לבצע פריסת שבח " />
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          name="shouldTryPrisa"
                          value={Boolean(this.props.shouldTryPrisa).toString()}
                          onChange={(e) => this.props.handlePrisaOnChange(e)}
                        >
                          <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Fragment>
            ) : null}
            {this.props.soldPropertyDateShown ? (
              <Grid item>
                <LabeledTextField
                  type="date"
                  value={this.props.soldPropertyDate}
                  textFieldWidth={170}
                  name="soldPropertyDate"
                  label="תאריך מכירת הנכס"
                  onChange={(e) => {
                    this.props.handleOnChange(e);
                  }}
                />
              </Grid>
            ) : null}
            {this.props.datePropertyBoughtShown ? (
              <Grid item>
                <LabeledTextField
                  type="date"
                  value={this.props.datePropertyBought}
                  textFieldWidth={170}
                  name="datePropertyBought"
                  label="תאריך רכישת הנכס"
                  onChange={(e) => {
                    this.props.handleOnChange(e);
                  }}
                />
              </Grid>
            ) : null}
            {this.props.maxYears < 1 ? (
              <Grid item>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <InfoOutlinedIcon color="secondary" style={{ verticalAlign: "middle" }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      אין אפשרות לעשות פריסה כאשר שנת המכירה זהה לשנת הרכישה.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : this.props.minYears === 0 ? (
              <Grid item>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <InfoOutlinedIcon color="secondary" style={{ verticalAlign: "middle" }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      פריסה כאשר אחד מחלקי הרכישה נרכש בשנת המכירה לא נתמכת. לעזרה פנה למוקד השירות.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : this.props.shouldTryPrisa && this.props.soldPropertyDate && !this.props.isClientsNumDisabled ? (
              <Grid item>
                <LabeledTextField
                  type="select"
                  value={this.props.personsData.length}
                  textFieldWidth={110}
                  name="personsData"
                  label="לכמה לקוחות לחלק את השבח"
                  options={this.props.options}
                  onChange={(e) => {
                    this.props.handlePrisaOnChange(e);
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
          {this.props.shouldTryPrisa && this.props.soldPropertyDate
            ? this.props.personsData.map((person, index) => {
                return (
                  <Fragment key={`prisa-person-data-${index}`}>
                    <Grid item>
                      <Divider light />
                    </Grid>
                    {this.props.personsData.length > 1 ? (
                      <Grid item>
                        <Typography align="right" variant="h5" color="secondary" style={{ fontWeight: "bold" }}>
                          פריסה מספר {index + 1}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid container item direction="column" spacing={2} alignItems="flex-start">
                      <Grid container item direction="row" spacing={2}>
                        <Grid item>
                          <LabeledTextField
                            type="text"
                            value={person.personName}
                            textFieldWidth={170}
                            name="personName"
                            label={`שם לקוח`}
                            onChange={(e) => this.props.handlePrisaOnChange(e, index)}
                          />
                        </Grid>
                        <Grid item>
                          <LabeledTextField
                            type="date"
                            value={person.birthOfClient}
                            textFieldWidth={170}
                            name="birthOfClient"
                            label="תאריך הלידה של הנישום"
                            onChange={(e) => this.props.handlePrisaOnChange(e, index)}
                          />
                        </Grid>
                        <Grid item>
                          <LabeledTextField
                            type="select"
                            // disabled={this.props.isNumOfYearsDisabled}
                            value={person.dataPerYear.length}
                            textFieldWidth={170}
                            name="dataPerYear"
                            label="מספר שנים לפריסה"
                            options={this.getPrisaYearsOptions(this.props.maxYears)}
                            onChange={(e) => this.props.handlePrisaOnChange(e, index)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          style={{ marginRight: "-11px" }}
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={person.isMaximal}
                              value={!person.isMaximal}
                              onChange={(e) => {
                                this.props.handlePrisaOnChange(e, index);
                                this.props.handlePrisaOnChange({ target: { name: "income", value: null } }, index, 0);
                              }}
                              name="isMaximal"
                              color="primary"
                            />
                          }
                          label="חישוב מקסימלי לשנת המכירה"
                        />
                      </Grid>
                    </Grid>
                    {person.dataPerYear.map((year, yearIndex) => {
                      return (
                        <Grid item key={`prisa-person-${index}-data-per-year-${yearIndex}`}>
                          <Grid container direction="row" spacing={8} alignItems="center">
                            <Grid item>
                              <Typography variant="body1" color={yearIndex === 0 && person.isMaximal ? "textSecondary" : "textPrimary"}>
                                שנת מס
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" color={yearIndex === 0 && person.isMaximal ? "textSecondary" : "textPrimary"}>
                                {parseInt(this.props.soldPropertyDate.substring(0, 4)) - yearIndex}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" color={yearIndex === 0 && person.isMaximal ? "textSecondary" : "textPrimary"}>
                                הכנסה
                              </Typography>
                            </Grid>
                            <Grid item>
                              <LabeledTextField
                                type="number"
                                textFieldWidth={140}
                                value={year.income}
                                name="income"
                                disabled={yearIndex === 0 && person.isMaximal}
                                onChange={(e) => this.props.handlePrisaOnChange(e, index, yearIndex)}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" color={yearIndex === 0 && person.isMaximal ? "textSecondary" : "textPrimary"}>
                                נקודות זיכוי
                              </Typography>
                            </Grid>
                            <Grid item>
                              <LabeledTextField
                                type="number"
                                textFieldWidth={140}
                                value={year.zikui}
                                name="zikui"
                                disabled={yearIndex === 0 && person.isMaximal}
                                onChange={(e) => this.props.handlePrisaOnChange(e, index, yearIndex)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Fragment>
                );
              })
            : null}
        </Grid>
      </CustomAcordion>
    );
  }

  getPrisaYearsOptions(maxYears) {
    const options = [];
    for (let i = 1; i <= maxYears; i++) {
      options.push({ label: i === 1 ? "שנה אחת אחורה" : `${i} שנים אחורה`, value: i });
    }
    return options;
  }
}

export default PrisaComponent;
