import React, { Component, Fragment } from "react";
import { Divider, Grid, Typography, Button, Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import CustomDialog from "../../../layout/CustomDialog";
import * as customColors from "../../../../theme/customColors";
import LabeledTextField from "../../../layout/LabeledTextField";

// Original optional props:
// open
// onClose

// Extended optional props:
// handleResults - force the result.
// iazamCombinationPercent

class HasMutavTzinunDialog extends Component {
  state = {
    helpCalculationButtonClicked: false,
    isChecked: false,

    groundUnitPrice: null,
    amountOfUnitsInProject: null,
    projectBuildingCost: null,

    totalBuildingArea: null,
    buildingCostPerSquareMeter: null,
    totalProjectDevelopmentCost: null,
    architectCost: null,
    engineerCost: null,
    advisersCost: null,
    improvementLevy: null,
    constructionFee: null,
    developmentFee: null,
    evacuationCost: null,
    rentCost: null,
    depreciationAmount: null,
    profitPercent: null,
    unplannedPercent: null,
    outcomes: [],

    exchangeByZaban: null,
    exchangeByGroundUnits: null,

    iazamCombinationPercent: this.props.iazamCombinationPercent || 0,
    valueActualSoldOfProperty: null
  };

  render() {
    return (
      <CustomDialog label="מחשבון עזר לחישוב שווי עסקת קומבינציה" open={this.props.open} onClose={this.props.onClose} maxWidth="md">
        <Grid container direction="column" spacing={3}>
          <Grid container item direction="row" wrap="nowrap">
            <Grid container item direction="column" xs={8} spacing={1}>
              <Grid item>
                <LabeledTextField
                  type="number"
                  label="מחיר יחידת קרקע"
                  name="groundUnitPrice"
                  labelWidth={280}
                  textFieldWidth={140}
                  onChange={(e) => this.handleOnChange(e)}
                  value={this.state.groundUnitPrice}
                />
              </Grid>
              <Grid item>
                <LabeledTextField
                  type="number"
                  label="מספר יחידות במיזם כולו"
                  name="amountOfUnitsInProject"
                  labelWidth={280}
                  textFieldWidth={140}
                  onChange={(e) => this.handleOnChange(e)}
                  value={this.state.amountOfUnitsInProject}
                />
              </Grid>
              <Grid item>
                <LabeledTextField
                  disabled={this.state.helpCalculationButtonClicked}
                  type="number"
                  label='עלות בנית הפרויקט כולל מע"מ (לפי דוח 0)'
                  name="projectBuildingCost"
                  labelWidth={280}
                  textFieldWidth={140}
                  onChange={(e) => this.handleOnChange(e)}
                  value={this.state.projectBuildingCost}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={
                    this.state.helpCalculationButtonClicked ? (
                      <ArrowDownwardIcon style={{ marginRight: "8px" }} />
                    ) : (
                      <ArrowBackIcon style={{ marginRight: "8px" }} />
                    )
                  }
                  onClick={() => this.handleHelpCalculation()}
                >
                  עזרה בחישוב עלות הפרויקט
                </Button>
              </Grid>
              {this.state.helpCalculationButtonClicked ? (
                <Fragment>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label='שטח בניה ברוטו של כל הפרויקט במ"ר'
                      name="totalBuildingArea"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.totalBuildingArea}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label='עלות בניה למ"ר כולל מע"מ (חומר ועבודה)'
                      name="buildingCostPerSquareMeter"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.buildingCostPerSquareMeter}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="עלות פיתוח של כל הפרויקט"
                      name="totalProjectDevelopmentCost"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.totalProjectDevelopmentCost}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                      עלות תכנון של כל הפרויקט:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="אדריכל"
                      name="architectCost"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.architectCost}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="מהנדס"
                      name="engineerCost"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.engineerCost}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="יתר היועצים"
                      name="advisersCost"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.advisersCost}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                      אגרות, מיסים והיטלים של כל הפרויקט:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="היטל השבחה"
                      name="improvementLevy"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.improvementLevy}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="אגרת בניה"
                      name="constructionFee"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.constructionFee}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="אגרת פיתוח"
                      name="developmentFee"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.developmentFee}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                      תשלום מזומן ע"י הקונה (הקבלן) שנוסף לשווי הבניה:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="עלות פינוי בעל הקרקע"
                      name="evacuationCost"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.evacuationCost}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label='שכ"ד בעל הקרקע לתקופת הבניה'
                      name="rentCost"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.rentCost}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                      תשלום מזומן ע"י המוכר בעל הקרקע (יופחת משווי הבניה):
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="הסכום שיופחת"
                      name="depreciationAmount"
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.depreciationAmount}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                      הוצאות נוספות:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label="רווח קבלני לכל הפרויקט ב%"
                      name="profitPercent"
                      min={0}
                      max={100}
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.profitPercent}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      label='בלת"מ ב%'
                      name="unplannedPercent"
                      min={0}
                      max={100}
                      labelWidth={280}
                      textFieldWidth={140}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.unplannedPercent}
                    />
                  </Grid>
                  <Grid container item direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1">להוספת הוצאות נוספות </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="הוספה">
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ maxWidth: "36px", minWidth: "36px" }}
                          endIcon={<AddIcon style={{ marginRight: "12px" }} />}
                          onClick={() => this.addOutcome()}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {this.state.outcomes.map((outcome, index) => {
                    return (
                      <Grid key={`outcome-${index}`} container item direction="row" alignItems="center" spacing={2}>
                        <Grid item>
                          <LabeledTextField
                            type="number"
                            label="סכום ההוצאה"
                            name="amount"
                            labelWidth={100}
                            textFieldWidth={140}
                            onChange={(e) => this.handleOutcomeOnChange(e, index)}
                            value={outcome.amount}
                          />
                        </Grid>
                        <Grid item>
                          <LabeledTextField
                            type="number"
                            label="אחוז ההוצאה"
                            name="percent"
                            min={0}
                            max={100}
                            labelWidth={100}
                            textFieldWidth={140}
                            onChange={(e) => this.handleOutcomeOnChange(e, index)}
                            value={outcome.percent}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip title="מחיקה">
                            <DeleteIcon
                              fontSize="small"
                              style={{ cursor: "pointer", verticalAlign: "middle" }}
                              onClick={() => this.removeOutcome(index)}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Fragment>
              ) : null}
            </Grid>
            <Grid container item direction="column" xs={4} spacing={3} justify="flex-start" alignItems="center">
              <Grid item>
                <Chart
                  options={{
                    labels: ["בעלים", "יזם"],
                    colors: [customColors.blue, customColors.red],
                    legend: {
                      horizontalAlign: "center",
                      position: "bottom"
                    }
                  }}
                  series={[100 - this.state.iazamCombinationPercent, this.state.iazamCombinationPercent]}
                  type="pie"
                  width={280}
                />
              </Grid>
              <Grid container item direction="column" spacing={1} justify="flex-start" alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1" style={{ textDecoration: "underline" }}>
                    לשינוי האחוז הנמכר ליזם
                  </Typography>
                </Grid>
                <Grid item>
                  <LabeledTextField
                    type="number"
                    name="iazamCombinationPercent"
                    min={1}
                    max={99}
                    textFieldWidth={100}
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.iazamCombinationPercent}
                    // error={this.state.iazamCombinationPercent < 1 || this.state.iazamCombinationPercent > 99}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item direction="row" justify="center" alignItems="center">
            <Button variant="contained" color="secondary" onClick={() => this.calculateCombination()}>
              לחץ לחישוב
            </Button>
          </Grid>
          <Grid container item direction="row" justify="center" alignItems="center">
            <Grid container item direction="column" justify="center" alignItems="center" xs={5} spacing={1}>
              <Grid item>
                <Typography variant="subtitle1">התמורה לפי זכויות בניה</Typography>
              </Grid>
              <Grid item>
                <LabeledTextField type="number" name="exchangeByZaban" disabled textFieldWidth={140} value={this.state.exchangeByZaban} />
              </Grid>
            </Grid>
            <Grid container item direction="column" alignItems="center" xs={2}>
              <Divider orientation="vertical" style={{ height: "65px" }} />
            </Grid>
            <Grid container item direction="column" justify="center" alignItems="center" xs={5} spacing={1}>
              <Grid item>
                <Typography variant="subtitle1">התמורה לפי יחידת קרקע</Typography>
              </Grid>
              <Grid item>
                <LabeledTextField
                  type="number"
                  name="exchangeByGroundUnits"
                  disabled
                  textFieldWidth={140}
                  value={this.state.exchangeByGroundUnits}
                />
              </Grid>
            </Grid>
          </Grid>
          {this.state.isChecked ? (
            <Grid container item direction="row" justify="center" alignItems="center" spacing={2}>
              <Grid item>
                <LabeledTextField
                  type="number"
                  label="נא רשום את הסכום של שווי הקומביצניה:"
                  name="valueActualSoldOfProperty"
                  labelWidth={270}
                  textFieldWidth={160}
                  onChange={(e) => this.handleOnChange(e)}
                  value={this.state.valueActualSoldOfProperty}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => this.updateChanges()}>
                  עדכן תמורה
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </CustomDialog>
    );
  }

  calculateCombination() {
    if (!this.state.iazamCombinationPercent) {
      return Swal.fire("", "האחוז הנמכר ליזם הינו שדה חובה", "error");
    }
    if (!this.state.groundUnitPrice) {
      return Swal.fire("", "מחיר יחידת קרקע הינו שדה חובה", "error");
    }
    if (!this.state.amountOfUnitsInProject) {
      return Swal.fire("", "מספר יחידות במיזם כולו הינו שדה חובה", "error");
    }
    if (!this.state.helpCalculationButtonClicked && !this.state.projectBuildingCost) {
      return Swal.fire("", 'עלות בנית הפרויקט כולל מע"מ הינו שדה חובה', "error");
    }
    if (this.state.helpCalculationButtonClicked && !this.state.totalBuildingArea) {
      return Swal.fire("", 'שטח בניה ברוטו של כל הפרויקט במ"ר הינו שדה חובה', "error");
    }
    if (this.state.helpCalculationButtonClicked && !this.state.buildingCostPerSquareMeter) {
      return Swal.fire("", 'עלות בניה למ"ר כולל מע"מ הינו שדה חובה', "error");
    }

    const exchangeByGroundUnits = Math.floor(
      this.state.amountOfUnitsInProject * this.state.groundUnitPrice * (this.state.iazamCombinationPercent / 100)
    );

    let exchangeByZaban;
    if (this.state.helpCalculationButtonClicked) {
      exchangeByZaban = this.state.totalBuildingArea * this.state.buildingCostPerSquareMeter;
      exchangeByZaban += this.state.totalProjectDevelopmentCost || 0;
      exchangeByZaban += this.state.architectCost || 0;
      exchangeByZaban += this.state.engineerCost || 0;
      exchangeByZaban += this.state.advisersCost || 0;
      exchangeByZaban += this.state.improvementLevy || 0;
      exchangeByZaban += this.state.constructionFee || 0;
      exchangeByZaban += this.state.developmentFee || 0;
      exchangeByZaban += this.state.evacuationCost || 0;
      exchangeByZaban += this.state.rentCost || 0;
      exchangeByZaban +=
        (exchangeByZaban * (this.state.profitPercent || 100)) / 100 + (exchangeByZaban * (this.state.unplannedPercent || 100)) / 100;
      exchangeByZaban *= 1 - this.state.iazamCombinationPercent / 100;
      exchangeByZaban -= this.state.depreciationAmount || 0;
      for (const outcome of this.state.outcomes) {
        if (outcome.amount && outcome.percent) {
          exchangeByZaban += outcome.amount * (outcome.percent / 100);
        }
      }
      exchangeByZaban = Math.floor(exchangeByZaban);
    } else {
      exchangeByZaban = this.state.projectBuildingCost;
      exchangeByZaban *= 1 - this.state.iazamCombinationPercent / 100;
      exchangeByZaban = Math.floor(exchangeByZaban);
    }

    this.setState({ exchangeByGroundUnits, exchangeByZaban, isChecked: true });
  }

  updateChanges() {
    if (!this.state.iazamCombinationPercent) {
      return Swal.fire("", "האחוז הנמכר ליזם הינו שדה חובה", "error");
    }
    if (!this.state.valueActualSoldOfProperty) {
      return Swal.fire("", "סכום שווי הקומבינציה הינו שדה חובה", "error");
    }
    if (this.props.handleResults) {
      // Return results to the preCalc
      const e1 = { target: { value: this.state.iazamCombinationPercent, name: "iazamCombinationPercent" } };
      const e2 = { target: { value: this.state.valueActualSoldOfProperty, name: "valueActualSoldOfProperty" } };
      this.props.handleResults(e1, e2);
    }
  }

  handleOnChange(e) {
    const newState = { ...this.state, [e.target.name]: e.target.value };
    this.handleDependencies(e, newState);
    this.setState(newState);
  }

  handleDependencies(e, newState) {
    if (this.state.isChecked && e.target.name !== "valueActualSoldOfProperty") {
      newState.exchangeByGroundUnits = false;
      newState.exchangeByZaban = false;
      newState.isChecked = false;
    }
  }

  handleHelpCalculation() {
    this.setState({ helpCalculationButtonClicked: !this.state.helpCalculationButtonClicked, projectBuildingCost: null });
  }

  handleOutcomeOnChange(e, index) {
    const outcomes = [...this.state.outcomes];
    outcomes[index][e.target.name] = e.target.value;
    this.setState({ outcomes });
  }

  addOutcome() {
    const outcomes = [...this.state.outcomes];
    outcomes.push({ amount: null, percent: null });
    this.setState({ outcomes });
  }

  removeOutcome(index) {
    const outcomes = this.state.outcomes.filter((outcome, outcomeIndex) => outcomeIndex !== index);
    this.setState({ outcomes });
  }
}

export default HasMutavTzinunDialog;
