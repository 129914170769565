import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import * as customColors from "../../theme/customColors";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 2
  }
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="white"
      width="100%"
      height="60px"
      bgcolor={customColors.darkBlue}
      className={classes.footer}
    >
      <Typography>כל הזכויות שמורות לחברת ביטקס מיסוי מקרקעין בע"מ</Typography>
    </Box>
  );
};

export default Footer;
