import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Tooltip } from "@material-ui/core";

// Extended optional props:
// title

export default function CalculatorIcon(props) {
  const { title, ...iconProps } = props;
  return title ? (
    <Tooltip title={title}>
      <SvgIcon {...iconProps}>
        <path d="M18,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,8H6V4h12V8z M9,12H7v-2h2V12z   M13,12h-2v-2h2V12z M9,16H7v-2h2V16z M13,16h-2v-2h2V16z M9,20H7v-2h2V20z M13,20h-2v-2h2V20z M17,12h-2v-2h2V12z M17,16h-2v-2h2  V16z M17,20h-2v-2h2V20z" />
      </SvgIcon>
    </Tooltip>
  ) : (
    <SvgIcon {...iconProps}>
      <path d="M18,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,8H6V4h12V8z M9,12H7v-2h2V12z   M13,12h-2v-2h2V12z M9,16H7v-2h2V16z M13,16h-2v-2h2V16z M9,20H7v-2h2V20z M13,20h-2v-2h2V20z M17,12h-2v-2h2V12z M17,16h-2v-2h2  V16z M17,20h-2v-2h2V20z" />
    </SvgIcon>
  );
}
