import React, { Component } from "react";
import { Divider, Grid, FormControl, FormControlLabel, Typography, RadioGroup, Radio, Button, Box } from "@material-ui/core";
import Swal from "sweetalert2";
import moment from "moment";

import CustomDialog from "../../../layout/CustomDialog";
import * as customColors from "../../../../theme/customColors";
import Popup from "../../../../assets/images/popup.png";
import HelpDialog from "../../../layout/HelpDialog";
import helpTexts from "../../../../assets/texts/helpTexts";
import LabeledTextField from "../../../layout/LabeledTextField";

// Original optional props:
// open
// onClose

// Extended optional props:
// handleResults - force the result.

class HasMutavTzinunDialog extends Component {
  state = {
    isGiftPropertyFromRelated: false,
    propertyGiftedDate: "",
    dateOfBirth: "",
    isGotFromParentAsGift: true,
    isLiveInGiftedProperty: true,
    isSingleParentOrMarried: true,

    isChecked: false,
    comment: "",
    hasMutavTzinun: false,
    startTzinunDate: "",
    endTzinunDate: ""
  };

  render() {
    return (
      <CustomDialog label="בדיקת מגבלת צינון לשימוש במס מוטב עבור דירה שהתקבלה במתנה" open={this.props.open} onClose={this.props.onClose}>
        <Grid container direction="column" spacing={1}>
          <Grid container item direction="row" wrap="nowrap">
            <Grid container item direction="column" xs={8} spacing={1}>
              <Grid item>
                <FormControl component="fieldset">
                  <Grid container direction="column">
                    <Grid item container direction="row" spacing={1} alignItems="center">
                      <Grid item>
                        <Typography variant="body2">האם הדירה או חלק ממנה התקבלה כאמור לעיל במתנה?</Typography>
                      </Grid>
                      <Grid item>
                        <HelpDialog helpDialog={helpTexts.isGiftPropertyFromRelated} label="" />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isGiftPropertyFromRelated).toString()}
                        row
                        name="isGiftPropertyFromRelated"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid container item direction="column" spacing={1}>
                <Grid item container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <Typography color={!this.state.isGiftPropertyFromRelated ? "textSecondary" : "textPrimary"} variant="body2">
                      מתי התקבלה הדירה במתנה?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <HelpDialog helpDialog={helpTexts.propertyGiftedDate} label="" />
                  </Grid>
                </Grid>

                <Grid item>
                  <LabeledTextField
                    disabled={!this.state.isGiftPropertyFromRelated}
                    type="date"
                    name="propertyGiftedDate"
                    textFieldWidth={170}
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.propertyGiftedDate}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid container item direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    color={
                      !this.state.propertyGiftedDate || this.state.propertyGiftedDate >= "2013-08-01" ? "textSecondary" : "textPrimary"
                    }
                    variant="body2"
                  >
                    מה תאריך הלידה של מקבל המתנה (עבור בני זוג - עפ"י המבוגר שבהם)?
                  </Typography>
                </Grid>
                <Grid item>
                  <LabeledTextField
                    disabled={!this.state.propertyGiftedDate || this.state.propertyGiftedDate >= "2013-08-01"}
                    type="date"
                    name="dateOfBirth"
                    textFieldWidth={170}
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.dateOfBirth}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={!this.state.propertyGiftedDate || this.state.propertyGiftedDate >= "2013-08-01"}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.propertyGiftedDate || this.state.propertyGiftedDate >= "2013-08-01" ? "textSecondary" : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם נותן המתנה הינו הורה של מוכר הדירה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isGotFromParentAsGift).toString()}
                        row
                        name="isGotFromParentAsGift"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={!this.state.propertyGiftedDate || this.state.propertyGiftedDate >= "2013-08-01"}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.propertyGiftedDate || this.state.propertyGiftedDate >= "2013-08-01" ? "textSecondary" : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם מקבל המתנה מתגורר בדירה?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isLiveInGiftedProperty).toString()}
                        row
                        name="isLiveInGiftedProperty"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  disabled={
                    !this.state.propertyGiftedDate ||
                    this.state.propertyGiftedDate >= "2013-08-01" ||
                    !this.state.isGotFromParentAsGift ||
                    !this.state.isLiveInGiftedProperty
                  }
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        color={
                          !this.state.propertyGiftedDate ||
                          this.state.propertyGiftedDate >= "2013-08-01" ||
                          !this.state.isGotFromParentAsGift ||
                          !this.state.isLiveInGiftedProperty
                            ? "textSecondary"
                            : "textPrimary"
                        }
                        variant="body2"
                      >
                        האם מקבל המתנה נשוי או הורה יחיד?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        value={Boolean(this.state.isSingleParentOrMarried).toString()}
                        row
                        name="isSingleParentOrMarried"
                        onChange={(e) => this.handleOnChange(e)}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                          </Grid>
                          <Grid item>
                            <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.hasMutavTzinun}
                  disabled={this.state.isGiftPropertyFromRelated && this.state.propertyGiftedDate < "2013-08-01" && !this.state.dateOfBirth}
                >
                  בדוק זכאות
                </Button>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={4} spacing={2} justify="flex-start" alignItems="center">
              <Grid item>
                <img alt="popup" src={Popup} width="200px" height="150px" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box width="100%" bgcolor={customColors.darkPurple} p={0.5}>
              <Typography variant="h6" style={{ color: customColors.white }}>
                החלטת המערכת:
              </Typography>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`מגבלת צינון: ${this.state.isChecked ? (this.state.hasMutavTzinun ? "קיימת מגבלת צינון" : "אין מגבלת צינון") : ""}`}
              </Typography>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`הערה: ${this.state.isChecked ? (this.state.comment ? this.state.comment : "") : ""}`}
              </Typography>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`תאריך תחילת תקופת הצינון: ${
                  this.state.isChecked && this.state.startTzinunDate ? new Date(this.state.startTzinunDate).toLocaleDateString("en-GB") : ""
                }`}
              </Typography>
              <Typography variant="h6" style={{ color: customColors.white }}>
                {`תאריך סיום תקופת הצינון: ${
                  this.state.isChecked && this.state.endTzinunDate ? new Date(this.state.endTzinunDate).toLocaleDateString("en-GB") : ""
                }`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  hasMutavTzinun = () => {
    let hasMutavTzinun = false;
    let startTzinunDate = "";
    let endTzinunDate = "";
    let comment = "";
    const today = moment(new Date()).format("YYYY-MM-DD");
    if (!this.state.isGiftPropertyFromRelated) {
      // TODO:
    } else if (this.state.propertyGiftedDate > today) {
      return Swal.fire("", "תאריך קבלת המתנה לא יכול להיות תאריך עתידי", "error");
    } else if (this.state.dateOfBirth > today) {
      return Swal.fire("", "תאריך הלידה לא יכול להיות תאריך עתידי", "error");
    } else if (this.props.soldPropertyDate < this.state.propertyGiftedDate) {
      return Swal.fire("", "תאריך קבלת המתנה לא יכול להיות לאחר תאריך המכירה", "error");
    } else if (this.props.soldPropertyDate < this.state.dateOfBirth) {
      return Swal.fire("", "תאריך הלידה לא יכול להיות לאחר תאריך המכירה", "error");
    } else if (this.state.propertyGiftedDate && this.state.propertyGiftedDate >= "2013-08-01") {
      comment = "יש לעשות שימוש במס מוטב של נותן המתנה";
    } else if (this.state.propertyGiftedDate && this.state.dateOfBirth) {
      let yearsOfTzinun;
      if (this.state.isGotFromParentAsGift && this.state.isLiveInGiftedProperty) {
        yearsOfTzinun = this.state.isSingleParentOrMarried ? 1 : 2;
      } else {
        yearsOfTzinun = !this.state.isGotFromParentAsGift && !this.state.isLiveInGiftedProperty ? 4 : 3;
      }
      let age18 = new Date(this.state.dateOfBirth);
      age18.setFullYear(age18.getFullYear() + 18);
      age18 = moment(age18).format("YYYY-MM-DD");
      startTzinunDate = age18 > this.state.propertyGiftedDate ? age18 : this.state.propertyGiftedDate;
      endTzinunDate = new Date(startTzinunDate);
      endTzinunDate.setFullYear(endTzinunDate.getFullYear() + yearsOfTzinun);
      endTzinunDate = moment(endTzinunDate).format("YYYY-MM-DD");
      hasMutavTzinun = endTzinunDate > this.props.soldPropertyDate;
    }
    this.setState({ hasMutavTzinun, comment, startTzinunDate, endTzinunDate, isChecked: true });
    // Return results to the preCalc
    if (this.props.handleResults) this.props.handleResults({ target: { value: hasMutavTzinun, name: "hasMutavTzinun" } });
  };

  handleOnChange(e) {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const newState = { ...this.state, [e.target.name]: val };
    this.handleDependencies(e, newState);
    this.setState(newState);
  }

  handleDependencies(e, newState) {
    if (e.target.name === "isGiftPropertyFromRelated") {
      if (e.target.value === "false") {
        newState.propertyGiftedDate = "";
        newState.dateOfBirth = "";
        newState.isGotFromParentAsGift = true;
        newState.isLiveInGiftedProperty = true;
        newState.isSingleParentOrMarried = true;
      }
    } else if (e.target.name === "propertyGiftedDate") {
      if (e.target.value >= "2013-08-01") {
        newState.dateOfBirth = "";
        newState.isGotFromParentAsGift = true;
        newState.isLiveInGiftedProperty = true;
        newState.isSingleParentOrMarried = true;
      }
    } else if (e.target.name === "dateOfBirth") {
    } else if (e.target.name === "isGotFromParentAsGift") {
      if (e.target.value === "false") {
        newState.isSingleParentOrMarried = true;
      }
    } else if (e.target.name === "isLiveInGiftedProperty") {
      if (e.target.value === "false") {
        newState.isSingleParentOrMarried = true;
      }
    }
  }
}

export default HasMutavTzinunDialog;
