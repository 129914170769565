import React from "react";
import { Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import * as customColors from "../../theme/customColors";

// Original optional props:
// onClick

// Extended optional props:
// label
// textWidth
// disabled
// iconLeft
// icon

const CustomButton = (props) => {
  const textWidth = props.textWidth ? props.textWidth + "px" : null;
  const icon = React.createElement(props.icon ? props.icon : AddIcon, {
    style: { color: "white", fontSize: "30px", verticalAlign: "middle" }
  });
  return (
    <Box
      style={{ cursor: props.disabled ? "default" : "pointer" }}
      display="flex"
      flexDirection="row"
      border={3.5}
      borderColor="secondary.main"
      onClick={props.disabled ? null : props.onClick}
      alignItems="center"
      justifyContent="flex-start"
    >
      {!props.iconLeft ? (
        <Box p={0.5} bgcolor="secondary.main" style={{ outline: "2px " + customColors.orange + " solid" }}>
          {icon}
        </Box>
      ) : null}
      <Box p={0.5} width={textWidth}>
        <Typography style={{ fontWeight: "bold" }}>{props.label}</Typography>
      </Box>
      {props.iconLeft ? (
        <Box p={0.5} bgcolor="secondary.main" style={{ outline: "2px " + customColors.orange + " solid" }}>
          {icon}
        </Box>
      ) : null}
    </Box>
  );
};

export default CustomButton;
