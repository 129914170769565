export const ignoreInvalidCharForNumber = (num) => {
  return num.replace(/[^\d\.]/g, "");
};

export const numberWithCommas = (x) => {
  if (x === 0) return 0;
  if (!x) return "";
  return ignoreInvalidCharForNumber(x.toString()).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isNumberInRange = (num, min, max) => {
  if (!num) return true;
  if (min && num < min) return false;
  if (max && num > max) return false;
  return true;
};

export const showDateInTextType = (date) => {
  return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4);
};

export const showDateInTextFieldType = (date) => {
  return date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
};

export const getAmountOptions = (amount) => {
  const options = [];
  for (let i = 1; i <= amount; i++) {
    options.push({ label: `${i}`, value: i });
  }
  return options;
};

export const convertDecimalToFraction = (decimalNum, approximation = 3) => {
  const gcd = function (a, b) {
    if (b < 0.0000001) return a;
    return gcd(b, Math.floor(a % b));
  };

  const len = decimalNum.toString().length - 2;
  let denominator = Math.pow(10, Math.min(len, approximation));
  let numerator = decimalNum * denominator;
  const divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  return [Math.floor(numerator), Math.floor(denominator)];
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const date = (date) => new Date(date);

export const getIlsCurrencyDateBased = (date) => {
  if (!date || date < "1900-01-01") return "";
  if (date >= "1986-01-01") return "שקל חדש";
  if (date >= "1980-02-24") return "שקל ישן";
  return "לירה";
};
