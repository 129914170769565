import React, { Component } from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import moment from "moment";

import CustomDialog from "../layout/CustomDialog";
import CalculatorIcon from "../layout/CalculatorIcon";
import LabeledTextField from "../layout/LabeledTextField";
import thirdPartyService from "../../services/thirdPartyService";

class CalculatorDialog extends Component {
  state = {
    exchangeDate: "",
    dollarAmount: null,
    shekelAmount: null
  };

  render() {
    return (
      <CustomDialog label='המרת ש"ח\דולר' open={this.props.open} onClose={this.props.onClose} maxWidth="xs">
        <Grid container direction="column" spacing={1} style={{ marginTop: "8px", marginBottom: "8px" }}>
          <Grid item>
            <LabeledTextField
              type="date"
              value={this.state.exchangeDate}
              name="exchangeDate"
              label="תאריך המרה"
              labelWidth={130}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item>
            <LabeledTextField
              type="number"
              value={this.state.dollarAmount}
              name="dollarAmount"
              label="מחיר בדולר"
              labelWidth={130}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item style={{ marginRight: "138px" }}>
            <Tooltip title="חשב">
              <Button
                variant="contained"
                color="secondary"
                style={{ maxWidth: "50px", minWidth: "50px" }}
                endIcon={<CalculatorIcon style={{ marginRight: "12px" }} />}
                onClick={() => this.convertDollarCurrency()}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <LabeledTextField
              type="number"
              disabled
              value={this.state.shekelAmount}
              name="shekelAmount"
              label="מחיר בשקל"
              labelWidth={130}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  handleOnChange = (e) => {
    let val = e.target.value;
    const newState = { ...this.state, [e.target.name]: val };
    this.setState(newState);
  };

  convertionValidations() {
    if (!this.state.dollarAmount) {
      return "מחיר בדולר הינו שדה חובה";
    } else if (!this.state.exchangeDate) {
      return "תאריך המרה הינו שדה חובה";
    } else if (this.state.exchangeDate > moment(new Date()).format("YYYY-MM-DD")) {
      return "תאריך המרה לא יכול להיות תאריך עתידי";
    }
    return null;
  }

  convertDollarCurrency() {
    const validationErr = this.convertionValidations();
    if (validationErr) {
      return Swal.fire("", validationErr, "error");
    } else {
      thirdPartyService
        .convertDollarCurrency(this.state.dollarAmount, this.state.exchangeDate)
        .then((res) => {
          this.setState({ shekelAmount: res });
        })
        .catch((err) => {
          return Swal.fire("", err, "error");
        });
    }
  }
}

export default CalculatorDialog;
