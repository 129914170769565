var apiUrlEndpoint;
if (window.location.host.includes("staging.btax-calc.co.il")) {
  apiUrlEndpoint = "https://staging.api.btax-calc.co.il/";
} else if (window.location.host.includes("btax-calc.co.il")) {
  apiUrlEndpoint = "https://api.btax-calc.co.il/";
} else {
  apiUrlEndpoint = "http://localhost:4848/";
}

var apiUrlProtectedEndpoint = apiUrlEndpoint + "loggedin/";
var webVersion = "1.0.0";

const screenZoomLevelOptions = ["", "zoom-level-1", "zoom-level-2", "zoom-level-3"];
const fontSizeLevelOptions = ["", "font-increase-size-level-1", "font-increase-size-level-2"];
const lineHeightLevelOptions = ["", "line-height-increase-level-1", "line-height-increase-level-2"];
const letterSpaceLevelOptions = ["", "letter-space-increase-level-1", "letter-space-increase-level-2"];
const linkHighlightOptions = ["", "link-highlight"];
const mouseCursorLevelOptions = ["", "large-mouse-cursor"];

export default {
  // websiteBaseUrl: apiUrlEndpoint.includes("localhost")
  //   ? "http://localhost:3000/"
  //   : apiUrlEndpoint.includes("dev")
  //   ? "https://dev.visa-to-go.com/"
  //   : "https://visa-to-go.com/",

  webVersion,

  getClientIpEndpoint: "https://jsonip.com",
  getLocationOfIpEndpoint: "https://ipapi.co/%ip%/json",

  reCaptchaKey: "6LeSnPoZAAAAAGhZr-cUFOL_l8U2MoQNb-pAEBi1",

  // Constants:
  maxDeductionsAllowed: 500,
  minZabanAllowed: 0.001,
  maxZabanAllowed: 30000,
  earliestAllowedProperySoldDate: "2015-01-01",
  apaBoughtAsReplacementToSoldApaAmountOfMonths: 24,

  // Links/endpoints //
  // unprotected
  // users
  loginUserEndpoint: apiUrlEndpoint + "unprotected/login",
  pendingRegisterEndpoint: apiUrlEndpoint + "unprotected/pending-register",
  confEmailEndpoint: apiUrlEndpoint + "unprotected/conf-email",
  resendEmailConfEndpoint: apiUrlEndpoint + "unprotected/resend-email-conf",
  resetUserPassEndpoint: apiUrlEndpoint + "unprotected/reset-pass",
  changeUserPassEndpoint: apiUrlEndpoint + "unprotected/change-pass",
  informUsContactMsgEndpoint: apiUrlEndpoint + "unprotected/inform-us-contact-msg",

  // sales
  getAllDealsSalesEndpoint: apiUrlProtectedEndpoint + "sales/get-all-deals",
  resetTestUserPasswordSalesEndpoint: apiUrlProtectedEndpoint + "sales/reset-test-user-password",
  getEmailConfTokenEndpoint: apiUrlProtectedEndpoint + "sales/get-email-conf-token",

  // admin
  importDealAdminEndpoint: apiUrlProtectedEndpoint + "admin/import-deal",
  importJustPrisaAdminEndpoint: apiUrlProtectedEndpoint + "admin/import-just-prisa",
  getAllMadadimAdminEndpoint: apiUrlProtectedEndpoint + "admin/get-all-madadim",
  updateMadadAdminEndpoint: apiUrlProtectedEndpoint + "admin/update-madad",
  deleteMadadAdminEndpoint: apiUrlProtectedEndpoint + "admin/delete-madad",
  getAllBracketsAdminEndpoint: apiUrlProtectedEndpoint + "admin/get-all-brackets",
  updateBracketAdminEndpoint: apiUrlProtectedEndpoint + "admin/update-bracket",
  deleteBracketAdminEndpoint: apiUrlProtectedEndpoint + "admin/delete-bracket",
  getAllYearlyConstantsAdminEndpoint: apiUrlProtectedEndpoint + "admin/get-all-yearly-constants",
  updateYearlyConstantsAdminEndpoint: apiUrlProtectedEndpoint + "admin/update-yearly-constants",
  deleteYearlyConstantsAdminEndpoint: apiUrlProtectedEndpoint + "admin/delete-yearly-constants",
  // TODO - delete the following two later

  // transferUsersAndOfficesEndpoint: apiUrlProtectedEndpoint + "admin/run-users-mig",
  // transferDealsOldBtaxEndpoint: apiUrlProtectedEndpoint + "admin/run-deals-mig",

  getTestAdminEndpoint: apiUrlProtectedEndpoint + "admin/get-test",
  getAllTestsAdminEndpoint: apiUrlProtectedEndpoint + "admin/get-all-tests",
  addDealTestAdminEndpoint: apiUrlProtectedEndpoint + "admin/add-deal-test",
  addJustPrisaTestAdminEndpoint: apiUrlProtectedEndpoint + "admin/add-just-prisa-test",
  deleteTestAdminEndpoint: apiUrlProtectedEndpoint + "admin/delete-test",
  startTestsAdminEndpoint: apiUrlProtectedEndpoint + "admin/start-tests",
  specificClientDealsTransferEndpoint: apiUrlProtectedEndpoint + "admin/specific-client-deals-transfer",
  sendRenewalTestEndpoint: apiUrlProtectedEndpoint + "admin/send-renewal-test",
  updateRenewalEmailEndpoint: apiUrlProtectedEndpoint + "admin/update-renewal-email",
  getRenewalTemplateEndpoint: apiUrlProtectedEndpoint + "admin/get-renewal-template",
  updateCountryAddressesEndpoint: apiUrlProtectedEndpoint + "admin/update-country-addresses",

  // deals
  preCalcStartEndpoint: apiUrlProtectedEndpoint + "deals/pre-calc-start",
  mainCalcStartEndpoint: apiUrlProtectedEndpoint + "deals/calc-start",
  justPrisaCalcEndpoint: apiUrlProtectedEndpoint + "deals/just-prisa-calc",
  initNewDealEndpoint: apiUrlProtectedEndpoint + "deals/init-new-deal",
  initNewDealWithPayloadEndpoint: apiUrlProtectedEndpoint + "deals/init-new-deal-payload",
  getAllDealsEndpoint: apiUrlProtectedEndpoint + "deals/get-all-deals",
  getDealDataEndpoint: apiUrlProtectedEndpoint + "deals/get-deal-data",
  updateDealDataEndpoint: apiUrlProtectedEndpoint + "deals/update-deal-data",
  updateDealJustPrisaEndpoint: apiUrlProtectedEndpoint + "deals/update-deal-just-prisa",
  copyDealEndpoint: apiUrlProtectedEndpoint + "deals/copy-deal",
  deleteDealEndpoint: apiUrlProtectedEndpoint + "deals/delete-deal",
  transferDealEndpoint: apiUrlProtectedEndpoint + "deals/transfer-deal",
  shareDealEndpoint: apiUrlProtectedEndpoint + "deals/share-deal",
  cancelShareDealEndpoint: apiUrlProtectedEndpoint + "deals/cancel-share-deal",
  getCalculatedMadadEndpoint: apiUrlProtectedEndpoint + "deals/get-calculated-madad",
  execute49aEndpoint: apiUrlProtectedEndpoint + "deals/execute-49a",
  getAddressesEndpoint: apiUrlProtectedEndpoint + "deals/addresses",
  getLawyersInOfficeEndpoint: apiUrlProtectedEndpoint + "deals/get-lawyers-in-office",

  // reminders
  getAllRemindersEndpoint: apiUrlProtectedEndpoint + "reminders/get-all-reminders",
  getRemindersByDealIdEndpoint: apiUrlProtectedEndpoint + "reminders/get-reminders-by-deal-id",
  createReminderEndpoint: apiUrlProtectedEndpoint + "reminders/create-reminder",
  updateReminderEndpoint: apiUrlProtectedEndpoint + "reminders/update-reminder",
  deleteReminderEndpoint: apiUrlProtectedEndpoint + "reminders/delete-reminder",
  toggleRemindersEndpoint: apiUrlProtectedEndpoint + "reminders/toggle-reminder-state",

  // files
  create49aDocs2990Endpoint: apiUrlProtectedEndpoint + "files/create-49a-docs-2990",
  generate2990Endpoint: apiUrlProtectedEndpoint + "files/generate-2990",
  generate2990JsonEndpoint: apiUrlProtectedEndpoint + "files/generate-2990-json",
  generateJustPrisaEndpoint: apiUrlProtectedEndpoint + "files/generate-just-prisa-doc",
  generateDeductionEndpoint: apiUrlProtectedEndpoint + "files/generate-just-deduction",
  generateDocsEndpoint: apiUrlProtectedEndpoint + "files/generate-docs",
  generateDocsHistoryEndpoint: apiUrlProtectedEndpoint + "files/generate-docs-history",
  generate2990DocsHistoryEndpoint: apiUrlProtectedEndpoint + "files/generate-2990-docs-history",
  getAllDealDocsEndpoint: apiUrlProtectedEndpoint + "files/get-all-deal-docs",
  update2990SpecificFieldsEndpoint: apiUrlProtectedEndpoint + "files/update-2990-specific-fields",
  getDocsFieldsEndpoint: apiUrlProtectedEndpoint + "files/get-docs-fields",
  updateDocsFieldsEndpoint: apiUrlProtectedEndpoint + "files/update-docs-fields",
  downloadDeductionTemplateEndpoint: apiUrlProtectedEndpoint + "files/download-deduction-template",
  translateDeduTemplateToObjEndpoint: apiUrlProtectedEndpoint + "files/translate-dedu-template-to-obj",

  // 3P
  getDollarCurrencyEndpoint: apiUrlProtectedEndpoint + "third-party/get-dollar-currency",

  // accessibility
  screenZoomLevelOptions,
  fontSizeLevelOptions,
  lineHeightLevelOptions,
  letterSpaceLevelOptions,
  linkHighlightOptions,
  mouseCursorLevelOptions
};
