import React, { Fragment, useEffect, useRef, useState } from "react";
import { Grid, Typography, Divider, Box } from "@material-ui/core";
import CustomDialog from "../layout/CustomDialog";

const VideoInfoDialog = (props) => {
  const [url, setUrl] = useState("https://www.youtube.com/embed/jDy9NxehPhc");
  const videoRef = useRef();
  const previousUrl = useRef(url);

  useEffect(() => {
    if (previousUrl.current === url) {
      return;
    }
    if (videoRef.current) {
      videoRef.current.load();
    }
    previousUrl.current = url;
  }, [url]);

  return (
    <>
      <CustomDialog label="סרטוני הדרכה" open={props.open} onClose={props.onClose} maxWidth="md">
        <Grid container direction="row" spacing={3}>
          <Grid container item direction="column" spacing={1} xs={4}>
            <Grid item>
              <Typography variant="h4">סרטוני הדרכה כללי</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">אנא בחר סרטון מהרשימה למטה:</Typography>
            </Grid>
            <Divider orientation="horozontal" />

            <Grid item>
              <Typography variant="subtitle1" onClick={() => setUrl("https://www.youtube.com/embed/jDy9NxehPhc")}>
                <span className={`pointer primary-color ${checkSelected("https://www.youtube.com/embed/jDy9NxehPhc") && " bold"}`}>
                  - פיצול שומות במקרה של מספר מוכרים
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" onClick={() => setUrl("https://www.youtube.com/embed/hAUD_VR3YAM")}>
                <span className={`pointer primary-color ${checkSelected("https://www.youtube.com/embed/hAUD_VR3YAM") && " bold"}`}>
                  - מחשבון פחת
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction="row" xs={1} justify="center">
            <Divider orientation="vertical" />
          </Grid>
          <Grid container item direction="column" spacing={1} xs={7}>
            <Fragment>
              <iframe width="100%" height="400" src={url} frameborder="0" allow="fullscreen"></iframe>
            </Fragment>
          </Grid>
        </Grid>
        <Box width="100%" height="8px" />
      </CustomDialog>
    </>
  );
  function checkSelected(val) {
    if (val === url) return true;
    else return false;
  }
};

export default VideoInfoDialog;
