import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Badge,
  Box
} from "@material-ui/core";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";

import CalculatorIcon from "../../components/layout/CalculatorIcon";
import * as customColors from "../../theme/customColors";
import MoveDealMenus from "./MoveDealMenus";

// Extended optional props:
// headers - array of the headers.
// rows - array of arrays.
// name - table name.
// hideFirstCellsInRow - hide the first cells in each row.
// notifyBadge - set last cell in each row as notificationBadge.
// calcFunc - add cell to the last of each row calc function.
// copyFunc - add cell to the last of each row copy function.
// editFunc - add cell to the last of each row edit function.
// deleteFunc - add cell to the last of each row delete function.
// handleDoubleClick - add dbClick function.

// Components style:
const StyledTableCell = withStyles((theme) => ({
  head: {
    padding: "12px 8px",
    backgroundColor: customColors.lightBlue,
    color: theme.palette.grey[900],
    border: "1px solid " + theme.palette.grey[500]
  },
  body: {
    padding: "12px 8px",
    color: theme.palette.grey[900],
    border: "1px solid " + theme.palette.grey[500]
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useTablePaginationActionsStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

// Table components:
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <StyledTableRow>
        {props.headers.map((headCell, index) => (
          <StyledTableCell key={`${props.name}-header-${index}`} align="right">
            <Typography variant="subtitle2">{headCell}</Typography>
          </StyledTableCell>
        ))}
        {props.calcFunc ? <StyledTableCell /> : null}
        {props.copyFunc ? <StyledTableCell /> : null}
        {props.editFunc ? <StyledTableCell /> : null}
        {props.deleteFunc ? <StyledTableCell /> : null}
        {props.officeLawyers && props.officeLawyers.length ? <StyledTableCell /> : null}
      </StyledTableRow>
    </TableHead>
  );
}

function TablePaginationActions(props) {
  const classes = useTablePaginationActionsStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default function CustomComplexTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [chosenRow, setChosenRow] = useState();

  const [dealSharingOptionsEvent, setDealSharingOptionsEvent] = useState();

  const dealIdBeingSharedOrTransferred = useRef();

  // this is a workaround to make the focus page reset when search is happening with txt
  useEffect(() => setPage(0), [props.resetFilters]);

  const handleChangePage = (event, newPage) => {
    if (props.onPageChange) {
      props.onPageChange(null, newPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeDealOwnershipStart = (e, dealId) => {
    const dealData = props.dealsPrettyIdAndDesc.find((currentDeal) => currentDeal._id === dealId);

    setDealSharingOptionsEvent(e.currentTarget);
    dealIdBeingSharedOrTransferred.current = { dealId, belongToEmail: dealData.belongToEmail };
  };

  return (
    <>
      <Paper variant="outlined" square>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              headers={props.headers}
              calcFunc={props.calcFunc}
              copyFunc={props.copyFunc}
              editFunc={props.editFunc}
              deleteFunc={props.deleteFunc}
              officeLawyers={props.officeLawyers}
            />
            <TableBody>
              {props.rows.map((row, rowIndex) => {
                const styleForChosenRow = chosenRow === rowIndex ? { background: customColors.orange } : {};
                return (
                  <StyledTableRow
                    style={styleForChosenRow}
                    tabIndex={-1}
                    key={`${props.name}-row-${rowIndex}`}
                    onClick={() => setChosenRow(rowIndex)}
                    onDoubleClick={() => (props.handleDoubleClick ? props.handleDoubleClick(row) : null)}
                  >
                    {row
                      .filter((cell, cellIndex) => (props.hideFirstCellsInRow ? cellIndex >= props.hideFirstCellsInRow : true))
                      .map((cell, cellIndex) =>
                        props.notifyBadge && row.length - (props.hideFirstCellsInRow || 0) === cellIndex + 1 ? (
                          cell ? (
                            <StyledTableCell key={`${props.name}-row-${rowIndex}-cell-${cellIndex}`} align="center">
                              <Badge
                                badgeContent={cell}
                                color={chosenRow === rowIndex ? "error" : "secondary"}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left"
                                }}
                              >
                                <NotificationsNoneOutlinedIcon color={chosenRow === rowIndex ? "inherit" : "action"} />
                              </Badge>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell key={`${props.name}-row-${rowIndex}-cell-${cellIndex}`} />
                          )
                        ) : (
                          <StyledTableCell key={`${props.name}-row-${rowIndex}-cell-${cellIndex}`} align="right">
                            <Typography variant="subtitle2" color={chosenRow === rowIndex ? "inherit" : "textPrimary"}>
                              {cell}
                            </Typography>
                          </StyledTableCell>
                        )
                      )}
                    {props.calcFunc ? (
                      <StyledTableCell key={`${props.name}-row-${rowIndex}-calc`} width="32px" align="center">
                        <Box border={1} bgcolor="white">
                          <Tooltip title="חשב">
                            <IconButton size="small" onClick={() => props.calcFunc(row)}>
                              <CalculatorIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    ) : null}
                    {props.officeLawyers && props.officeLawyers.length ? (
                      <StyledTableCell key={`${row[0]}-row-${rowIndex}-ownership`} width="32px" align="center">
                        <Box border={1} bgcolor="white">
                          <Tooltip title="ניהול עסקה">
                            <IconButton
                              size="small"
                              id="sharing-button"
                              aria-controls={dealSharingOptionsEvent ? "sharing-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={dealSharingOptionsEvent ? "true" : undefined}
                              onClick={(e) => onChangeDealOwnershipStart(e, row[0])}
                            >
                              <CompareArrowsIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    ) : null}
                    {props.copyFunc ? (
                      <StyledTableCell key={`${props.name}-row-${rowIndex}-copy`} width="32px" align="center">
                        <Box border={1} bgcolor="white">
                          <Tooltip title="שכפל">
                            <IconButton size="small" onClick={() => props.copyFunc(row)}>
                              <FileCopyOutlinedIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    ) : null}
                    {props.editFunc ? (
                      <StyledTableCell key={`${props.name}-row-${rowIndex}-edit`} width="32px" align="center">
                        <Box border={1} bgcolor="white">
                          <Tooltip title="ערוך">
                            <IconButton size="small" onClick={() => props.editFunc(row)}>
                              <EditIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    ) : null}
                    {props.deleteFunc ? (
                      <StyledTableCell key={`${props.name}-row-${rowIndex}-delete`} width="32px" align="center">
                        <Box border={1} bgcolor="white">
                          <Tooltip title="מחק">
                            <IconButton size="small" onClick={() => props.deleteFunc(row)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    ) : null}
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={props.dealsAllCount || props.rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>

      <MoveDealMenus
        dealIdBeingSharedOrTransferred={dealIdBeingSharedOrTransferred.current}
        transferDeal={props.transferDeal}
        shareDeal={props.shareDeal}
        cancelShareDeal={props.cancelShareDeal}
        currentLawyerEmail={props.currentLawyerEmail}
        dealSharingOptionsEvent={dealSharingOptionsEvent}
        setDealSharingOptionsEvent={setDealSharingOptionsEvent}
        officeLawyers={props.officeLawyers}
        dealsPrettyIdAndDesc={props.dealsPrettyIdAndDesc}
      />
    </>
  );
}
