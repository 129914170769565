import React, { Fragment } from "react";
import { Grid, Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography, Divider, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { tabNames } from "../../../services/docsService/fieldsData";
import CustomAcordion from "../../layout/CustomAcordion";
import CustomButton from "../../layout/CustomButton";
import LabeledTextField from "../../layout/LabeledTextField";
import LabeledAutocomplete from "../../layout/LabeledAutocomplete";
import * as customColors from "../../../theme/customColors";
import moment from "moment";

const MAX_QUESTION_GROUP_DUPLICATE = 3;

function SectionPanel(props) {
  return (
    <CustomAcordion summaryText={props.sectionName} defaultExpanded>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {props.panelState.map((group, index) => {
            return (
              <Grid key={`question-group-${index}`} container direction="column" spacing={2}>
                {index > 0 ? (
                  <Grid item>
                    <Divider />
                  </Grid>
                ) : null}
                <Grid container item direction="row" spacing={2} justify="flex-start" alignItems="center">
                  {props.questions.map((question) => {
                    let content = (
                      <Typography key={`${question.name}-${index}`} color="secondary">
                        HERE WILL BE THE QUESTION
                      </Typography>
                    );
                    if (["text", "select", "number", "date"].includes(question.type)) {
                      const value =
                        question.type === "date"
                          ? moment(props.panelState[index][question.name]).format("YYYY-MM-DD")
                          : props.panelState[index][question.name];
                      content = (
                        <Grid item key={`${question.name}-${index}`}>
                          <LabeledTextField
                            type={question.type}
                            name={question.name}
                            value={value}
                            label={question.label}
                            options={question.options}
                            textFieldWidth={170}
                            onChange={(e) => props.handleOnChange(e, props.panel, index)}
                          />
                        </Grid>
                      );
                    } else if (question.type === "autocomplete")
                      content = (
                        <Grid item key={`${question.name}-${index}`}>
                          <LabeledAutocomplete
                            name={question.name}
                            value={props.panelState[index][question.name]}
                            freeSolo={question.freeSolo !== undefined ? question.freeSolo : true}
                            label={question.label}
                            options={question.options}
                            textFieldWidth={150}
                            onChange={(e) => {
                              props.handleOnChange(e, props.panel, index);
                            }}
                          />
                        </Grid>
                      );
                    else if (question.type === "radio")
                      content = (
                        <Grid item xs={12} key={`${question.name}-${index}`}>
                          <Box display="flex" paddingX={3} paddingY={1} border={1} borderRadius={10} borderColor={customColors.gray}>
                            <FormControl component="fieldset">
                              <Grid container item direction="row" spacing={1} alignItems="center" justify="flex-start">
                                <Grid item>
                                  <Typography variant="subtitle1" align="right">
                                    {question.label}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <RadioGroup
                                    row
                                    name={question.name}
                                    value={
                                      typeof props.panelState[index][question.name] === "boolean"
                                        ? Boolean(props.panelState[index][question.name]).toString()
                                        : props.panelState[index][question.name]
                                    }
                                    onChange={(e) => props.handleOnChange(e, props.panel, index)}
                                  >
                                    {question.options.map((opt) => (
                                      <FormControlLabel
                                        key={`${question.name}-${index}-${opt.value}`}
                                        value={opt.value}
                                        control={<Radio color="primary" />}
                                        label={opt.label}
                                        labelPlacement="end"
                                      />
                                    ))}
                                  </RadioGroup>
                                </Grid>
                              </Grid>
                            </FormControl>
                          </Box>
                        </Grid>
                      );
                    return content;
                  })}
                  {index > 0 ? (
                    <Grid item>
                      <Tooltip title="מחק">
                        <DeleteIcon
                          fontSize="small"
                          style={{ cursor: "pointer", verticalAlign: "middle" }}
                          onClick={() => props.removeDuplicateQuestions(props.panel, index)}
                        />
                      </Tooltip>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        {displayPlusBtn(props.sectionName) ? (
          <Fragment>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center">
                <Grid item>
                  <CustomButton
                    label="הוספה"
                    textWidth={100}
                    onClick={() => {
                      props.addDuplicateQuestions(props.panel);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        ) : null}
      </Grid>
    </CustomAcordion>
  );

  function displayPlusBtn(panel) {
    let shouldDisplay = false;
    if (
      panel === tabNames.SELLERS_INFO ||
      panel === tabNames.BUYERS_INFO ||
      panel === tabNames.THIRD_PARTY ||
      panel === tabNames.LAWYER_INFO
    )
      shouldDisplay = props.panelState && props.panelState.length >= MAX_QUESTION_GROUP_DUPLICATE ? false : true;
    return shouldDisplay;
  }
}

export default SectionPanel;
