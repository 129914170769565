import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NavigationBar from "../components/layout/NavigationBar";
import CustomToolBar from "../components/layout/CustomToolBar";
import Footer from "../components/layout/Footer";
import DealsContainer from "./dealsContainer";
import JustPrisaContainer from "./justPrisaContainer";
import MyDealsContainerNoSP from "./myDealsContainerNoSP";
import UsersManagement from "../components/managerComponents/usersManagement";
import AdminRoute from "../auth/adminGuard";
import SalesRoute from "../auth/salesGuard";
import AdminContainer from "./adminContainer";

class ApplicationCon extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <CustomToolBar />
        <div className="container pb-70 pt-70 center">
          <Switch>
            <Route path="/my-deals" component={MyDealsContainerNoSP} />
            <Route path="/deal/:tab/:dealId" component={DealsContainer} />
            <Route path="/just-prisa/:tab/:dealId" component={JustPrisaContainer} />
            <SalesRoute path="/users-management/:searchByCardNum?" component={UsersManagement} />
            <AdminRoute path="/admin" component={AdminContainer} />
            <Route path="/">
              <Redirect to="/my-deals" />
            </Route>
          </Switch>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default ApplicationCon;
