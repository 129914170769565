import React, { Component } from "react";
import { Link, Typography } from "@material-ui/core";

// Original optional props:
// label
// onClick
// align
// variant

// Extended optional props:
// disabled

class CustomLink extends Component {
  render() {
    return (
      <Typography align={this.props.align ? this.props.align : "right"}>
        <Link
          style={{ cursor: this.props.disabled ? "default" : "pointer" }}
          color={this.props.disabled ? "textSecondary" : "primary"}
          underline={this.props.disabled ? "none" : "hover"}
          component="button"
          variant={this.props.variant ? this.props.variant : "subtitle2"}
          onClick={this.props.disabled ? null : this.props.onClick}
        >
          {this.props.label}
        </Link>
      </Typography>
    );
  }
}

export default CustomLink;
