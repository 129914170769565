import React, { Component } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { Grid, Button, Tooltip, Divider } from "@material-ui/core";

import CustomDialog from "../layout/CustomDialog";
import CalculatorIcon from "../layout/CalculatorIcon";
import LabeledTextField from "../layout/LabeledTextField";
import dealService from "../../services/dealsService";

class CalculatorDialog extends Component {
  state = {
    startDate: "",
    endDate: "",
    shekelAmount: null,
    startActualMadadDate: "",
    startActualMadadValue: null,
    endActualMadadDate: "",
    endActualMadadValue: null,
    shekelMemudadAmount: null,
    changingPercent: null
  };

  render() {
    return (
      <CustomDialog label="מחשבון מדד" open={this.props.open} onClose={this.props.onClose} maxWidth="xs">
        <Grid container direction="column" spacing={1} style={{ marginTop: "8px", marginBottom: "8px" }}>
          <Grid item>
            <LabeledTextField
              type="date"
              value={this.state.startDate}
              name="startDate"
              label="תחילת תקופה"
              labelWidth={130}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item>
            <LabeledTextField
              type="date"
              value={this.state.endDate}
              name="endDate"
              label="סיום תקופה"
              labelWidth={130}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item>
            <LabeledTextField
              type="number"
              value={this.state.shekelAmount}
              name="shekelAmount"
              label='הסכום בש"ח'
              labelWidth={130}
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          <Grid item style={{ marginRight: "138px" }}>
            <Tooltip title="חשב">
              <Button
                variant="contained"
                color="secondary"
                style={{ maxWidth: "50px", minWidth: "50px" }}
                endIcon={<CalculatorIcon style={{ marginRight: "12px" }} />}
                onClick={() => this.getCalculatedMadad()}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item xs={6}>
                <LabeledTextField
                  type="text"
                  labeledTextFieldDirection="column"
                  label="חודש קובע למדד:"
                  disabled
                  value={this.state.startActualMadadDate}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledTextField
                  type="number"
                  labeledTextFieldDirection="column"
                  label="ערך מדד בתאריך:"
                  disabled
                  value={this.state.startActualMadadValue}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item xs={6}>
                <LabeledTextField type="text" disabled value={this.state.endActualMadadDate} />
              </Grid>
              <Grid item xs={6}>
                <LabeledTextField type="number" disabled value={this.state.endActualMadadValue} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item xs={6}>
                <LabeledTextField
                  type="number"
                  labeledTextFieldDirection="column"
                  label='הסכום בש"ח ממודד:'
                  disabled
                  value={this.state.shekelMemudadAmount}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledTextField
                  type="number"
                  labeledTextFieldDirection="column"
                  label="השינוי באחוזים:"
                  disabled
                  value={this.state.changingPercent}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomDialog>
    );
  }

  handleOnChange = (e) => {
    let val = e.target.value;
    const newState = { ...this.state, [e.target.name]: val };
    this.setState(newState);
  };

  madadValidations() {
    if (!this.state.startDate) {
      return "תחילת תקופה הינו שדה חובה";
    } else if (this.state.startDate > moment(new Date()).format("YYYY-MM-DD")) {
      return "תחילת תקופה לא יכול להיות תאריך עתידי";
    } else if (!this.state.endDate) {
      return "סיום תקופה הינו שדה חובה";
    } else if (this.state.endDate > moment(new Date()).format("YYYY-MM-DD")) {
      return "סיום תקופה לא יכול להיות תאריך עתידי";
    } else if (this.state.startDate > this.state.endDate) {
      return "תאריך תחילת תקופה לא יכול להיות לאחר תאריך סיום תקופה";
    } else if (!this.state.shekelAmount) {
      return 'הסכום בש"ח הינו שדה חובה';
    }
    return null;
  }

  getCalculatedMadad() {
    const validationErr = this.madadValidations();
    if (validationErr) {
      return Swal.fire("", validationErr, "error");
    } else {
      dealService
        .getCalculatedMadad(this.state.startDate, this.state.endDate, this.state.shekelAmount)
        .then((res) => {
          if (res.success) {
            const {
              startActualMadadValue,
              startActualMadadDate,
              endActualMadadValue,
              endActualMadadDate,
              shekelMemudadAmount,
              changingPercent
            } = res.data;
            this.setState({
              startActualMadadValue,
              startActualMadadDate,
              endActualMadadValue,
              endActualMadadDate,
              shekelMemudadAmount,
              changingPercent
            });
          }
        })
        .catch((err) => {
          return Swal.fire("", err, "error");
        });
    }
  }
}

export default CalculatorDialog;
