import React from "react";
import { setNewAccessibleState } from "../../store/actions/appSettingActions";
import { connect } from "react-redux";
import CustomDialog from "../layout/CustomDialog";
import config from "../../config";
import { useState } from "react";
import "../../assets/css/accessibility.css";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ZoomInRoundedIcon from "@material-ui/icons/ZoomInRounded";
import HeightRoundedIcon from "@material-ui/icons/HeightRounded";
import SpaceBarIcon from "@material-ui/icons/SpaceBar";
import LinkIcon from "@material-ui/icons/Link";
import MouseOutlinedIcon from "@material-ui/icons/MouseOutlined";

import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const AccessibilityDialog = (props) => {
  const zoomLevels = config.screenZoomLevelOptions;
  const fontSizeLevels = config.fontSizeLevelOptions;
  const lineHeightLevels = config.lineHeightLevelOptions;
  const letterSpaceLevels = config.letterSpaceLevelOptions;
  const linkHighlightLevels = config.linkHighlightOptions;
  const mouseCursorLevels = config.mouseCursorLevelOptions;

  const [zoomIndex, setZoomIndex] = useState(zoomLevels.indexOf(props.screenZoomLevel));
  const [fontIndex, setFontIndex] = useState(fontSizeLevels.indexOf(props.fontSizeLevel));
  const [lineHeightIndex, setLineHeightIndex] = useState(lineHeightLevels.indexOf(props.lineHeightLevel));
  const [letterSpaceIndex, setLetterSpaceIndex] = useState(letterSpaceLevels.indexOf(props.letterSpaceLevel));
  const [linkHighlightIndex, setLinkHighlightIndex] = useState(linkHighlightLevels.indexOf(props.linkHighlightLevel));
  const [mouseCursorIndex, setMouseCursorIndex] = useState(mouseCursorLevels.indexOf(props.mouseCursorLevel));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const adjustSettingAndUpdateMessage = (settingName, newSettingClass, snackbarMessage) => {
    props.setNewAccessibleState({
      key: settingName,
      newValue: newSettingClass
    });

    setSnackbarMessage(snackbarMessage);
    setSnackbarOpen(true);
  };

  const handleZoomIn = () => {
    if (zoomIndex === 3) return;
    const newZoomIndex = zoomIndex + 1;
    setZoomIndex(newZoomIndex);
    adjustSettingAndUpdateMessage("screenZoomLevel", "zoom-level-" + newZoomIndex, `Zoom level: ${newZoomIndex}`);
  };

  const handleZoomOut = () => {
    if (zoomIndex === 0) return;
    const newZoomIndex = zoomIndex - 1;
    const newZoomLevelClass = newZoomIndex === 0 ? "" : "zoom-level-" + newZoomIndex;
    setZoomIndex(newZoomIndex);
    adjustSettingAndUpdateMessage("screenZoomLevel", newZoomLevelClass, `Zoom level: ${newZoomIndex}`);
  };

  const handleFontUp = () => {
    if (fontIndex === 2) return;
    const newFontIndex = fontIndex + 1;
    setFontIndex(newFontIndex);
    adjustSettingAndUpdateMessage("fontSizeLevel", "font-increase-size-level-" + newFontIndex, `Font level: ${newFontIndex}`);
  };

  const handleFontDown = () => {
    if (fontIndex === 0) return;
    const newFontIndex = fontIndex - 1;
    const newFontLevelClass = newFontIndex === 0 ? "" : "font-increase-size-level-" + newFontIndex;
    setFontIndex(newFontIndex);
    adjustSettingAndUpdateMessage("fontSizeLevel", newFontLevelClass, `Font level: ${newFontIndex}`);
  };

  const handleLineHeightUp = () => {
    if (lineHeightIndex === 2) return;
    const newLineHeightIndex = lineHeightIndex + 1;
    setLineHeightIndex(newLineHeightIndex);
    adjustSettingAndUpdateMessage(
      "lineHeightLevel",
      "line-height-increase-level-" + newLineHeightIndex,
      `Line height level: ${newLineHeightIndex}`
    );
  };

  const handleLineHeightDown = () => {
    if (lineHeightIndex === 0) return;
    const newLineHeightIndex = lineHeightIndex - 1;
    const newLineHeightLevelClass = newLineHeightIndex === 0 ? "" : "line-height-increase-level-" + newLineHeightIndex;
    setLineHeightIndex(newLineHeightIndex);
    adjustSettingAndUpdateMessage("lineHeightLevel", newLineHeightLevelClass, `Line height level: ${newLineHeightIndex}`);
  };

  const handleLetterSpaceUp = () => {
    if (letterSpaceIndex === 2) return;
    const newLetterSpaceIndex = letterSpaceIndex + 1;
    setLetterSpaceIndex(newLetterSpaceIndex);
    adjustSettingAndUpdateMessage(
      "letterSpaceLevel",
      "letter-space-increase-level-" + newLetterSpaceIndex,
      `Letter space level: ${newLetterSpaceIndex}`
    );
  };

  const handleLetterSpaceDown = () => {
    if (letterSpaceIndex === 0) return;
    const newLetterSpaceIndex = letterSpaceIndex - 1;
    const newLetterSpaceLevelClass = newLetterSpaceIndex === 0 ? "" : "letter-space-increase-level-" + newLetterSpaceIndex;
    setLetterSpaceIndex(newLetterSpaceIndex);
    adjustSettingAndUpdateMessage("letterSpaceLevel", newLetterSpaceLevelClass, `Letter space level: ${newLetterSpaceIndex}`);
  };

  const handleLinkHighlightUp = () => {
    if (linkHighlightIndex === 1) return;
    const newLinkHighlightIndex = linkHighlightIndex + 1;
    setLinkHighlightIndex(newLinkHighlightIndex);
    adjustSettingAndUpdateMessage("linkHighlightLevel", "link-highlight", "Links highlighted");
  };

  const handleLinkHighlightDown = () => {
    if (linkHighlightIndex === 0) return;
    const newLinkHighlightIndex = linkHighlightIndex - 1;
    const newLinkHighlightLevelClass = newLinkHighlightIndex === 0 ? "" : "link-highlight" + newLinkHighlightIndex;
    setLinkHighlightIndex(newLinkHighlightIndex);
    adjustSettingAndUpdateMessage("linkHighlightLevel", newLinkHighlightLevelClass, "Links unhighlighted");
  };

  const handleMouseCursorUp = () => {
    if (mouseCursorIndex === 1) return;
    const newMouseCursorIndex = mouseCursorIndex + 1;
    setMouseCursorIndex(newMouseCursorIndex);
    adjustSettingAndUpdateMessage("mouseCursorLevel", "large-mouse-cursor", "Cursor size: large");
  };

  const handleMouseCursorDown = () => {
    if (mouseCursorIndex === 0) return;
    const newMouseCursorIndex = mouseCursorIndex - 1;
    const newMouseCursorLevelClass = newMouseCursorIndex === 0 ? "" : "large-mouse-cursor" + newMouseCursorIndex;
    setMouseCursorIndex(newMouseCursorIndex);
    adjustSettingAndUpdateMessage("mouseCursorLevel", newMouseCursorLevelClass, "Cursor size: normal");
  };

  return (
    <CustomDialog label="נגישות" open={props.open} onClose={props.onClose} maxWidth="sm">
      <div className="accessibility-window">
        <div className="accessibility-button">
          <ZoomInRoundedIcon className="accessibility-icon-size" />
          <div>Zoom</div>
          <div className="add-remove-div">
            <button className="add-remove-buttons" onClick={handleZoomIn} disabled={zoomIndex === 3}>
              <AddIcon />
            </button>
            <div className="accessibility-button-line" />
            <button className="add-remove-buttons" onClick={handleZoomOut} disabled={zoomIndex === 0}>
              <RemoveIcon />
            </button>
          </div>
        </div>

        <div className="accessibility-button">
          <TextFieldsIcon className="accessibility-icon-size" />
          <div>Font size</div>
          <div className="add-remove-div">
            <button className="add-remove-buttons" onClick={handleFontUp} disabled={fontIndex === 2}>
              <AddIcon />
            </button>
            <div className="accessibility-button-line" />
            <button className="add-remove-buttons" onClick={handleFontDown} disabled={fontIndex === 0}>
              <RemoveIcon />
            </button>
          </div>
        </div>

        <div className="accessibility-button">
          <HeightRoundedIcon className="accessibility-icon-size" />
          <div>Line height</div>
          <div className="add-remove-div">
            <button className="add-remove-buttons" onClick={handleLineHeightUp} disabled={lineHeightIndex === 2}>
              <AddIcon />
            </button>
            <div className="accessibility-button-line" />
            <button className="add-remove-buttons" onClick={handleLineHeightDown} disabled={lineHeightIndex === 0}>
              <RemoveIcon />
            </button>
          </div>
        </div>

        <div className="accessibility-button">
          <SpaceBarIcon className="accessibility-icon-size" />
          <div>Letter space</div>
          <div className="add-remove-div">
            <button className="add-remove-buttons" onClick={handleLetterSpaceUp} disabled={letterSpaceIndex === 2}>
              <AddIcon />
            </button>
            <div className="accessibility-button-line" />
            <button className="add-remove-buttons" onClick={handleLetterSpaceDown} disabled={letterSpaceIndex === 0}>
              <RemoveIcon />
            </button>
          </div>
        </div>

        <div className="accessibility-button">
          <LinkIcon className="accessibility-icon-size" />
          <div>Highlight links</div>
          <div className="add-remove-div">
            <button className="add-remove-buttons" onClick={handleLinkHighlightUp} disabled={linkHighlightIndex === 1}>
              <AddIcon />
            </button>
            <div className="accessibility-button-line" />
            <button className="add-remove-buttons" onClick={handleLinkHighlightDown} disabled={linkHighlightIndex === 0}>
              <RemoveIcon />
            </button>
          </div>
        </div>

        <div className="accessibility-button">
          <MouseOutlinedIcon className="accessibility-icon-size" />
          <div>Enlarge cursor</div>
          <div className="add-remove-div">
            <button className="add-remove-buttons" onClick={handleMouseCursorUp} disabled={mouseCursorIndex === 1}>
              <AddIcon />
            </button>
            <div className="accessibility-button-line" />
            <button className="add-remove-buttons" onClick={handleMouseCursorDown} disabled={mouseCursorIndex === 0}>
              <RemoveIcon />
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity="info" icon={false}>
          <Typography variant="subtitle2" align="center">
            {snackbarMessage}
          </Typography>
        </Alert>
      </Snackbar>
    </CustomDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    screenZoomLevel: state.appSettingsReducer.screenZoomLevel,
    fontSizeLevel: state.appSettingsReducer.fontSizeLevel,
    lineHeightLevel: state.appSettingsReducer.lineHeightLevel,
    letterSpaceLevel: state.appSettingsReducer.letterSpaceLevel,
    linkHighlightLevel: state.appSettingsReducer.linkHighlightLevel,
    mouseCursorLevel: state.appSettingsReducer.mouseCursorLevel
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewAccessibleState: (data) => dispatch(setNewAccessibleState(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessibilityDialog);
