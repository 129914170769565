import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import LabeledTextField from "./LabeledTextField";
import { ignoreInvalidCharForNumber } from "../../services/utils/utils";

export default function DealsSearchComponent(props) {
  const [dealPrettyId, setDealPrettyId] = useState("");
  const [dealName, setDealName] = useState("");

  const handleSearchChangeAndInformFather = (key, value) => {
    if (key === "dealPrettyId") {
      setDealPrettyId(ignoreInvalidCharForNumber(value));
      props.onSearchFiltersChange(key, ignoreInvalidCharForNumber(value));
    } else {
      setDealName(value);
      props.onSearchFiltersChange(key, value);
    }
  };

  return (
    <Grid container item direction="row" spacing={2} justify="flex-start" alignItems="center">
      <Grid item>
        <LabeledTextField
          type="text"
          value={dealPrettyId}
          placeholder="אתר מספר עסקה"
          onChange={(e) => handleSearchChangeAndInformFather("dealPrettyId", e.target.value)}
        />
      </Grid>
      <Grid item>
        <LabeledTextField
          type="text"
          value={dealName}
          placeholder="אתר שם עסקה"
          onChange={(e) => handleSearchChangeAndInformFather("dealName", e.target.value)}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" color="secondary" onClick={props.searchDealsWithFilters}>
          חפש\י
        </Button>
      </Grid>
    </Grid>
  );
}
