import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import HelpDialog from "../../layout/HelpDialog";
import helpTexts from "../../../assets/texts/helpTexts";
import { date, getIlsCurrencyDateBased, ignoreInvalidCharForNumber, numberWithCommas } from "../../../services/utils/utils";
import moment from "moment";

const MIN_SUPPORTED_DATE = new Date("1950-01-01");
const MAX_SUPPORTED_DATE = new Date();

export default function PinuiBinui({ setFormInvalidMsg, setIsFormInvalid, setDataToSave }) {
  const [didGetMoreMoneyAndApaForPinuiBinui, setDidGetMoreMoneyAndApaForPinuiBinui] = useState(null);
  const [isApaGotImproved, setIsApaGotImproved] = useState(null);
  const [amountPaidToImproveApa, setAmountPaidToImproveApa] = useState("");
  const [shetahApaWasAllowed, setShetahApaWasAllowed] = useState("");
  const [improvedGotShetahApa, setImprovedGotShetahApa] = useState("");

  const [isMoreThanTikratShovi, setIsMoreThanTikratShovi] = useState(null);
  const [pinuiBinuiExtraMoneyValue, setPinuiBinuiExtraMoneyValue] = useState("");
  const [valueSoldApa, setValueSoldApa] = useState("");
  const [dayApaPurchased, setDayApaPurchased] = useState(null);
  const [totalPropertyCost, setTotalPropertyCost] = useState("");
  const [valueSoldOfPinuiBinui, setValueSoldOfPinuiBinui] = useState("");
  const [dayPinuiBinuiDeal, setDayPinuiBinuiDeal] = useState(null);
  const [valueGotPartialPtorFor, setValueGotPartialPtorFor] = useState("");

  useEffect(() => {
    if (!didGetMoreMoneyAndApaForPinuiBinui) return;
    validateForm();
  }, [didGetMoreMoneyAndApaForPinuiBinui]);

  useEffect(() => {
    validateForm();
  }, [
    pinuiBinuiExtraMoneyValue,
    valueSoldApa,
    totalPropertyCost,
    valueSoldOfPinuiBinui,
    dayPinuiBinuiDeal,
    valueGotPartialPtorFor,
    isApaGotImproved,
    amountPaidToImproveApa,
    shetahApaWasAllowed,
    improvedGotShetahApa
  ]);

  return (
    <div>
      <FormControl>
        <FormLabel>
          <div className="helper-and-text-wrapper">
            האם שווי הדירה שהתקבלה במסגרת פינוי בינוי כללה תמורה כספית נוספת בגין שנמוך הדירה?
            <div className="helper-wrapper">
              <HelpDialog helpDialog={helpTexts.pinuiBinuiGotMoneyReducedApaValue} label="קבלת תמורה כספית נוספת בגין שנמוך הדירה" />
            </div>
          </div>
        </FormLabel>
        <RadioGroup
          value={didGetMoreMoneyAndApaForPinuiBinui}
          row
          onChange={(e) => {
            resetFields();
            setDidGetMoreMoneyAndApaForPinuiBinui(e.target.value);
          }}
        >
          <FormControlLabel value="gotMoreMoneyAndApaPinuiBinui" control={<Radio color="primary" />} label="כן" />
          <FormControlLabel value="didntGetMoreMoneyAndApaPinuiBinui" control={<Radio color="primary" />} label="לא" />
        </RadioGroup>
      </FormControl>

      {didGetMoreMoneyAndApaForPinuiBinui === "gotMoreMoneyAndApaPinuiBinui" && (
        <div>
          <hr />
          <table className="fs-18" cellSpacing="10">
            <tbody>
              <tr>
                <td className="td-size-200">יום רכישה מקורי של הדירה</td>
                <td>
                  <TextField
                    type="date"
                    onChange={(e) => setDayApaPurchased(e.target.value)}
                    value={dayApaPurchased || ""}
                    onBlur={validateForm}
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>שווי הרכישה המקורי של הדירה</td>
                <td>
                  <TextField
                    className="ironit-input"
                    onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                    value={numberWithCommas(totalPropertyCost)}
                  />
                </td>
                <td>
                  {getIlsCurrencyDateBased(dayApaPurchased) && (
                    <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
                  )}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="td-size-200">שווי התמורה הכספית הנוספת</td>
                <td>
                  <TextField
                    className="ironit-input"
                    onChange={(e) => setPinuiBinuiExtraMoneyValue(e.target.value.replace(/,/g, ""))}
                    value={numberWithCommas(pinuiBinuiExtraMoneyValue)}
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>יום המכירה שנקבע בעסקת פינוי בינוי</td>
                <td>
                  <TextField
                    onBlur={validateForm}
                    type="date"
                    onChange={(e) => setDayPinuiBinuiDeal(e.target.value)}
                    value={dayPinuiBinuiDeal || ""}
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="td-size-200">שווי המכירה של הדירה הנמכרת כיום</td>
                <td>
                  <TextField
                    className="ironit-input"
                    onChange={(e) => setValueSoldApa(e.target.value.replace(/,/g, ""))}
                    value={numberWithCommas(valueSoldApa)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {didGetMoreMoneyAndApaForPinuiBinui === "didntGetMoreMoneyAndApaPinuiBinui" && (
        <>
          <div className="pinui-binui-container">
            <div className="tikrat-shovi-icon">
              {/* TODO - check where to put the tool tip */}
              {/* <HelpDialog helpDialog={helpTexts.pinuiBinuiTikratShovi} label="אופן קבלת הממכר" /> */}
            </div>
            <FormControl>
              <FormLabel>
                <div className="helper-and-text-wrapper">
                  האם במסגרת עסקת פינוי בינוי הנישום שידרג את הדירה?
                  <div className="helper-wrapper">
                    <HelpDialog helpDialog={helpTexts.pinuiBinuiPaidMoneyIncreasedApaValue} label="שדרוג הדירה על ידי הוספת תשלום" />
                  </div>
                </div>
              </FormLabel>
              <RadioGroup value={isApaGotImproved} row onChange={(e) => setIsApaGotImproved(e.target.value)}>
                <FormControlLabel value="gotImprovedApa" control={<Radio color="primary" />} label="כן" />
                <FormControlLabel value="didntGetImprovedApa" control={<Radio color="primary" />} label="לא" />
              </RadioGroup>
            </FormControl>
          </div>

          {isApaGotImproved === "gotImprovedApa" && (
            <div className="pinui-binui-container">
              <h2 className="yellow-color">במקרה זה יהיו שני ימי רכישה</h2>
              <table className="fs-18" cellSpacing="10">
                <tbody>
                  <tr>
                    <td>יום רכישה מקורי של הדירה</td>
                    <td>
                      <TextField
                        onBlur={validateForm}
                        type="date"
                        onChange={(e) => setDayApaPurchased(e.target.value)}
                        value={dayApaPurchased || ""}
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>שווי הרכישה המקורי של הדירה</td>
                    <td>
                      <TextField
                        className="ironit-input"
                        onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                        value={numberWithCommas(totalPropertyCost)}
                      />
                    </td>
                    <td>
                      {getIlsCurrencyDateBased(dayApaPurchased) && (
                        <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
                      )}
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>יום המכירה שנקבע בעסקת פינוי בינוי</td>
                    <td>
                      <TextField
                        onBlur={validateForm}
                        type="date"
                        onChange={(e) => setDayPinuiBinuiDeal(e.target.value)}
                        value={dayPinuiBinuiDeal || ""}
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>הסכום ששילם הבעלים בגין שדרוג הדירה</td>
                    <td>
                      <TextField
                        className="ironit-input"
                        onChange={(e) => setAmountPaidToImproveApa(e.target.value.replace(/,/g, ""))}
                        value={numberWithCommas(amountPaidToImproveApa)}
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>השטח במ"ר של הדירה לה היה זכאי</td>
                    <td>
                      <TextField
                        className="ironit-input"
                        onChange={(e) => setShetahApaWasAllowed(e.target.value)}
                        value={ignoreInvalidCharForNumber(shetahApaWasAllowed)}
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>השטח במ"ר של הדירה המשודרגת</td>
                    <td>
                      <TextField
                        className="ironit-input"
                        onChange={(e) => setImprovedGotShetahApa(e.target.value)}
                        value={ignoreInvalidCharForNumber(improvedGotShetahApa)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {isApaGotImproved === "didntGetImprovedApa" && (
            <>
              <div className="pinui-binui-container">
                <div className="tikrat-shovi-icon"></div>
                <FormControl>
                  <FormLabel>האם בעסקת פינוי בינוי נתקבל פטור מלא ממס שבח?</FormLabel>
                  <RadioGroup value={isMoreThanTikratShovi} row onChange={(e) => setIsMoreThanTikratShovi(e.target.value)}>
                    <FormControlLabel value="lessThanTikratShovi" control={<Radio color="primary" />} label="כן" />
                    <FormControlLabel value="moreThanTikratShovi" control={<Radio color="primary" />} label="לא" />
                  </RadioGroup>
                </FormControl>
              </div>

              {isMoreThanTikratShovi === "moreThanTikratShovi" && (
                <div>
                  <h2 className="yellow-color">במקרה זה יהיו שני ימי רכישה</h2>
                  <Alert severity="info">
                    <AlertTitle>לתשומת ליבך:</AlertTitle>
                    <Typography variant="subtitle2" align="right">
                      אופן התחשיב הזה טרם נבחן בחוק ובפסיקה ואין לגביו הו"ב או החלטת מיסוי כלשהי ולכן מומלץ להיוועץ עם מומחה מיסוי בטרם
                      עריכת התחשיב
                      <br />
                    </Typography>
                  </Alert>
                  <div className="mt-20">
                    <b className="fs-18 pr-10">שווי רכישה ראשון:</b>
                    <table className="fs-18" cellSpacing="10">
                      <tbody>
                        <tr>
                          <td>יום רכישה מקורי של הדירה</td>
                          <td>
                            <TextField
                              onBlur={validateForm}
                              type="date"
                              onChange={(e) => setDayApaPurchased(e.target.value)}
                              value={dayApaPurchased || ""}
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td className="td-size-200">
                            שווי הרכישה המקורי של הדירה בניכוי שווי הרכישה שנקבע בעסקת פינוי בינוי בגין החלק החייב במס
                          </td>
                          <td>
                            <TextField
                              className="ironit-input"
                              onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                              value={numberWithCommas(totalPropertyCost)}
                            />
                          </td>
                          <td>
                            {getIlsCurrencyDateBased(dayApaPurchased) && (
                              <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="mt-30">
                      <b className="fs-18 pr-10">שווי רכישה שני:</b>
                      <table className="fs-18" cellSpacing="10">
                        <tbody>
                          <tr>
                            <td>יום המכירה שנקבע בעסקת פינוי בינוי</td>
                            <td>
                              <TextField
                                onBlur={validateForm}
                                type="date"
                                onChange={(e) => setDayPinuiBinuiDeal(e.target.value)}
                                value={dayPinuiBinuiDeal || ""}
                              />
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td className="td-size-200">שווי המכירה שנקבע בעסקת פינוי בינוי בגין החלק החייב במס</td>
                            <td>
                              <TextField
                                className="ironit-input"
                                onChange={(e) => setValueSoldOfPinuiBinui(e.target.value.replace(/,/g, ""))}
                                value={numberWithCommas(valueSoldOfPinuiBinui)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-30">
                      <b className="fs-18 pr-10">שווי המכירה בעסקת הפינוי בינוי</b>
                      <table className="fs-18" cellSpacing="10">
                        <tbody>
                          <tr>
                            <td className="td-size-200">שווי המכירה שנקבע בעסקת הפינוי בינוי בגין החלק הפטור ממס</td>
                            <td>
                              <TextField
                                className="ironit-input"
                                onChange={(e) => setValueGotPartialPtorFor(e.target.value.replace(/,/g, ""))}
                                value={numberWithCommas(valueGotPartialPtorFor)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {didGetMoreMoneyAndApaForPinuiBinui === "didntGetMoreMoneyAndApaPinuiBinui" &&
                isMoreThanTikratShovi === "lessThanTikratShovi" && (
                  <table className="fs-18" cellSpacing="10">
                    <tbody>
                      <tr>
                        <td className="td-size-200">יום רכישה מקורי של הדירה</td>
                        <td>
                          <TextField
                            type="date"
                            onChange={(e) => setDayApaPurchased(e.target.value)}
                            value={dayApaPurchased || ""}
                            onBlur={validateForm}
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>שווי הרכישה המקורי של הדירה</td>
                        <td>
                          <TextField
                            className="ironit-input"
                            onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                            value={numberWithCommas(totalPropertyCost)}
                          />
                        </td>
                        <td>
                          {getIlsCurrencyDateBased(dayApaPurchased) && (
                            <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
            </>
          )}
        </>
      )}
    </div>
  );

  function resetFields() {
    setIsApaGotImproved(null);
    setAmountPaidToImproveApa("");
    setShetahApaWasAllowed("");
    setImprovedGotShetahApa("");
    setIsMoreThanTikratShovi(null);
    setPinuiBinuiExtraMoneyValue("");
    setValueSoldApa("");
    setDayApaPurchased(null);
    setTotalPropertyCost("");
    setValueSoldOfPinuiBinui("");
    setDayPinuiBinuiDeal(null);
    setValueGotPartialPtorFor("");
  }

  function validateForm() {
    const createDataToSave = {};

    if (!didGetMoreMoneyAndApaForPinuiBinui) return;

    if (didGetMoreMoneyAndApaForPinuiBinui === "gotMoreMoneyAndApaPinuiBinui") {
      // yes
      if (!totalPropertyCost || !dayApaPurchased || !pinuiBinuiExtraMoneyValue || !valueSoldApa || !dayPinuiBinuiDeal) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (!validateMinAndMaxForDate(date(dayApaPurchased))) return;
      if (!validateMinAndMaxForDate(date(dayPinuiBinuiDeal))) return;
      if (!validateDateLaterThanAnother(dayApaPurchased, dayPinuiBinuiDeal)) return;

      createDataToSave.pinuiBinuiExtraMoneyValue = pinuiBinuiExtraMoneyValue;
      createDataToSave.valueSoldApa = valueSoldApa;
      createDataToSave.pinuiBinuiExtraValueDate = dayPinuiBinuiDeal;

      createDataToSave.purchaseDate = dayApaPurchased;
      createDataToSave.purchaseValue = totalPropertyCost;
    } else if (isApaGotImproved === "gotImprovedApa") {
      // no, yes
      if (
        !totalPropertyCost ||
        !dayApaPurchased ||
        !dayPinuiBinuiDeal ||
        !amountPaidToImproveApa ||
        !shetahApaWasAllowed ||
        !improvedGotShetahApa
      ) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (!validateMinAndMaxForDate(date(dayApaPurchased))) return;
      if (!validateMinAndMaxForDate(date(dayPinuiBinuiDeal))) return;
      if (!validateDateLaterThanAnother(dayApaPurchased, dayPinuiBinuiDeal)) return;

      if (Number(shetahApaWasAllowed) >= Number(improvedGotShetahApa)) {
        setFormInvalidMsg('השטח במ"ר של הדירה המשודרגת חייב להיות גדול משטח הדירה הקודמת');
        setIsFormInvalid(true);
        return;
      }

      // createDataToSave.dayPinuiBinuiDeal = dayPinuiBinuiDeal;
      createDataToSave.purchaseDate = dayApaPurchased;
      createDataToSave.purchaseValue = totalPropertyCost;
      createDataToSave.purchaseDate2 = dayPinuiBinuiDeal;
      createDataToSave.purchaseValue2 = amountPaidToImproveApa;
      createDataToSave.shetahApaWasAllowed = shetahApaWasAllowed;
      createDataToSave.improvedGotShetahApa = improvedGotShetahApa;
    } else if (isMoreThanTikratShovi === "moreThanTikratShovi") {
      // no, no, no
      if (!totalPropertyCost || !dayApaPurchased || !valueSoldOfPinuiBinui || !dayPinuiBinuiDeal || !valueGotPartialPtorFor) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (!validateMinAndMaxForDate(date(dayApaPurchased))) return;
      if (!validateMinAndMaxForDate(date(dayPinuiBinuiDeal))) return;
      if (!validateDateLaterThanAnother(dayApaPurchased, dayPinuiBinuiDeal)) return;

      createDataToSave.purchaseDate = dayApaPurchased;
      createDataToSave.purchaseValue = totalPropertyCost;
      createDataToSave.purchaseDate2 = dayPinuiBinuiDeal;
      createDataToSave.purchaseValue2 = valueSoldOfPinuiBinui;
      createDataToSave.valueGotPartialPtorFor = valueGotPartialPtorFor;
      createDataToSave.valuePaidTaxForPartialPtor = valueSoldOfPinuiBinui;
    } else {
      // no, no, yes (isMoreThanTikratShovi === lessThanTikratShovi)
      if (!totalPropertyCost || !dayApaPurchased) {
        setFormInvalidMsg("");
        setIsFormInvalid(true);
        return;
      }

      if (!validateMinAndMaxForDate(date(dayApaPurchased))) return;

      createDataToSave.purchaseDate = dayApaPurchased;
      createDataToSave.purchaseValue = totalPropertyCost;
    }

    setDataToSave(createDataToSave);
    setFormInvalidMsg("");
    setIsFormInvalid(false);
  }

  function validateMinAndMaxForDate(givenDate) {
    if (givenDate > MAX_SUPPORTED_DATE) {
      setFormInvalidMsg("תאריך חייב להיות לפני " + moment(MAX_SUPPORTED_DATE).format("DD-MM-YYYY"));
      setIsFormInvalid(true);
      return false;
    }

    if (givenDate < MIN_SUPPORTED_DATE) {
      setFormInvalidMsg("תאריך חייב להיות לאחר " + moment(MIN_SUPPORTED_DATE).format("DD-MM-YYYY"));
      setIsFormInvalid(true);
      return false;
    }

    return true;
  }

  function validateDateLaterThanAnother(earlierDate, laterDate) {
    if (date(earlierDate) > date(laterDate)) {
      setFormInvalidMsg("תאריך עסקת פינוי בינוי חייב להיות אחרי תאריך יום הרכישה המקורי");
      setIsFormInvalid(true);
      return false;
    }

    return true;
  }
}
