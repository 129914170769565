import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/rootReducer";

const getStoreInitialState = () => {
  var storeState = {};
  let token = localStorage.getItem("token");
  if (token) {
    let role = localStorage.getItem("role");
    let jobType = localStorage.getItem("jobType");
    let email = localStorage.getItem("email");
    let fullName = localStorage.getItem("fullName");
    let belongToCardNum = localStorage.getItem("belongToCardNum");
    let abilities = localStorage.getItem("abilities");
    let areRemindersOn = localStorage.getItem("areRemindersOn") === "true";
    if (abilities) abilities = abilities.split(",");
    let softwareServiceType = localStorage.getItem("softwareServiceType");

    storeState["loggedinReducer"] = {
      isLoggedIn: true,
      role,
      jobType,
      belongToCardNum,
      email,
      fullName,
      abilities,
      softwareServiceType,
      areRemindersOn
    };
  }

  return storeState;
};

const composeEnhancers = /*process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null ||*/ compose;

const store = createStore(rootReducer, getStoreInitialState(), composeEnhancers(applyMiddleware(thunk)));

export default store;
