import config from "../config";
import axios from "axios";

export default {
  convertDollarCurrency: function (amount, date) {
    return new Promise((resolve) => {
      axios.get(`${config.getDollarCurrencyEndpoint}?amount=${amount}&date=${date}`).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  }
};
