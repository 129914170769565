import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import filesService from "../../../services/docsService/filesService";
import CustomComplexTable from "../../layout/CustomComplexTable";
import CustomLink from "../../layout/CustomLink";
import Swal from "sweetalert2";

// Multipliers // Irrelevant
// var SELLER_SECTION = "פרטי המוכרים";
// var BUYER_SECTION = "פרטי הקונים";
// var THIRD_SECTION = "צד שלישי";

const docKeysTranslator = {
  shtarMecher: "שטר מכר",
  eaaratAzharaOneSide: "בקשה לרישום הערת אזהרה - צד אחד",
  eaaratAzharaAllSides: "בקשה לרישום הע'א בהסכמת כל הצדדים",
  ipuiKoachBuyer: "יפוי כח בלתי חוזר קונים",
  ipuiKoachSeller: "יפוי כח בלתי חוזר מוכרים",
  ipuiKoachEaaratAzhara: "יפוי כח בלתי חוזר לביטול העא",
  taboPassingLeasingRight: "שטר העברת זכות שכירות - מנהל רשום בטאבו",
  taboFixLeasingRight: "שטר תיקון תנאי חכירה - מנהל רשום בטאבו",
  taboCancelLeasingRight: "שטר ביטול רישום חכירה - מנהל רשום בטאבו",
  taboRegisterLeasingRight: "שטר רישום זכות חכירה - מנהל רשום בטאבו",
  noTaboLeasingRight: "שטר העברת זכות חכירה שאינה בטאבו"
};

class DocsHistory extends Component {
  state = {
    dealId: "",
    headers: ["שם תבנית", "תאריך יצירה", "הורדה"],
    rows: []
  };

  componentDidMount() {
    const dealId = this.props.match.params.dealId;
    filesService.getAllDealDocs(dealId).then((res) => {
      if (res.success) {
        const rows = [];
        for (const docsGroup of res.data) {
          if (Array.isArray(docsGroup.docsCreated)) {
            for (const doc of docsGroup.docsCreated) {
              const row = [];
              row.push(docsGroup._id);
              row.push(doc);
              row.push(docKeysTranslator[doc]);
              row.push(docsGroup.dateCreated ? moment(docsGroup.dateCreated).format("hh:mm:ss DD/MM/YYYY") : "");
              row.push(
                <CustomLink
                  label="הורד"
                  onClick={() => {
                    this.generateDocsHistory(docsGroup._id, doc);
                  }}
                />
              );
              rows.push(row);
            }
          } else if (docsGroup.docsCreated === "2990") {
            const row = [];
            row.push(docsGroup._id);
            row.push(docsGroup);
            row.push("טופס 2990");
            row.push(docsGroup.dateCreated ? moment(docsGroup.dateCreated).format("hh:mm:ss DD/MM/YYYY") : "");
            row.push(
              <CustomLink
                label="הורד"
                onClick={() => {
                  this.generate2990DocsHistory(docsGroup._id);
                }}
              />
            );
            rows.push(row);
          }
        }
        this.setState({ dealId, rows });
      }
    });
  }

  render() {
    return (
      <Grid container direction="column" spacing={1}>
        <Grid item>
          {this.state.rows.length > 0 ? (
            <CustomComplexTable hideFirstCellsInRow={2} headers={this.state.headers} rows={this.state.rows} />
          ) : (
            <Typography variant="h5">לא הופקו טפסים עבור עסקה זו</Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  generateDocsHistory(docsHistoryId, docType) {
    filesService
      .generateDocsHistory(this.state.dealId, docsHistoryId, docType)
      .catch(() => Swal.fire("היתה בעיה ביצירת המסמך", "במידה ובעיה זו ממשיכה בבקשה צרו איתנו קשר", "warning"));
  }

  generate2990DocsHistory(docsHistoryId) {
    filesService
      .generate2990DocsHistory(this.state.dealId, docsHistoryId)
      .catch(() => Swal.fire("היתה בעיה ביצירת המסמך", "במידה ובעיה זו ממשיכה בבקשה צרו איתנו קשר", "warning"));
  }
}

export default withRouter(DocsHistory);
