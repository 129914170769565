import React, { Component } from "react";
import { Dialog, IconButton, DialogContent, DialogTitle, Grid, Typography, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Draggable from "react-draggable";

import * as customColors from "../../theme/customColors";

// Original optional props:
// label
// maxWidth

// Extended optional props:
// children

function PaperComponent(props) {
  return (
    <Draggable handle="#customized-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

class CustomDialog extends Component {
  render() {
    const dialog = (
        <Dialog
          disableEnforceFocus={this.props.disableEnforceFocus || false}
          fullWidth
          onClose={this.props.onClose}
          open={this.props.open}
          PaperComponent={PaperComponent}
          maxWidth={this.props.maxWidth ? this.props.maxWidth : "sm"}>
          <DialogTitle style={{ background: customColors.darkBlue, cursor: "move" }} id="customized-dialog-title">
            <Grid container direction="row">
              <Grid item xs={11}>
                <Typography variant="h6" align="right" style={{ color: customColors.white }}>
                  {this.props.label}
                </Typography>
              </Grid>
              <Grid container item xs={1} justify="flex-end">
                <Grid item>
                  <IconButton size="small" onClick={this.props.onClose} style={{ color: customColors.white }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{this.props.children}</DialogContent>
        </Dialog>
    );
    return dialog;
  }
}

export default CustomDialog;
