import React, { Component, Fragment } from "react";
import { Divider, Grid, Typography, Tooltip, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import * as customColors from "../../../../theme/customColors";
import CalculatorIcon from "../../../layout/CalculatorIcon";
import CustomDialog from "../../../layout/CustomDialog";
import helpTexts from "../../../../assets/texts/helpTexts";
import LabeledTextField from "../../../layout/LabeledTextField";
import { convertDecimalToFraction } from "../../../../services/utils/utils";

// Original optional props:
// open
// onClose

// Extended optional props:
// handleResults - force the result.
// soldPropertyDate
// deduction
// getPurchaseDate

const depreciationPercentOpts = [
  { label: "בניני אבן איתנים - 1.50%", value: 0.015 },
  { label: "בנייני בטון מזוין - 2%", value: 0.02 },
  { label: "בניינים שבנייתם איתנה פחות - 4%", value: 0.04 },
  { label: "בניינים שבנייתם גרועה - 6%", value: 0.06 },
  { label: "שיעור מס אחר", value: -1 }
];
const depreciationBuiltPercentOpts = [
  { label: "100%", value: 1 },
  { label: "4/5", value: 0.8 },
  { label: "3/4", value: 0.75 },
  { label: "2/3", value: parseFloat((2 / 3).toFixed(3)) },
  { label: "1/2", value: 0.5 },
  { label: "1/3", value: parseFloat((1 / 3).toFixed(3)) },
  { label: "1/4", value: 0.25 },
  { label: "1/5", value: 0.2 },
  { label: "אחר", value: -1 }
];

class DepreciationCalculatorDialog extends Component {
  state = {
    depType: this.props.propertyType !== "otherProperty" ? "ptor" : "regTax",
    // depType: this.props.sellerType === "single" && this.props.propertyType === "dmm" ? "ptor" : "regTax",
    dateRanges: [{ startDate: "", endDate: "" }],
    depreciationPercentFromList: 0.02,
    depreciationPercentTemp: null,
    depreciationBuiltPercentFromList: 1,
    depreciationBuiltPercentNumerator: null,
    depreciationBuiltPercentDenominator: null,
    depreciationResult: null
  };

  componentDidMount() {
    if (this.props.deduction.depreciation) {
      const newState = { ...this.state };
      const dep = this.props.deduction.depreciation;
      if (dep.depType) newState.depType = dep.depType;
      if (Array.isArray(dep.dateRanges) && dep.dateRanges.length > 0) {
        const newDateRange = [];
        for (const range of dep.dateRanges) {
          newDateRange.push({ ...range });
        }
        newState.dateRanges = newDateRange;
      } else if (this.props.propertyType === "otherProperty") {
        const startDate =
          this.props.deduction.deduType === "inheritance"
            ? this.props.getPurchaseDate(this.props.deduction)
            : this.props.deduction.deduDate;
        const endDate = this.props.soldPropertyDate;
        newState.dateRanges = [{ startDate, endDate }];
      }
      if (dep.depreciationPercent) {
        newState.depreciationPercentFromList = depreciationPercentOpts.map((item) => item.value).includes(dep.depreciationPercent)
          ? dep.depreciationPercent
          : -1;
        if (newState.depreciationPercentFromList === -1) {
          newState.depreciationPercentTemp = dep.depreciationPercent;
        }
      }

      if (dep.depreciationBuiltPercent) {
        newState.depreciationBuiltPercentFromList = depreciationBuiltPercentOpts
          .map((item) => item.value)
          .includes(dep.depreciationBuiltPercent)
          ? dep.depreciationBuiltPercent
          : -1;
        if (newState.depreciationBuiltPercentFromList === -1) {
          const depreciationBuiltPercent = convertDecimalToFraction(dep.depreciationBuiltPercent);
          newState.depreciationBuiltPercentNumerator = depreciationBuiltPercent[0];
          newState.depreciationBuiltPercentDenominator = depreciationBuiltPercent[1];
        }
      }

      if (dep.depreciationBuiltPercentFromList) newState.depreciationBuiltPercentFromList = dep.depreciationBuiltPercentFromList;
      if (dep.depreciationBuiltPercentNumerator) newState.depreciationBuiltPercentNumerator = dep.depreciationBuiltPercentNumerator;
      if (dep.depreciationBuiltPercentDenominator) newState.depreciationBuiltPercentDenominator = dep.depreciationBuiltPercentDenominator;

      if (dep.depreciationResult) newState.depreciationResult = dep.depreciationResult;
      this.setState(newState);
    }
  }

  render() {
    const depTypeOpts = [{ label: "שעור מס רגילים", value: "regTax" }];
    if (this.props.propertyType !== "otherProperty") {
      // if (this.props.sellerType === "single" && this.props.propertyType === "dmm") {
      depTypeOpts.unshift({ label: "שעור מס 10%", value: "percent10" });
      depTypeOpts.unshift({ label: "מסלול פטור", value: "ptor" });
    }
    return (
      <CustomDialog label="מחשבון פחת" open={this.props.open} onClose={this.props.onClose}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <LabeledTextField
              type="select"
              disabled={this.props.propertyType === "otherProperty"}
              // disabled={!(this.props.sellerType === "single" && this.props.propertyType === "dmm")}
              value={this.state.depType}
              labelWidth={200}
              textFieldWidth={160}
              name="depType"
              options={depTypeOpts}
              label="מסלול מיסוי ההכנסה משכירות"
              onChange={(e) => this.handleOnChange(e)}
            />
          </Grid>
          {this.state.dateRanges.map((range, index) => {
            return (
              <Grid key={`date-ranges-${index}`} container item direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <LabeledTextField
                    type="date"
                    value={range.startDate}
                    textFieldWidth={170}
                    name="startDate"
                    label="מתאריך"
                    onChange={(e) => this.handleDateRangeOnChange(e, index)}
                  />
                </Grid>
                <Grid item>
                  <LabeledTextField
                    type="date"
                    value={range.endDate}
                    textFieldWidth={170}
                    name="endDate"
                    label="עד תאריך"
                    onChange={(e) => this.handleDateRangeOnChange(e, index)}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="הוספה">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ maxWidth: "36px", minWidth: "36px" }}
                      endIcon={<AddIcon style={{ marginRight: "12px" }} />}
                      onClick={() => this.addDateRange()}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="מחיקה">
                    <DeleteIcon
                      fontSize="small"
                      style={{ cursor: "pointer", verticalAlign: "middle" }}
                      onClick={() => this.removeDateRange(index)}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            );
          })}
          {this.state.depType !== "regTax" ? (
            <Fragment>
              <Grid item>
                <Divider light />
              </Grid>
              <Grid container item direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <LabeledTextField
                    type="select"
                    value={this.state.depreciationPercentFromList}
                    labelWidth={100}
                    textFieldWidth={270}
                    name="depreciationPercentFromList"
                    options={depreciationPercentOpts}
                    label="שיעור הפחת"
                    onChange={(e) => this.handleOnChange(e)}
                  />
                </Grid>
                {this.state.depreciationPercentFromList === -1 ? (
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      value={this.state.depreciationPercentTemp}
                      textFieldWidth={100}
                      min={0}
                      max={100}
                      name="depreciationPercentTemp"
                      currency="%"
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid item>
                <LabeledTextField
                  type="number"
                  value={this.props.deduction.amount}
                  labelWidth={200}
                  textFieldWidth={170}
                  disabled={true}
                  label="סכום הרכישה/ההשבחה"
                />
              </Grid>
              <Grid container item direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <LabeledTextField
                    type="select"
                    value={this.state.depreciationBuiltPercentFromList}
                    labelWidth={200}
                    textFieldWidth={170}
                    name="depreciationBuiltPercentFromList"
                    options={depreciationBuiltPercentOpts}
                    label="שיעור חלק המבנה במקרקעין"
                    onChange={(e) => this.handleOnChange(e)}
                  />
                </Grid>
                {this.state.depreciationBuiltPercentFromList === -1 ? (
                  <Fragment>
                    <Grid item>
                      <LabeledTextField
                        type="number"
                        value={this.state.depreciationBuiltPercentDenominator}
                        textFieldWidth={70}
                        max={1000}
                        min={0}
                        name="depreciationBuiltPercentDenominator"
                        currency="/"
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </Grid>
                    <Grid item>
                      <LabeledTextField
                        type="number"
                        value={this.state.depreciationBuiltPercentNumerator}
                        textFieldWidth={60}
                        max={1000}
                        min={0}
                        name="depreciationBuiltPercentNumerator"
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </Grid>
                  </Fragment>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: "100px", marginRight: "208px" }}
                  endIcon={<CalculatorIcon style={{ marginRight: "13px" }} />}
                  onClick={() => this.calculateDepreciation()}
                >
                  חשב
                </Button>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      value={this.props.deduction.depreciation.depreciationResult}
                      labelWidth={200}
                      textFieldWidth={170}
                      disabled={true}
                      helpDialog={helpTexts.depreciationResult}
                      label="סכום הפחת לניכוי"
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="נקה">
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ maxWidth: "36px", minWidth: "36px" }}
                        endIcon={<DeleteIcon style={{ marginRight: "12px" }} />}
                        onClick={() => this.clearDepreciation()}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item>
                <Typography variant="h6" align="center" style={{ color: customColors.red }}>
                  נא למלא תאריך וסכום הפחת בהתאם לטופס י"א מרו"ח
                </Typography>
              </Grid>
              <Grid container item direction="row" justify="center">
                <Grid item>
                  <LabeledTextField
                    type="number"
                    value={this.state.depreciationResult}
                    labelWidth={200}
                    textFieldWidth={170}
                    min={0}
                    name="depreciationResult"
                    currency="₪"
                    onChange={(e) => this.handleOnChange(e)}
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row" justify="center" alignItems="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ minWidth: "100px", marginLeft: "20px" }}
                    onClick={() => this.calculateDepreciation()}
                  >
                    הוסף
                  </Button>
                </Grid>
                <Grid item>
                  <Tooltip title="נקה">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ maxWidth: "36px", minWidth: "36px" }}
                      endIcon={<DeleteIcon style={{ marginRight: "12px" }} />}
                      onClick={() => this.clearDepreciation()}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </CustomDialog>
    );
  }

  calculateDepreciation() {
    const today = moment(new Date()).format("YYYY-MM-DD");
    for (let i = 0; i < this.state.dateRanges.length; i++) {
      const range = this.state.dateRanges[i];
      if (!range.startDate) return Swal.fire("", "תאריך התחלה הינו שדה חובה", "error");
      if (!range.endDate) return Swal.fire("", "תאריך סיום הינו שדה חובה", "error");
      if (range.endDate > today) return Swal.fire("", "תאריך סיום לא יכול להיות תאריך עתידי", "error");
      if (range.startDate > range.endDate) return Swal.fire("", "תאריך התחלה לא יכול להיות לאחר תאריך סיום", "error");
      const deduDate =
        this.props.deduction.deduType === "inheritance" ? this.props.getPurchaseDate(this.props.deduction) : this.props.deduction.deduDate;
      if (range.startDate < deduDate) return Swal.fire("", "תאריך ההתחלה לא יכול להיות לפני תאריך הניכוי", "error");
      if (range.endDate > this.props.soldPropertyDate) return Swal.fire("", "תאריך הסיום לא יכול להיות לאחר תאריך המכירה", "error");
      if (i > 0) {
        if (range.startDate <= this.state.dateRanges[i - 1].endDate)
          return Swal.fire("", "טווחי התאריכים צריכים להיות לפי סדר ובלי חפיפה ביניהם", "error");
      }
    }
    if (this.state.depType === "regTax") {
      if (this.props.handleResults && this.state.depreciationResult) {
        const depreciation = {
          depType: this.state.depType,
          depCurrency: "newShekel",
          // depCurrency: this.props.deduction.deduCurrency,
          dateRanges: this.state.dateRanges,
          depreciationPercent: null,
          depreciationBuiltPercent: null,
          depreciationResult: this.state.depreciationResult
        };
        this.props.handleResults({ target: { value: depreciation, name: "depreciation" } });
        this.props.onClose();
      }
    } else {
      let depreciationResult = null;
      if (!this.props.deduction.amount) {
        return Swal.fire("", "סכום ההשבחה ריק", "error");
      } else if (
        this.state.depreciationBuiltPercentFromList === -1 &&
        (!this.state.depreciationBuiltPercentDenominator || !this.state.depreciationBuiltPercentNumerator)
      ) {
        return Swal.fire("", "שיעור חלק המבנה במקרקעין הינו שדה חובה", "error");
      } else if (this.state.depreciationPercentFromList === -1 && !this.state.depreciationPercentTemp) {
        return Swal.fire("", "שיעור הפחת הינו שדה חובה", "error");
      } else {
        const depreciationPercent =
          this.state.depreciationPercentFromList === -1
            ? this.state.depreciationPercentTemp / 100 || 0
            : this.state.depreciationPercentFromList;
        const depreciationBuiltPercent =
          this.state.depreciationBuiltPercentFromList === -1
            ? parseFloat((this.state.depreciationBuiltPercentNumerator / this.state.depreciationBuiltPercentDenominator).toFixed(3))
            : this.state.depreciationBuiltPercentFromList;
        let totalYears = 0;
        for (const range of this.state.dateRanges) {
          const start =
            this.state.depType === "ptor" && range.startDate < "2007-02-27" ? new Date("2007-02-27") : new Date(range.startDate);
          const end = new Date(range.endDate);
          // Includes the starting and ending date in the range.
          end.setDate(end.getDate() + 1);
          totalYears += Math.max(0, moment(end).diff(moment(start), "years", true));
        }
        depreciationResult = this.props.deduction.amount * depreciationPercent * depreciationBuiltPercent * totalYears;
        if (this.props.handleResults && Number.isFinite(depreciationResult)) {
          const depreciation = {
            depType: this.state.depType,
            depCurrency: this.props.deduction.deduCurrency,
            dateRanges: this.state.dateRanges,
            depreciationPercent,
            depreciationBuiltPercent,
            depreciationResult
          };
          this.props.handleResults({ target: { value: depreciation, name: "depreciation" } });
        }
      }
    }
  }

  clearDepreciation() {
    this.props.handleResults({ target: { value: {}, name: "depreciation" } });
    // this.props.onClose();
  }

  handleOnChange(e) {
    const newState = { ...this.state, [e.target.name]: e.target.value };
    this.handleDependencies(e, newState);
    this.setState(newState);
  }

  handleDateRangeOnChange(e, index) {
    const dateRanges = [...this.state.dateRanges];
    dateRanges[index][e.target.name] = e.target.value;
    this.setState({ dateRanges });
  }

  addDateRange() {
    const dateRanges = [...this.state.dateRanges];
    dateRanges.push({ startDate: "", endDate: "" });
    this.setState({ dateRanges });
  }

  removeDateRange(index) {
    let dateRanges = this.state.dateRanges.filter((range, rangeIndex) => index !== rangeIndex);
    if (dateRanges.length === 0) {
      dateRanges = [{ startDate: "", endDate: "" }];
    }
    this.setState({ dateRanges });
  }

  handleDependencies(e, newState) {
    if (e.target.name === "depType") {
      newState.dateRanges = [{ startDate: "", endDate: "" }];
      if (e.target.value === "regTax") {
        newState.depreciationPercentFromList = 0.02;
        newState.depreciationPercentTemp = null;
        newState.depreciationBuiltPercentFromList = 1;
        newState.depreciationBuiltPercentDenominator = null;
        newState.depreciationBuiltPercentNumerator = null;
        newState.depreciationResult = null;
      }
    } else if (e.target.name === "depreciationPercentFromList") {
      if (e.target.value !== -1) {
        newState.depreciationPercentTemp = null;
      }
    } else if (e.target.name === "depreciationBuiltPercentFromList") {
      if (e.target.value !== -1) {
        newState.depreciationBuiltPercentDenominator = null;
        newState.depreciationBuiltPercentNumerator = null;
      }
    }
  }
}

export default DepreciationCalculatorDialog;
