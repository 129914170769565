import React, { Component } from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import moment from "moment";
import { Grid, Typography, Button } from "@material-ui/core";
import adminService from "../../services/adminService";
import CustomAcordion from "../../components/layout/CustomAcordion";
import LabeledTextField from "../../components/layout/LabeledTextField";
import CustomComplexTable from "../../components/layout/CustomComplexTable";
import * as customColors from "../../theme/customColors";
import logo from "../../assets/images/logo.png";
import { ignoreInvalidCharForNumber } from "../../services/utils/utils";
import TestJsonDisplayerDialog from "../../components/dialogs/testJsonDisplayerDialog";
import filesService from "../../services/docsService/filesService";

class UpdateIndexesContainer extends Component {
  state = {
    // Madad
    madadEdited: {
      year: null,
      month1: null,
      month2: null,
      month3: null,
      month4: null,
      month5: null,
      month6: null,
      month7: null,
      month8: null,
      month9: null,
      month10: null,
      month11: null,
      month12: null
    },
    madadHeaders: [
      "שנה",
      "חודש 1",
      "חודש 2",
      "חודש 3",
      "חודש 4",
      "חודש 5",
      "חודש 6",
      "חודש 7",
      "חודש 8",
      "חודש 9",
      "חודש 10",
      "חודש 11",
      "חודש 12"
    ],
    madadRows: [],

    // Brackets:
    bracketsEdited: {
      year: null,
      zikuiValue: null,
      bracketOver1: null,
      bracketOver2: null,
      bracketOver3: null,
      bracketOver4: null,
      bracketOver5: null,
      bracketOver6: null,
      bracketOver7: null,
      endAmountOver1: null,
      endAmountOver2: null,
      endAmountOver3: null,
      endAmountOver4: null,
      endAmountOver5: null,
      endAmountOver6: null,
      bracketUnder1: null,
      bracketUnder2: null,
      bracketUnder3: null,
      bracketUnder4: null,
      endAmountUnder1: null,
      endAmountUnder2: null,
      endAmountUnder3: null
    },
    bracketsHeaders: [
      "שנה",
      "מעל 60",
      "שווי זיקוי",
      "מדרגה 1",
      "מדרגה 2",
      "מדרגה 3",
      "מדרגה 4",
      "מדרגה 5",
      "מדרגה 6",
      "מדרגה 7",
      "טווח 1",
      "טווח 2",
      "טווח 3",
      "טווח 4",
      "טווח 5",
      "טווח 6"
    ],
    bracketsRows: [],

    // YearlyConstants:
    yearlyConstantsEdited: {
      year: null,
      tikratYokra: null,
      tikraMaximalitKefel: null,
      tikraMinimalitZaban: null,
      min49aAmount: null,
      max49aAmount: null
    },
    yearlyConstantsHeaders: ["שנה", "תקרת יוקרה", "תקרת כפל מקסימלית", 'תקרת זב"ן מינימלית', "49(ה) מינימלי", "49(ה) מקסימלי"],
    yearlyConstantsRows: [],
    testsHeaders: ["תיאור בדיקה", "תאריך יצירה", "סוג בדיקה"],
    testsRows: [],
    testShown: {},
    isTestDialogOpen: false
  };

  componentDidMount() {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
  }

  render() {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid container item direction="row">
          <Grid container item direction="row" xs={6} justify="flex-start" alignItems="flex-end" style={{ minHeight: "85px" }}>
            <Typography variant="h4">ניהול מדדים</Typography>
            {/* <Button onClick={() => adminService.transferUsersAndOffices()}>Users mig</Button>
            <Button onClick={() => adminService.transferDealsOldBtax()}>Deals mig</Button> */}
          </Grid>
          <Grid container item direction="row" xs={6} justify="flex-end">
            <div className="relative">
              <div className="country-addresses-con">
                <label htmlFor="update-addresses-btn">
                  <span className="pointer">העלאת קובץ כתובות</span>
                </label>

                <input
                  id="update-addresses-btn"
                  style={{ visibility: "hidden", position: "absolute" }}
                  type="file"
                  onChange={this.updateCountryAddresses}
                />
              </div>
            </div>
            <div className="small-logo-deals-page inline-block">
              <img alt="btax-logo" src={logo} />
            </div>
          </Grid>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="מדדים" defaultExpanded>
            <Grid container item direction="column" spacing={1} justify="center" alignItems="flex-start">
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => this.updateMadad()}>
                      עדכן מדד
                    </Button>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="year"
                      value={this.state.madadEdited.year}
                      placeholder="שנה"
                      textFieldWidth={80}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month1"
                      value={this.state.madadEdited.month1}
                      placeholder="חודש 1"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month2"
                      value={this.state.madadEdited.month2}
                      placeholder="חודש 2"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month3"
                      value={this.state.madadEdited.month3}
                      placeholder="חודש 3"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month4"
                      value={this.state.madadEdited.month4}
                      placeholder="חודש 4"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month5"
                      value={this.state.madadEdited.month5}
                      placeholder="חודש 5"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month6"
                      value={this.state.madadEdited.month6}
                      placeholder="חודש 6"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month7"
                      value={this.state.madadEdited.month7}
                      placeholder="חודש 7"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month8"
                      value={this.state.madadEdited.month8}
                      placeholder="חודש 8"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month9"
                      value={this.state.madadEdited.month9}
                      placeholder="חודש 9"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month10"
                      value={this.state.madadEdited.month10}
                      placeholder="חודש 10"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month11"
                      value={this.state.madadEdited.month11}
                      placeholder="חודש 11"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="month12"
                      value={this.state.madadEdited.month12}
                      placeholder="חודש 12"
                      textFieldWidth={140}
                      onChange={(e) => this.handleIndexData(e, "madadEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => this.getAllMadadim()}>
                  הצג מדדים
                </Button>
              </Grid>
              <Grid item>
                <CustomComplexTable
                  hideFirstCellsInRow={1}
                  headers={this.state.madadHeaders}
                  rows={this.state.madadRows}
                  handleDoubleClick={this.copyMadad}
                  copyFunc={this.copyMadad}
                  deleteFunc={this.deleteMadad}
                />
              </Grid>
            </Grid>
          </CustomAcordion>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="מדרגות מס" defaultExpanded>
            <Grid container item direction="column" spacing={1} justify="center" alignItems="flex-start">
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => this.updateBracket()}>
                      עדכן מדרגות
                    </Button>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="year"
                      value={this.state.bracketsEdited.year}
                      placeholder="שנה"
                      textFieldWidth={80}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="zikuiValue"
                      value={this.state.bracketsEdited.zikuiValue}
                      placeholder="שווי זיכוי"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>מעל גיל 60</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketOver1"
                      value={this.state.bracketsEdited.bracketOver1}
                      placeholder="מדרגה 1"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketOver2"
                      value={this.state.bracketsEdited.bracketOver2}
                      placeholder="מדרגה 2"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketOver3"
                      value={this.state.bracketsEdited.bracketOver3}
                      placeholder="מדרגה 3"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketOver4"
                      value={this.state.bracketsEdited.bracketOver4}
                      placeholder="מדרגה 4"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketOver5"
                      value={this.state.bracketsEdited.bracketOver5}
                      placeholder="מדרגה 5"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketOver6"
                      value={this.state.bracketsEdited.bracketOver6}
                      placeholder="מדרגה 6"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketOver7"
                      value={this.state.bracketsEdited.bracketOver7}
                      placeholder="מדרגה 7"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountOver1"
                      value={this.state.bracketsEdited.endAmountOver1}
                      placeholder="טווח 1"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountOver2"
                      value={this.state.bracketsEdited.endAmountOver2}
                      placeholder="טווח 2"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountOver3"
                      value={this.state.bracketsEdited.endAmountOver3}
                      placeholder="טווח 3"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountOver4"
                      value={this.state.bracketsEdited.endAmountOver4}
                      placeholder="טווח 4"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountOver5"
                      value={this.state.bracketsEdited.endAmountOver5}
                      placeholder="טווח 5"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountOver6"
                      value={this.state.bracketsEdited.endAmountOver6}
                      placeholder="טווח 6"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>מתחת לגיל 60</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketUnder1"
                      value={this.state.bracketsEdited.bracketUnder1}
                      placeholder="מדרגה 1"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketUnder2"
                      value={this.state.bracketsEdited.bracketUnder2}
                      placeholder="מדרגה 2"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketUnder3"
                      value={this.state.bracketsEdited.bracketUnder3}
                      placeholder="מדרגה 3"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="bracketUnder4"
                      value={this.state.bracketsEdited.bracketUnder4}
                      placeholder="מדרגה 4"
                      textFieldWidth={90}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountUnder1"
                      value={this.state.bracketsEdited.endAmountUnder1}
                      placeholder="טווח 1"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountUnder2"
                      value={this.state.bracketsEdited.endAmountUnder2}
                      placeholder="טווח 2"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="endAmountUnder3"
                      value={this.state.bracketsEdited.endAmountUnder3}
                      placeholder="טווח 3"
                      textFieldWidth={120}
                      onChange={(e) => this.handleIndexData(e, "bracketsEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => this.getAllBrackets()}>
                  הצג מדרגות
                </Button>
              </Grid>
              <Grid item>
                <CustomComplexTable
                  hideFirstCellsInRow={1}
                  headers={this.state.bracketsHeaders}
                  rows={this.state.bracketsRows}
                  handleDoubleClick={this.copyBracket}
                  copyFunc={this.copyBracket}
                  deleteFunc={this.deleteBracket}
                ></CustomComplexTable>
              </Grid>
            </Grid>
          </CustomAcordion>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="תקרות" defaultExpanded>
            <Grid container item direction="column" spacing={1} justify="center" alignItems="flex-start">
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => this.updateYearlyConstants()}>
                      עדכן תקרה
                    </Button>
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="year"
                      value={this.state.yearlyConstantsEdited.year}
                      placeholder="שנה"
                      textFieldWidth={80}
                      onChange={(e) => this.handleIndexData(e, "yearlyConstantsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="tikratYokra"
                      value={this.state.yearlyConstantsEdited.tikratYokra}
                      placeholder="תקרת יוקרה"
                      textFieldWidth={165}
                      onChange={(e) => this.handleIndexData(e, "yearlyConstantsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="tikraMaximalitKefel"
                      value={this.state.yearlyConstantsEdited.tikraMaximalitKefel}
                      placeholder="תקרת כפל מקסימלית"
                      textFieldWidth={165}
                      onChange={(e) => this.handleIndexData(e, "yearlyConstantsEdited")}
                    />
                  </Grid>
                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="tikraMinimalitZaban"
                      value={this.state.yearlyConstantsEdited.tikraMinimalitZaban}
                      placeholder='תקרת זב"ן מינימלית'
                      textFieldWidth={165}
                      onChange={(e) => this.handleIndexData(e, "yearlyConstantsEdited")}
                    />
                  </Grid>

                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="min49aAmount"
                      value={this.state.yearlyConstantsEdited.min49aAmount}
                      placeholder="תקרת 49(ה) מינימלי"
                      textFieldWidth={165}
                      onChange={(e) => this.handleIndexData(e, "yearlyConstantsEdited")}
                    />
                  </Grid>

                  <Grid item>
                    <LabeledTextField
                      type="number"
                      name="max49aAmount"
                      value={this.state.yearlyConstantsEdited.max49aAmount}
                      placeholder="תקרת 49(ה) מקסימלי"
                      textFieldWidth={165}
                      onChange={(e) => this.handleIndexData(e, "yearlyConstantsEdited")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => this.getAllYearlyConstants()}>
                  הצג תקרות
                </Button>
              </Grid>
              <Grid item>
                <CustomComplexTable
                  hideFirstCellsInRow={1}
                  headers={this.state.yearlyConstantsHeaders}
                  rows={this.state.yearlyConstantsRows}
                  handleDoubleClick={this.copyYearlyConstant}
                  copyFunc={this.copyYearlyConstant}
                  deleteFunc={this.deleteYearlyConstant}
                ></CustomComplexTable>
              </Grid>
            </Grid>
          </CustomAcordion>
        </Grid>
        <Grid item>
          <CustomAcordion summaryText="בדיקות" defaultExpanded>
            <Grid container item direction="column" spacing={1} justify="center" alignItems="flex-start">
              <Grid item>
                <Grid container direction="row" spacing={1} justify="flex-start" alignItems="center">
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => this.getAllTests()}>
                      הצג בדיקות
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => this.startTests()}>
                      הרץ בדיקות
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CustomComplexTable
                  hideFirstCellsInRow={1}
                  headers={this.state.testsHeaders}
                  rows={this.state.testsRows}
                  handleDoubleClick={this.getTest}
                  deleteFunc={this.deleteTest}
                ></CustomComplexTable>
              </Grid>
            </Grid>
          </CustomAcordion>
        </Grid>
        {this.state.isTestDialogOpen ? (
          <TestJsonDisplayerDialog open={true} onClose={this.handleCloseDialog} test={this.state.testShown} />
        ) : null}
      </Grid>
    );
  }

  handleIndexData = (e, indexType) => {
    let val = e.target.value;
    if (val === "false") val = false;
    if (val === "true") val = true;
    const index = { ...this.state[indexType], [e.target.name]: val };
    this.setState({ [indexType]: index });
  };

  handleCloseDialog = () => {
    this.setState({ isTestDialogOpen: false, testShown: {} });
  };

  getAllMadadim() {
    adminService.getAllMadadim().then((res) => {
      if (res["success"]) {
        const madadRows = [];
        for (const tikra of res.data) {
          const row = [];
          row.push(tikra._id);
          row.push(tikra.year || "");
          row.push(parseFloat(tikra.month1).toLocaleString() || "");
          row.push(parseFloat(tikra.month2).toLocaleString() || "");
          row.push(parseFloat(tikra.month3).toLocaleString() || "");
          row.push(parseFloat(tikra.month4).toLocaleString() || "");
          row.push(parseFloat(tikra.month5).toLocaleString() || "");
          row.push(parseFloat(tikra.month6).toLocaleString() || "");
          row.push(parseFloat(tikra.month7).toLocaleString() || "");
          row.push(parseFloat(tikra.month8).toLocaleString() || "");
          row.push(parseFloat(tikra.month9).toLocaleString() || "");
          row.push(parseFloat(tikra.month10).toLocaleString() || "");
          row.push(parseFloat(tikra.month11).toLocaleString() || "");
          row.push(parseFloat(tikra.month12).toLocaleString() || "");
          madadRows.push(row);
        }
        this.setState({ madadRows });
      }
    });
  }

  copyMadad = (row) => {
    const madadEdited = {
      year: row[1],
      month1: parseFloat(ignoreInvalidCharForNumber(row[2])),
      month2: parseFloat(ignoreInvalidCharForNumber(row[3])),
      month3: parseFloat(ignoreInvalidCharForNumber(row[4])),
      month4: parseFloat(ignoreInvalidCharForNumber(row[5])),
      month5: parseFloat(ignoreInvalidCharForNumber(row[6])),
      month6: parseFloat(ignoreInvalidCharForNumber(row[7])),
      month7: parseFloat(ignoreInvalidCharForNumber(row[8])),
      month8: parseFloat(ignoreInvalidCharForNumber(row[9])),
      month9: parseFloat(ignoreInvalidCharForNumber(row[10])),
      month10: parseFloat(ignoreInvalidCharForNumber(row[11])),
      month11: parseFloat(ignoreInvalidCharForNumber(row[12])),
      month12: parseFloat(ignoreInvalidCharForNumber(row[13]))
    };
    this.setState({ madadEdited });
  };

  updateMadadValidation() {
    if (!this.state.madadEdited.year) return "שנה הינו שדה חובה";
    else if (!this.state.madadEdited.month1) return "חודש 1 הינו שדה חובה";
    else if (!this.state.madadEdited.month2) return "חודש 2 הינו שדה חובה";
    else if (!this.state.madadEdited.month3) return "חודש 3 הינו שדה חובה";
    else if (!this.state.madadEdited.month4) return "חודש 4 הינו שדה חובה";
    else if (!this.state.madadEdited.month5) return "חודש 5 הינו שדה חובה";
    else if (!this.state.madadEdited.month6) return "חודש 6 הינו שדה חובה";
    else if (!this.state.madadEdited.month7) return "חודש 7 הינו שדה חובה";
    else if (!this.state.madadEdited.month8) return "חודש 8 הינו שדה חובה";
    else if (!this.state.madadEdited.month9) return "חודש 9 הינו שדה חובה";
    else if (!this.state.madadEdited.month10) return "חודש 10 הינו שדה חובה";
    else if (!this.state.madadEdited.month11) return "חודש 11 הינו שדה חובה";
    else if (!this.state.madadEdited.month12) return "חודש 12 הינו שדה חובה";
    return null;
  }

  updateMadad() {
    const err = this.updateMadadValidation();
    if (err) {
      return Swal.fire("", err, "error");
    } else {
      Swal.fire({
        icon: "info",
        title: `האם אתה בטוח שברצונך לערוך את המדד לשנת ${this.state.madadEdited.year}`,
        showCancelButton: true,
        cancelButtonText: "ביטול",
        confirmButtonText: "עדכן",
        preConfirm: () => {
          return adminService
            .updateMadad(this.state.madadEdited)
            .then((res) => res)
            .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
        }
      }).then((result) => {
        if (result.isConfirmed && result.value.success) {
          Swal.fire({
            icon: "success",
            title: `המדדים לשנת ${this.state.madadEdited.year} עודכנו בהצלחה`,
            confirmButtonText: "המשך"
          }).then((result) => {
            if (result.isConfirmed) this.props.history.go(0);
          });
        }
      });
    }
  }

  deleteMadad = (row) => {
    Swal.fire({
      icon: "warning",
      title: `האם אתה בטוח שברצונך למחוק את המדדים לשנת ${row[1]}`,
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonColor: customColors.red,
      confirmButtonText: "מחק",
      preConfirm: () => {
        if (!row[0]) return Swal.showValidationMessage("מחיקה נכשלה");
        return adminService
          .deleteMadad(row[0])
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `המדדים לשנת ${row[1]} נמחקו בהצלחה`,
          confirmButtonText: "המשך"
        }).then((result) => {
          if (result.isConfirmed) this.props.history.go(0);
        });
      }
    });
  };

  getAllBrackets() {
    adminService.getAllBrackets().then((res) => {
      if (res["success"]) {
        const bracketsRows = [];
        for (const bracket of res.data) {
          const row1 = [];
          const row2 = [];
          row1.push(bracket._id);
          row2.push(bracket._id);
          row1.push(bracket.year || "");
          row2.push(bracket.year || "");
          row1.push("כן");
          row2.push("לא");
          row1.push(bracket.zikuiValue || "");
          row2.push(bracket.zikuiValue || "");
          row1.push(bracket.over60.brackets[0] ? parseFloat(bracket.over60.brackets[0]).toLocaleString() : "");
          row2.push(bracket.under60.brackets[0] ? parseFloat(bracket.under60.brackets[0]).toLocaleString() : "");
          row1.push(bracket.over60.brackets[1] ? parseFloat(bracket.over60.brackets[1]).toLocaleString() : "");
          row2.push(bracket.under60.brackets[1] ? parseFloat(bracket.under60.brackets[1]).toLocaleString() : "");
          row1.push(bracket.over60.brackets[2] ? parseFloat(bracket.over60.brackets[2]).toLocaleString() : "");
          row2.push(bracket.under60.brackets[2] ? parseFloat(bracket.under60.brackets[2]).toLocaleString() : "");
          row1.push(bracket.over60.brackets[3] ? parseFloat(bracket.over60.brackets[3]).toLocaleString() : "");
          row2.push(bracket.under60.brackets[3] ? parseFloat(bracket.under60.brackets[3]).toLocaleString() : "");
          row1.push(bracket.over60.brackets[4] ? parseFloat(bracket.over60.brackets[4]).toLocaleString() : "");
          row2.push(bracket.under60.brackets[4] ? parseFloat(bracket.under60.brackets[4]).toLocaleString() : "");
          row1.push(bracket.over60.brackets[5] ? parseFloat(bracket.over60.brackets[5]).toLocaleString() : "");
          row2.push(bracket.under60.brackets[5] ? parseFloat(bracket.under60.brackets[5]).toLocaleString() : "");
          row1.push(bracket.over60.brackets[6] ? parseFloat(bracket.over60.brackets[6]).toLocaleString() : "");
          row2.push(bracket.under60.brackets[6] ? parseFloat(bracket.under60.brackets[6]).toLocaleString() : "");
          row1.push(bracket.over60.endAmounts[0] ? parseFloat(bracket.over60.endAmounts[0]).toLocaleString() : "");
          row2.push(bracket.under60.endAmounts[0] ? parseFloat(bracket.under60.endAmounts[0]).toLocaleString() : "");
          row1.push(bracket.over60.endAmounts[1] ? parseFloat(bracket.over60.endAmounts[1]).toLocaleString() : "");
          row2.push(bracket.under60.endAmounts[1] ? parseFloat(bracket.under60.endAmounts[1]).toLocaleString() : "");
          row1.push(bracket.over60.endAmounts[2] ? parseFloat(bracket.over60.endAmounts[2]).toLocaleString() : "");
          row2.push(bracket.under60.endAmounts[2] ? parseFloat(bracket.under60.endAmounts[2]).toLocaleString() : "");
          row1.push(bracket.over60.endAmounts[3] ? parseFloat(bracket.over60.endAmounts[3]).toLocaleString() : "");
          row2.push(bracket.under60.endAmounts[3] ? parseFloat(bracket.under60.endAmounts[3]).toLocaleString() : "");
          row1.push(bracket.over60.endAmounts[4] ? parseFloat(bracket.over60.endAmounts[4]).toLocaleString() : "");
          row2.push(bracket.under60.endAmounts[4] ? parseFloat(bracket.under60.endAmounts[4]).toLocaleString() : "");
          row1.push(bracket.over60.endAmounts[5] ? parseFloat(bracket.over60.endAmounts[5]).toLocaleString() : "");
          row2.push(bracket.under60.endAmounts[5] ? parseFloat(bracket.under60.endAmounts[5]).toLocaleString() : "");
          bracketsRows.push(row1);
          bracketsRows.push(row2);
        }
        this.setState({ bracketsRows });
      }
    });
  }

  copyBracket = (row) => {
    const bracketsEdited = {
      ...this.state.bracketsEdited,
      year: row[1],
      zikuiValue: row[3],
      [row[2] === "כן" ? "bracketOver1" : "bracketUnder1"]: row[4] ? parseFloat(ignoreInvalidCharForNumber(row[4])) : null,
      [row[2] === "כן" ? "bracketOver2" : "bracketUnder2"]: row[5] ? parseFloat(ignoreInvalidCharForNumber(row[5])) : null,
      [row[2] === "כן" ? "bracketOver3" : "bracketUnder3"]: row[6] ? parseFloat(ignoreInvalidCharForNumber(row[6])) : null,
      [row[2] === "כן" ? "bracketOver4" : "bracketUnder4"]: row[7] ? parseFloat(ignoreInvalidCharForNumber(row[7])) : null,
      [row[2] === "כן" ? "bracketOver5" : "bracketUnder5"]: row[8] ? parseFloat(ignoreInvalidCharForNumber(row[8])) : null,
      [row[2] === "כן" ? "bracketOver6" : "bracketUnder6"]: row[9] ? parseFloat(ignoreInvalidCharForNumber(row[9])) : null,
      [row[2] === "כן" ? "bracketOver7" : "bracketUnder7"]: row[10] ? parseFloat(ignoreInvalidCharForNumber(row[10])) : null,
      [row[2] === "כן" ? "endAmountOver1" : "endAmountUnder1"]: row[11] ? parseFloat(ignoreInvalidCharForNumber(row[11])) : null,
      [row[2] === "כן" ? "endAmountOver2" : "endAmountUnder2"]: row[12] ? parseFloat(ignoreInvalidCharForNumber(row[12])) : null,
      [row[2] === "כן" ? "endAmountOver3" : "endAmountUnder3"]: row[13] ? parseFloat(ignoreInvalidCharForNumber(row[13])) : null,
      [row[2] === "כן" ? "endAmountOver4" : "endAmountUnder4"]: row[14] ? parseFloat(ignoreInvalidCharForNumber(row[14])) : null,
      [row[2] === "כן" ? "endAmountOver5" : "endAmountUnder5"]: row[15] ? parseFloat(ignoreInvalidCharForNumber(row[15])) : null,
      [row[2] === "כן" ? "endAmountOver6" : "endAmountUnder6"]: row[16] ? parseFloat(ignoreInvalidCharForNumber(row[16])) : null
    };
    this.setState({ bracketsEdited });
  };

  updateBracketValidation() {
    if (!this.state.bracketsEdited.year) return "שנה הינו שדה חובה";
    else if (!this.state.bracketsEdited.zikuiValue) return "שווי נקודת זיכוי הינו שדה חובה";
    else if (!this.state.bracketsEdited.bracketOver1) return "מדרגה 1 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.bracketOver3 && !this.state.bracketsEdited.bracketOver2) return "מדרגה 2 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.bracketOver4 && !this.state.bracketsEdited.bracketOver3) return "מדרגה 3 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.bracketOver5 && !this.state.bracketsEdited.bracketOver4) return "מדרגה 4 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.bracketOver6 && !this.state.bracketsEdited.bracketOver5) return "מדרגה 5 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.bracketOver7 && !this.state.bracketsEdited.bracketOver6) return "מדרגה 6 מעל גיל 60 הינו שדה חובה";
    else if (!this.state.bracketsEdited.endAmountOver1) return "טווח 1 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.endAmountOver3 && !this.state.bracketsEdited.endAmountOver2)
      return "טווח 2 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.endAmountOver4 && !this.state.bracketsEdited.endAmountOver3)
      return "טווח 3 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.endAmountOver5 && !this.state.bracketsEdited.endAmountOver4)
      return "טווח 4 מעל גיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.endAmountOver6 && !this.state.bracketsEdited.endAmountOver5)
      return "טווח 5 מעל גיל 60 הינו שדה חובה";
    else if (!this.state.bracketsEdited.bracketUnder1) return "מדרגה 1 מתחת לגיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.bracketUnder3 && !this.state.bracketsEdited.bracketUnder2)
      return "מדרגה 2 מתחת לגיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.bracketUnder4 && !this.state.bracketsEdited.bracketUnder3)
      return "מדרגה 3 מתחת לגיל 60 הינו שדה חובה";
    else if (!this.state.bracketsEdited.endAmountUnder1) return "טווח 1 מתחת לגיל 60 הינו שדה חובה";
    else if (this.state.bracketsEdited.endAmountUnder3 && !this.state.bracketsEdited.endAmountUnder2)
      return "טווח 2 מתחת לגיל 60 הינו שדה חובה";
    return null;
  }

  parseBracketsEdited() {
    const over60 = { brackets: [], endAmounts: [] };
    const under60 = { brackets: [], endAmounts: [] };
    for (let i = 1; i < 8; i++) {
      if (this.state.bracketsEdited[`bracketOver${i}`]) over60.brackets.push(this.state.bracketsEdited[`bracketOver${i}`]);
      if (this.state.bracketsEdited[`endAmountOver${i}`]) over60.endAmounts.push(this.state.bracketsEdited[`endAmountOver${i}`]);
      if (i < 5) {
        if (this.state.bracketsEdited[`bracketUnder${i}`]) under60.brackets.push(this.state.bracketsEdited[`bracketUnder${i}`]);
        if (this.state.bracketsEdited[`endAmountUnder${i}`]) under60.endAmounts.push(this.state.bracketsEdited[`endAmountUnder${i}`]);
      }
    }
    return { year: this.state.bracketsEdited.year, over60, under60, zikuiValue: this.state.bracketsEdited.zikuiValue };
  }

  updateBracket() {
    const err = this.updateBracketValidation();
    if (err) {
      return Swal.fire("", err, "error");
    } else {
      Swal.fire({
        icon: "info",
        title: `האם אתה בטוח שברצונך לערוך את המדרגות לשנת ${this.state.bracketsEdited.year}`,
        showCancelButton: true,
        cancelButtonText: "ביטול",
        confirmButtonText: "עדכן",
        preConfirm: () => {
          return adminService
            .updateBracket(this.parseBracketsEdited())
            .then((res) => res)
            .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
        }
      }).then((result) => {
        if (result.isConfirmed && result.value.success) {
          Swal.fire({
            icon: "success",
            title: `המדרגות לשנת ${this.state.bracketsEdited.year} עודכנו בהצלחה`,
            confirmButtonText: "המשך"
          }).then((result) => {
            if (result.isConfirmed) this.props.history.go(0);
          });
        }
      });
    }
  }

  deleteBracket = (row) => {
    Swal.fire({
      icon: "warning",
      title: `האם אתה בטוח שברצונך למחוק את המדרגות לשנת ${row[1]}`,
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonColor: customColors.red,
      confirmButtonText: "מחק",
      preConfirm: () => {
        if (!row[0]) return Swal.showValidationMessage("מחיקה נכשלה");
        return adminService
          .deleteBracket(row[0])
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `המדרגות לשנת ${row[1]} נמחקו בהצלחה`,
          confirmButtonText: "המשך"
        }).then((result) => {
          if (result.isConfirmed) this.props.history.go(0);
        });
      }
    });
  };

  getAllYearlyConstants() {
    adminService.getAllYearlyConstants().then((res) => {
      if (res["success"]) {
        const yearlyConstantsRows = [];
        for (const tikra of res.data) {
          const row = [];
          row.push(tikra._id);
          row.push(tikra.year || "");
          row.push(parseFloat(tikra.tikratYokra).toLocaleString() || "");
          row.push(parseFloat(tikra.tikraMaximalitKefel).toLocaleString() || "");
          row.push(parseFloat(tikra.tikraMinimalitZaban).toLocaleString() || "");
          row.push(parseFloat(tikra.min49aAmount).toLocaleString() || "");
          row.push(parseFloat(tikra.max49aAmount).toLocaleString() || "");
          yearlyConstantsRows.push(row);
        }
        this.setState({ yearlyConstantsRows });
      }
    });
  }

  copyYearlyConstant = (row) => {
    const yearlyConstantsEdited = {
      year: row[1],
      tikratYokra: parseFloat(ignoreInvalidCharForNumber(row[2])),
      tikraMaximalitKefel: parseFloat(ignoreInvalidCharForNumber(row[3])),
      tikraMinimalitZaban: parseFloat(ignoreInvalidCharForNumber(row[4])),
      min49aAmount: parseFloat(ignoreInvalidCharForNumber(row[5])),
      max49aAmount: parseFloat(ignoreInvalidCharForNumber(row[6]))
    };
    this.setState({ yearlyConstantsEdited });
  };

  updateYearlyConstantsValidation() {
    if (!this.state.yearlyConstantsEdited.year) return "שנה הינו שדה חובה";
    else if (!this.state.yearlyConstantsEdited.tikratYokra) return "תקרת יוקרה הינו שדה חובה";
    else if (!this.state.yearlyConstantsEdited.tikraMaximalitKefel) return "תקרת כפל מקסימלית הינו שדה חובה";
    else if (!this.state.yearlyConstantsEdited.tikraMinimalitZaban) return 'תקרת זב"ן מינימלית הינו שדה חובה';
    else if (!this.state.yearlyConstantsEdited.min49aAmount) return "חובה להזין תקרת 49 מינימלית";
    else if (!this.state.yearlyConstantsEdited.max49aAmount) return "חובה להזין תקרת 49 מקסימלית";
    return null;
  }

  updateYearlyConstants() {
    const err = this.updateYearlyConstantsValidation();
    if (err) {
      return Swal.fire("", err, "error");
    } else {
      Swal.fire({
        icon: "info",
        title: `האם אתה בטוח שברצונך לערוך את התקרות לשנת ${this.state.yearlyConstantsEdited.year}`,
        showCancelButton: true,
        cancelButtonText: "ביטול",
        confirmButtonText: "עדכן",
        preConfirm: () => {
          return adminService
            .updateYearlyConstants(this.state.yearlyConstantsEdited)
            .then((res) => res)
            .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
        }
      }).then((result) => {
        if (result.isConfirmed && result.value.success) {
          Swal.fire({
            icon: "success",
            title: `התקרות לשנת ${this.state.yearlyConstantsEdited.year} עודכנו בהצלחה`,
            confirmButtonText: "המשך"
          }).then((result) => {
            if (result.isConfirmed) this.props.history.go(0);
          });
        }
      });
    }
  }

  deleteYearlyConstant = (row) => {
    Swal.fire({
      icon: "warning",
      title: `האם אתה בטוח שברצונך למחוק את התקרות לשנת ${row[1]}`,
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonColor: customColors.red,
      confirmButtonText: "מחק",
      preConfirm: () => {
        if (!row[0]) return Swal.showValidationMessage("מחיקה נכשלה");
        return adminService
          .deleteYearlyConstants(row[0])
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `התקרות לשנת ${row[1]} נמחקו בהצלחה`,
          confirmButtonText: "המשך"
        }).then((result) => {
          if (result.isConfirmed) this.props.history.go(0);
        });
      }
    });
  };

  getAllTests() {
    adminService.getAllTests().then((res) => {
      if (res["success"]) {
        const testsRows = [];
        for (const test of res.data) {
          const row = [];
          row.push(test._id);
          row.push(test.testDescription || "");
          row.push(moment(test.dateCreated).format("DD/MM/YYYY") || "");
          row.push(test.softwareServiceType || "");
          testsRows.push(row);
        }
        this.setState({ testsRows });
      }
    });
  }

  getTest = (row) => {
    return adminService
      .getTest(row[0])
      .then((res) => {
        if (res.success) this.setState({ isTestDialogOpen: true, testShown: res.data });
      })
      .catch((e) => console.log(e));
  };

  deleteTest = (row) => {
    Swal.fire({
      icon: "warning",
      title: `האם אתה בטוח שברצונך למחוק את הבדיקה - ${row[1]}`,
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonColor: customColors.red,
      confirmButtonText: "מחק",
      preConfirm: () => {
        if (!row[0]) return Swal.showValidationMessage("מחיקה נכשלה");
        return adminService
          .deleteTest(row[0])
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) {
        Swal.fire({
          icon: "success",
          title: `הבדיקה ${row[1]} נמחקה בהצלחה`,
          confirmButtonText: "המשך"
        }).then((result) => {
          if (result.isConfirmed) this.props.history.go(0);
        });
      }
    });
  };

  startTests() {
    adminService.startTests().then((res) => {
      if (res["success"]) {
        if (res.data.length === 0) {
          Swal.fire("לא נמצאו שגיאות", "כל הבדיקות עברו בהצלחה", "success");
        } else if (res.data.length > 0) {
          let failures = [];
          for (const test of res.data) {
            failures.push(`נמצאה שגיאה בבדיקה ${test.testDescription} בשלב ${test.failureStep}`);
            console.log({ testDescription: test.testDescription, ...test.comparing });
          }
          Swal.fire({
            title: "נמצאו שגיאות",
            html: failures.map((el, index) => `<p>${el}</p>`).join("\n"),
            icon: "error"
          });
        }
      }
    });
  }

  updateCountryAddresses(e) {
    if (!e.target.files[0].name.includes(".csv")) {
      Swal.fire("פורמט לא תקין", "רק CSV", "warning");
      return;
    }

    Swal.fire({
      title: "להעלות קובץ?",
      icon: "info",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        filesService.updateCountryAddresses(e.target.files[0]).then((res) => {
          if (res.success) {
            Swal.fire("הועלה בהצלחה", "", "success");
          }
        });
      }
    });
  }
}

const mapStateToProps = (state) => {
  return {
    belongToCardNum: state.loggedinReducer.belongToCardNum,
    fullName: state.loggedinReducer.fullName,
    abilities: state.loggedinReducer.abilities,
    softwareServiceType: state.loggedinReducer.softwareServiceType,
    role: state.loggedinReducer.role
  };
};

export default connect(mapStateToProps)(UpdateIndexesContainer);
