import config from "../config";
import axios from "axios";

export default {
  importDeal: function (dealData, emailToAddDeal, cardNumToAddDeal) {
    return new Promise((resolve) => {
      let url = "";
      if (dealData.softwareServiceType === "btax") url = config.importDealAdminEndpoint;
      else if (dealData.softwareServiceType === "btaxYam") url = config.importJustPrisaAdminEndpoint;
      if (emailToAddDeal) url += "?emailToAddDeal=" + emailToAddDeal + "&cardNumToAddDeal=" + cardNumToAddDeal;
      axios.post(url, dealData).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllMadadim: function () {
    return new Promise((resolve) => {
      axios.get(config.getAllMadadimAdminEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateMadad: function (updates) {
    return new Promise((resolve) => {
      axios.post(config.updateMadadAdminEndpoint, updates).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  deleteMadad: function (yearId) {
    return new Promise((resolve) => {
      axios.delete(config.deleteMadadAdminEndpoint + "?yearId=" + yearId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllBrackets: function () {
    return new Promise((resolve) => {
      axios.get(config.getAllBracketsAdminEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateBracket: function (updates) {
    return new Promise((resolve) => {
      axios.post(config.updateBracketAdminEndpoint, updates).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  deleteBracket: function (yearId) {
    console.log(yearId);
    return new Promise((resolve) => {
      axios.delete(config.deleteBracketAdminEndpoint + "?yearId=" + yearId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllYearlyConstants: function () {
    return new Promise((resolve) => {
      axios.get(config.getAllYearlyConstantsAdminEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateYearlyConstants: function (updates) {
    return new Promise((resolve) => {
      axios.post(config.updateYearlyConstantsAdminEndpoint, updates).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  deleteYearlyConstants: function (yearId) {
    return new Promise((resolve) => {
      axios.delete(config.deleteYearlyConstantsAdminEndpoint + "?yearId=" + yearId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getTest: function (testId) {
    return new Promise((resolve) => {
      axios.get(config.getTestAdminEndpoint + "?testId=" + testId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllTests: function () {
    return new Promise((resolve) => {
      axios.get(config.getAllTestsAdminEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  addDealTest: function (testDescription, calc, softwareServiceType) {
    return new Promise((resolve) => {
      axios.post(config.addDealTestAdminEndpoint, { testDescription, calc, softwareServiceType }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  addJustPrisaTest: function (testDescription, calc, softwareServiceType) {
    return new Promise((resolve) => {
      axios.post(config.addJustPrisaTestAdminEndpoint, { testDescription, calc, softwareServiceType }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  deleteTest: function (testId) {
    return new Promise((resolve) => {
      axios.delete(config.deleteTestAdminEndpoint + "?testId=" + testId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  startTests: function () {
    return new Promise((resolve) => {
      axios.get(config.startTestsAdminEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  specificClientDealsTransfer: function (newEmail, oldEmail) {
    return new Promise((resolve) => {
      axios.post(config.specificClientDealsTransferEndpoint, { newEmail, oldEmail }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  sendRenewalTest: function (email, emailTitle, emailBody) {
    return new Promise((resolve) => {
      axios.post(config.sendRenewalTestEndpoint, { email, emailTitle, emailBody }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateRenewalEmail: function (emailTitle, emailBody) {
    return new Promise((resolve) => {
      axios.post(config.updateRenewalEmailEndpoint, { emailTitle, emailBody }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getRenewalTemplate: function () {
    return new Promise((resolve) => {
      axios.get(config.getRenewalTemplateEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  }

  // TODO - delete the following two later
  // transferUsersAndOffices: function () {
  //   return new Promise((resolve) => {
  //     axios.get(config.transferUsersAndOfficesEndpoint).then((serverRes) => {
  //       if (serverRes.data) return resolve(serverRes.data);
  //       resolve({ err: "Problem with the request" });
  //     });
  //   });
  // },

  // transferDealsOldBtax: function () {
  //   return new Promise((resolve) => {
  //     axios.get(config.transferDealsOldBtaxEndpoint).then((serverRes) => {
  //       if (serverRes.data) return resolve(serverRes.data);
  //       resolve({ err: "Problem with the request" });
  //     });
  //   });
  // }
};
