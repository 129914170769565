import { ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import store from "./store/store";
import AccessibilityProvider from "./components/layout/AccessibilityProvider";
import InterceptorsComponent from "./components/sessions/interceptorsComponent";
import ApplicationCon from "./containers/applicationCon";
import SessionsContainer from "./containers/sessionsCon";
import { BrowserRouter, Switch } from "react-router-dom";
import OnlyNotLoggedInRoute from "./auth/onlyNotLoggedInGuard";
import UserGuard from "./auth/userGuard";
import theme from "./theme/theme";

function App() {
  return (
    <Provider store={store}>
      <AccessibilityProvider>
        <ThemeProvider theme={theme}>
          <InterceptorsComponent />

          <BrowserRouter>
            <Switch>
              <OnlyNotLoggedInRoute path="/sessions" component={SessionsContainer} />
              <UserGuard path="/" component={ApplicationCon} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </AccessibilityProvider>
    </Provider>
  );
}

export default App;
