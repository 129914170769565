import React, { Component } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import userService from "../../services/usersService";
import logo from "../../assets/images/logo.png";
import TextFieldWithIcon from "../layout/textFieldWithIcon";
import { validateEmail } from "../../services/utils/utils";

export default class ForgetPassword extends Component {
  state = {
    email: ""
  };
  render() {
    return (
      <div className="session-con">
        <div className="center">
          <img src={logo} alt="logo" />
        </div>

        <div className="mt-20">
          <div className="login-top-blue">
            <Typography>איפוס סיסמה</Typography>
          </div>
          <div className="login-wrapper">
            <Typography variant="subtitle2">
              <b>לא זוכר את הסיסמה?</b>
            </Typography>

            <div className="mt-10 mb-10">
              <Typography variant="subtitle2">אל חשש! איפוס סיסמה הוא פשוט מאוד.</Typography>
              <Typography variant="subtitle2">כל שעליך לעשות הוא להזין לשדה הבא את האימייל שלך</Typography>
            </div>

            <div className="mt-10">
              <TextFieldWithIcon
                value={this.state.email}
                onChange={this.handleChange}
                name="email"
                iconName="person"
                placeholder='דוא"ל'
                iconColor="#eee"
              />
            </div>

            <div className="right mt-20">
              <Button onClick={this.handleSubmit} variant="contained">
                לחץ לאיפוס
              </Button>
            </div>
            <div className="right mt-10">
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="subtitle2">זוכר את הסיסמה שלך?</Typography>
                </Grid>
                <Grid item>
                  <Link
                    to={{
                      pathname: "/sessions/login"
                    }}
                  >
                    <Typography variant="subtitle2">לחץ לכניסה</Typography>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      Swal.fire("", "יש להכניס אימייל", "warning");
      return;
    }

    if (!validateEmail(this.state.email)) {
      Swal.fire("", 'פורמט דוא"ל לא חוקי', "warning");
      return;
    }

    userService.resetUserPass(this.state.email).then((res) => {
      if (res["success"]) {
        this.props.history.push("/sessions/message/reset-pass-msg");
      } else {
        Swal.fire("", 'דוא"ל לא קיים במערכת', "info");
      }
    });
  };
}
