//action types
import { SET_LOGGED_IN, LOGOUT, TOGGLE_REMINDERS_STATE } from "../actions/actionTypes";

const INITIAL_STATE = {
  isLoggedIn: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      // const { belongToCardNum, email, fullName } = action.payload;
      return { ...state, ...action.payload, isLoggedIn: true }; //isLoggedIn: true, belongToCardNum, email, fullName
    case TOGGLE_REMINDERS_STATE:
      return { ...state, areRemindersOn: action.payload }; //isLoggedIn: true, belongToCardNum, email, fullName

    case LOGOUT:
      return { ...state, isLoggedIn: false };

    default:
      return { ...state };
  }
};
