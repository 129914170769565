import config from "../config";
import axios from "axios";

export default {
  getAllReminders: function () {
    return new Promise((resolve) => {
      axios.get(`${config.getAllRemindersEndpoint}`).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getRemindersByDealId: function (dealId) {
    return new Promise((resolve) => {
      axios.get(config.getRemindersByDealIdEndpoint + "?dealId=" + dealId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  createReminder: function (dealId, dealDescription, dealPrettyId, mission, destinationDate, status, notificationDate) {
    return new Promise((resolve) => {
      axios
        .post(config.createReminderEndpoint, { dealId, dealDescription, dealPrettyId, mission, destinationDate, status, notificationDate })
        .then((serverRes) => {
          if (serverRes.data) return resolve(serverRes.data);
          resolve({ err: "Problem with the request" });
        });
    });
  },

  updateReminder: function (reminderId, dealId, mission, destinationDate, status, notificationDate) {
    return new Promise((resolve) => {
      axios
        .post(config.updateReminderEndpoint, { reminderId, dealId, mission, destinationDate, status, notificationDate })
        .then((serverRes) => {
          if (serverRes.data) return resolve(serverRes.data);
          resolve({ err: "Problem with the request" });
        });
    });
  },

  deleteReminder: function (reminderId, dealId) {
    return new Promise((resolve) => {
      axios.delete(config.deleteReminderEndpoint + `?reminderId=${reminderId}&dealId=${dealId}`).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  }
};
