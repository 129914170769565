import { createMuiTheme } from "@material-ui/core";
import * as locale from "@material-ui/core/locale";
import * as customColors from "./customColors";

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: customColors.blue
      },
      secondary: {
        main: customColors.orange,
        contrastText: customColors.darkBlue
      },
      text: {
        primary: customColors.darkGray,
        secondary: customColors.gray
      },
      action: {
        active: customColors.darkGray,
        disabled: customColors.gray
      }
    },
    direction: "rtl"
  },
  locale.heIL
);

export default theme;
