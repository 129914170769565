import React, { Component, Fragment } from "react";
import moment from "moment";
import { Box, Divider, Grid, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import CustomAcordion from "../layout/CustomAcordion";
import LabeledTextField from "../layout/LabeledTextField";
import { showDateInTextType } from "../../services/utils/utils";
import Swal from "sweetalert2";

class DealManageContainer extends Component {
  render() {
    return (
      <Fragment>
        <Box height="20px" width="100%"></Box>
        <CustomAcordion defaultExpanded summaryText="כללי">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <LabeledTextField
                type="text"
                name="dealDescription"
                value={this.props.dealDescription}
                label="תיאור העסקה"
                onChange={this.props.handleManageOnChange}
              />
            </Grid>
            {this.props.isFullManageComponent ? (
              <Fragment>
                <Grid item>
                  <Divider light />
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={5} justify="flex-start" alignItems="center">
                    <Grid item>
                      <LabeledTextField
                        disabled
                        type="date"
                        name="dealDate"
                        value={this.props.dealDate}
                        label="תאריך חתימת ההסכם"
                        textFieldWidth={170}
                        onChange={this.props.handleManageOnChange}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset">
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" align="right">
                              האם נרשמה הערת אזהרה?
                            </Typography>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              row
                              name="hasEaaratAzhara"
                              value={Boolean(this.props.hasEaaratAzhara).toString()}
                              onChange={this.props.handleManageOnChange}
                            >
                              <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                              <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset">
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" align="right">
                              האם הוגשו דיווחי מס שבח?
                            </Typography>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              row
                              name="hasMasShevachReportsSubmitted"
                              value={Boolean(this.props.hasMasShevachReportsSubmitted).toString()}
                              onChange={this.props.handleManageOnChange}
                            >
                              <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                              <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    {this.props.dealDate ? (
                      <Grid item>
                        <Typography variant="subtitle1" align="right">
                          תאריך אחרון להגשת דיווחים:
                          <strong>{` ${this.getLastDateForSubmittion()}`}</strong>
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider light />
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <Typography variant="subtitle1" align="right" style={{ fontWeight: "bold" }}>
                      אישורי מיסים
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={5}>
                    <Grid item>
                      <FormControl component="fieldset">
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" align="right">
                              מס שבח
                            </Typography>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              row
                              name="masShavach"
                              value={Boolean(this.props.masShavach).toString()}
                              onChange={this.props.handleManageOnChange}
                            >
                              <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                              <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset">
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" align="right">
                              מס רכישה
                            </Typography>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              row
                              name="masRechisha"
                              value={Boolean(this.props.masRechisha).toString()}
                              onChange={this.props.handleManageOnChange}
                            >
                              <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                              <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl component="fieldset">
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Typography variant="subtitle1" align="right">
                              היטל השבחה
                            </Typography>
                          </Grid>
                          <Grid item>
                            <RadioGroup
                              row
                              name="haitelHashbacha"
                              value={Boolean(this.props.haitelHashbacha).toString()}
                              onChange={this.props.handleManageOnChange}
                            >
                              <FormControlLabel value={"true"} control={<Radio color="primary" />} label="כן" labelPlacement="end" />
                              <FormControlLabel value={"false"} control={<Radio color="primary" />} label="לא" labelPlacement="end" />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}
            <Grid item>
              <Grid container direction="row" justify="flex-start">
                <Button
                  style={{ minWidth: "110px" }}
                  endIcon={<SaveIcon style={{ marginRight: "8px" }} />}
                  variant="contained"
                  color="secondary"
                  onClick={this.validateDealDescriptionAndUpdate}
                >
                  שמירה
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CustomAcordion>
      </Fragment>
    );
  }

  validateDealDescriptionAndUpdate = () => {
    if (!this.props.dealDescription || this.props.dealDescription.length < 2) {
      Swal.fire("תיאור עסקה צריך להיות לפחות 2 תווים", "", "info");
      return;
    }
    this.props.updateDealData();
  };

  getLastDateForSubmittion() {
    const today = new Date(this.props.dealDate);
    today.setDate(today.getDate() + 30);
    return showDateInTextType(moment(today).format("YYYY-MM-DD"));
  }
}

export default DealManageContainer;
