import React, { useState } from "react";
import Swal from "sweetalert2";
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import CustomDialog from "../../layout/CustomDialog";
import { Settings } from "@material-ui/icons";
import dealsService from "../../../services/dealsService";
import Combination from "./Combination";
import Tama38on1 from "./Tama38on1";
import Tama38on2 from "./Tama38on2";
import { useHistory } from "react-router";
import PinuiBinui from "./PinuiBinui";

function DialogNewIronit(props) {
  const [dealType, setDealType] = useState("");
  const [formInvalidMsg, setFormInvalidMsg] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState(true);
  const [dataToSave, setDataToSave] = useState({});
  const history = useHistory();

  return (
    <CustomDialog disableEnforceFocus label={props.label} open={props.open} onClose={cleanAndClosePopup}>
      <div>
        <FormControl>
          <FormLabel>בחר סוג עסקה שנעשתה בדירה</FormLabel>
          <RadioGroup value={dealType} row onChange={(e) => setDealType(e.target.value)}>
            <FormControlLabel value="combination" control={<Radio color="primary" />} label="קומבינציה" />
            <FormControlLabel value="tama1" control={<Radio color="primary" />} label={`תמ"א 38/1`} />
            <FormControlLabel value="tama2" control={<Radio color="primary" />} label={`תמ"א 38/2`} />
            <FormControlLabel value="pinuiBinui" control={<Radio color="primary" />} label="פינוי בינוי" />
          </RadioGroup>
        </FormControl>

        {dealType === "combination" && (
          <Combination setFormInvalidMsg={setFormInvalidMsg} setIsFormInvalid={setIsFormInvalid} setDataToSave={setDataToSave} />
        )}
        {dealType === "tama1" && (
          <Tama38on1 setFormInvalidMsg={setFormInvalidMsg} setIsFormInvalid={setIsFormInvalid} setDataToSave={setDataToSave} />
        )}
        {dealType === "tama2" && (
          <Tama38on2 setFormInvalidMsg={setFormInvalidMsg} setIsFormInvalid={setIsFormInvalid} setDataToSave={setDataToSave} />
        )}
        {dealType === "pinuiBinui" && (
          <PinuiBinui setFormInvalidMsg={setFormInvalidMsg} setIsFormInvalid={setIsFormInvalid} setDataToSave={setDataToSave} />
        )}

        <hr />

        {dealType && (
          <div className="mt-20">
            <Button
              onClick={initNewDealWithPayload}
              style={{ minWidth: "130px" }}
              endIcon={<Settings style={{ marginRight: "8px" }} />}
              variant="contained"
              color="secondary"
              disabled={isFormInvalid}
            >
              ייצר עסקה חדשה
            </Button>
            <div className="ironit-invalid-form-validation-msg">{formInvalidMsg}</div>
          </div>
        )}
      </div>
    </CustomDialog>
  );

  function initNewDealWithPayload() {
    Swal.fire({
      title: "שם עסקה",
      input: "text",
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonText: "המשך",
      preConfirm: async (dealDescription) => {
        if (!dealDescription) return Swal.showValidationMessage("יש להזין תיאור עסקה");

        return dealsService
          .initNewDealWithPayload(dealDescription, dataToSave)
          .then((res) => res)
          .catch((e) => Swal.showValidationMessage(`בקשה נדחתה: ${e}`));
      }
    }).then((result) => {
      if (result.isConfirmed && result.value.success) history.push("/deal/calc/" + result.value.newId);
    });
  }

  function cleanAndClosePopup() {
    props.onClose();
  }
}

export default DialogNewIronit;
