import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { getIlsCurrencyDateBased, numberWithCommas } from "../../../services/utils/utils";
import moment from "moment";

const MIN_SUPPORTED_DATE = "1950-01-01";
const MAX_SUPPORTED_DATE = moment().format("YYYY-MM-DD");

export default function Tama38on1({ setFormInvalidMsg, setIsFormInvalid, setDataToSave }) {
  const [dayApaPurchased, setDayApaPurchased] = useState(null);
  const [totalPropertyCost, setTotalPropertyCost] = useState("");

  useEffect(() => {
    validateForm();
  }, [dayApaPurchased, totalPropertyCost]);

  return (
    <div>
      <div>
        <table className="fs-18" cellSpacing="10">
          <tbody>
            <tr>
              <td className="td-size-200">יום הרכישה המקורי של הדירה</td>
              <td>
                <TextField type="date" labelWidth={110} onChange={(e) => setDayApaPurchased(e.target.value)} value={dayApaPurchased} />
              </td>
            </tr>
          </tbody>

          <tbody>
            <tr>
              <td className="td-size-200">שווי רכישה מקורי של הדירה</td>
              <td>
                <TextField
                  className="ironit-input"
                  textFieldWidth={140}
                  labelWidth={190}
                  onChange={(e) => setTotalPropertyCost(e.target.value.replace(/,/g, ""))}
                  value={numberWithCommas(totalPropertyCost)}
                  currency="₪"
                />
              </td>
              <td>
                {getIlsCurrencyDateBased(dayApaPurchased) && (
                  <div className="ironit-currency-label">{getIlsCurrencyDateBased(dayApaPurchased)}</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  function validateForm() {
    if (dayApaPurchased) {
      if (dayApaPurchased < MIN_SUPPORTED_DATE) {
        setFormInvalidMsg("תאריך חייב להיות לאחר " + MIN_SUPPORTED_DATE);
        setIsFormInvalid(true);
        return;
      }
      if (dayApaPurchased > MAX_SUPPORTED_DATE) {
        setFormInvalidMsg("תאריך חייב להיות לפני " + MAX_SUPPORTED_DATE);
        setIsFormInvalid(true);
        return;
      }
    }

    if (dayApaPurchased && totalPropertyCost) {
      const createDataToSave = {
        purchaseValue: totalPropertyCost,
        purchaseDate: dayApaPurchased
      };

      setFormInvalidMsg("");
      setIsFormInvalid(false);
      setDataToSave(createDataToSave);
      return;
    }

    setFormInvalidMsg("");
    setIsFormInvalid(true);
  }
}
