/* eslint-disable import/no-anonymous-default-export */
import config from "../../config";
import axios from "axios";
import moment from "moment";

export default {
  translateDeduTemplateToObj: function (file) {
    return new Promise((resolve) => {
      const fd = new FormData();
      fd.append("file", file);

      axios.post(config.translateDeduTemplateToObjEndpoint, fd).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  downloadDeductionTemplate: function (callback) {
    axios({
      url: config.downloadDeductionTemplateEndpoint,
      method: "post",
      responseType: "blob"
    }).then((response) => {
      if (!response || !response.data) return callback();
      if (response.data.size < 250) return callback();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ניכויים.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },

  getDocsFields: function (dealId, isJust2990Fields) {
    return new Promise((resolve) => {
      let buildLink = config.getDocsFieldsEndpoint + "?dealId=" + dealId;
      let shouldPreventLoading = null;
      if (isJust2990Fields) {
        shouldPreventLoading = { preventLoading: true };
        buildLink += "&isJust2990Fields=true";
      }
      axios.get(buildLink, shouldPreventLoading).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateDocsFields: function (dealId, fields) {
    return new Promise((resolve) => {
      axios.post(config.updateDocsFieldsEndpoint, { dealId, fields }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllDealDocs: function (dealId) {
    return new Promise((resolve) => {
      axios.get(config.getAllDealDocsEndpoint + "?dealId=" + dealId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  update2990SpecificFields: function (dealId, data2990) {
    return new Promise((resolve) => {
      axios.post(config.update2990SpecificFieldsEndpoint, { dealId, data2990 }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  generateJustPrisa: function (dealId) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.generateJustPrisaEndpoint,
        method: "post",
        responseType: "blob",
        data: dealId
      }).then((response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        if (response.data.size < 250) return reject();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "2990.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve({ success: true });
      });
    });
  },

  create49aDocs2990: function (bestSampleForDocsCreation, dealId1, dealId2) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.create49aDocs2990Endpoint,
        method: "post",
        responseType: "blob",
        data: { ...bestSampleForDocsCreation, dealId1, dealId2 }
      }).then((response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        if (response.data.size < 250) return reject();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "קבצי 49ה.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve({ success: true });
      });
    });
  },

  generate2990: function (dataForGenerate2990Doc) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.generate2990Endpoint,
        method: "post",
        responseType: "blob",
        data: dataForGenerate2990Doc
      }).then((response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        if (response.data.size < 250) return reject();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "2990.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve({ success: true });
      });
    });
  },

  generate2990Json: function (dataForGenerate2990Doc, dealTaxType, dealIndex, partsAmount) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.generate2990JsonEndpoint,
        method: "post",
        data: { ...dataForGenerate2990Doc, dealTaxType, dealIndex }
      }).then(async (response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(response.data));
        if (window && window.showSaveFilePicker && false) {
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: generateFileDownloadName(dealTaxType, dealIndex, partsAmount),
            type: [
              {
                description: "JSON",
                accept: { "application/json": [".json"] }
              }
            ]
          });
          const writable = await fileHandle.createWritable();
          await writable.write(dataStr);
          writable.close();
        } else {
          const aElement = document.createElement("a");
          aElement.setAttribute("href", dataStr);
          aElement.setAttribute("download", generateFileDownloadName(dealTaxType, dealIndex, partsAmount));
          document.body.appendChild(aElement);
          aElement.click();
          aElement.remove();
        }
      });
    });
  },

  generateDeductions: function (dealId) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.generateDeductionEndpoint,
        method: "post",
        responseType: "blob",
        data: {
          dealId
        }
      }).then((response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        if (response.data.size < 250) return reject();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ניכויים.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve({ success: true });
      });
    });
  },

  generateDocs: function (docTypes, fields, dealId) {
    return new Promise((resolve, reject) => {
      for (const key in fields) {
        if (key === "signDate") {
          if (fields[key]) fields[key] = moment(fields[key]).format("DD/MM/YYYY");
          else fields[key] = "";
        }
      }
      axios({
        url: config.generateDocsEndpoint,
        method: "post",
        responseType: "blob",
        data: {
          docTypes,
          fields,
          dealId
        }
      }).then((response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        if (response.data.size < 250) return reject();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "docs.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve();
      });
    });
  },

  generateDocsHistory: function name(dealId, docsHistoryId, docType) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.generateDocsHistoryEndpoint,
        method: "post",
        responseType: "blob",
        data: {
          dealId,
          docsHistoryId,
          docType
        }
      }).then((response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        if (response.data.size < 250) return reject();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "docs.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve();
      });
    });
  },

  generate2990DocsHistory: function name(dealId, docsHistoryId) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.generate2990DocsHistoryEndpoint,
        method: "post",
        responseType: "blob",
        data: {
          dealId,
          docsHistoryId
        }
      }).then((response) => {
        if (!response || !response.data) return reject({ err: "Problem with request" });
        if (response.data.size < 250) return reject();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "2990.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve({ success: true });
      });
    });
  },

  updateCountryAddresses: function (file) {
    return new Promise((resolve) => {
      const fd = new FormData();
      fd.append("file", file);

      axios.post(config.updateCountryAddressesEndpoint, fd).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  }
};

const generateFileDownloadName = (dealTaxType, dealIndex, partsAmount) => {
  let fileName = "טופס 2990 מקוון";
  if (partsAmount > 1) fileName = `עסקה ${dealIndex + 1} - ` + fileName;
  if (dealTaxType === "linear") {
    fileName = "לינארי ישן -" + fileName;
  } else if (dealTaxType === "mutav") {
    fileName = "מוטב -" + fileName;
  }
  return fileName + ".json";
};
